import React, { Component } from "react";
import LiveGames from "./LiveGames";

export default class ActiveGames extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gametype: this.props.gametype,
      gametypename: {1:'Alpha', 2:'Other'}
    //   gametypename: {1:'Vision', 2:'Other'}
    }
  }

  componentDidMount() {}

  componentDidUpdate(nextProps){
    if(nextProps.gametype !== this.props.gametype){
      let gametype = nextProps.gametype

      this.setState({ gametype })
    }
  }

  render() {
    return (
      <article className="col-sm-12 col-md-12 col-lg-6">
        <div className="jarviswidget jarviswidget-color-blue"
          id="wid-id-4" data-widget-editbutton="false" data-widget-colorbutton="false">
          <header>
            <span className="widget-icon">
              <i className="fa fa-check txt-color-white" />
            </span>
            <h2> List of Active {this.state.gametypename[this.state.gametype]} Games</h2>
          </header>

          <div>
            <div className="jarviswidget-editbox">
              <div>
                <label>Title:</label>
                <input type="text" />
              </div>
            </div>
            <LiveGames gametype={ this.state.gametype }/>
          </div>
        </div>
      </article>
    )
  }
}
