import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom';
import { TextArea } from 'semantic-ui-react';
import moment from 'moment'
import Placeholder from './Placeholder';

const tablenames = {
    '1':['games','game_details'], 
    '2':['games','game_details','tickets','wins'],
    '3':['profile'],
    '4':['profile'],
    '5':['games','game_details','betting','betting_details'],
    '6':['games','game_details','betting','betting_details']
}//['profile', 'contact','user_till','games','game_details']
const fieldnameprofile=['username','firstname','lastname','email','password','pin','mobile','role']
const fieldnamecontact=['phone','area','address','location']
const fieldnamegame=['gamename','gametype']
const fieldnamedetails=['event','game_day','draw_date','draw_nos','machine_nos']
const fieldnamewins=['win_tickets','win_amount','win_percent']
const fieldnametickets=['total_tickets','total_amount']
const fieldnamebetting=['betname','multiplier']
const fieldnamebettingdetails=['ticket_serial','stakenum','stake_amount','no_of_lines','win_amount','createdon']

export default class PlaceholderForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                tablename:'', fieldname:'', placeholderText:'', messagetype:1,
                createdby:this.auth.getUser(), createdon: moment(),
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            scheduleTypes: this.props.scheduleTypes,
            scheduleType: this.props.scheduleType
        }
    }

    componentDidMount(){
        let formItems= this.state.formItems
        formItems['tablename'] = tablenames[1][0]

        this.setState(formItems)
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            let scheduleTypes = nextProps.scheduleTypes
            let scheduleType = nextProps.scheduleType

            this.setState({ scheduleTypes, scheduleType })
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        
        formItems[e.target.name] = e.target.value
        
        if(e.target.name == "tablename"){
            formItems['tablename']=e.target.value
        }
        else if(e.target.name == "fieldname"){
            formItems['placeholderText'] = '#' + e.target.value + '#'
        }

        this.setState({formItems})
    }

    formatScheduleType = () => {
        let sche = this.state.scheduleTypes.filter(sche => sche.ivalue == this.state.scheduleType)
        let schename = (sche[0] !== undefined) ? sche[0].iname : this.state.scheduleType

        //alert(JSON.stringify(this.state.scheduleType))
        return schename
    }
    
    goBack=()=>{
        ReactDOM.render(<Placeholder scheduleType={this.state.scheduleType} />, document.getElementById('generalConfigCon'))
    }

    submit=(e)=>{
        e.preventDefault()
        let formItems = this.state.formItems
        formItems['messagetype'] = this.state.scheduleType

        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/placeholder'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.goBack()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="row">
            <article className="col-xs-12" style={{padding:'10px 20px 0 20px'}}>
                <h3 className="pull-left">New Placeholder</h3>
                <div className="btn-group btn-group-sm pull-right clearfix">
                <button type="submit" className="btn btn-warning" onClick={this.goBack} form="frmSetup">
                        <i className="glyphicon glyphicon-backward"></i> Go Back
                    </button>
                    <button type="submit" className="btn btn-success" onClick={this.submit} form="frmSetup">
                        <i className="glyphicon glyphicon-save"></i> Save
                    </button>
                    
                </div>
            </article>
            <article className="col-xs-12"><hr className="hrsp" /></article>
            <article className="col-xs-12 col-sm-10">
                <form id="frmSetup" className="smart-form" onSubmit={this.formSubmit}>
                    <div className="row">
                        <div className="col-xs-12  col-sm-6">
                            <label className="label col col-4 text-right">Schedule Type: </label>
                            <section className="col col-8 ctrltxt">{this.formatScheduleType()}</section>
                        </div>
                        <div className="col-xs-12  col-sm-6">
                            <label className="label col col-4 text-right">Table: </label>
                            <section className="col col-8">
                            <label class="select">
                            <select className="form-control" id="tablename" name="tablename" style={{textTransform:'capitalize'}}
                                value={this.state.formItems.tablename} onChange={this.handleChange}>
                                <option value="">-- Select Table --</option>
                                {tablenames[this.state.scheduleType].map(ff=>(
                                    (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                ))}
                            </select>
                            </label>
                            </section>
                        </div>
                        
                        <div className="col-xs-12  col-sm-6">
                            <label className="label col col-4 text-right">Formfield: </label>
                            <section className="col col-8">
                                <label className="input">
                                <select className="form-control" id="formfield" name="fieldname" style={{textTransform:'capitalize'}}
                                value={this.state.formItems.fieldname} onChange={this.handleChange}>
                                    <option value="">-- Select Formfield --</option>
                                    {(this.state.formItems.tablename=='profile')?
                                    fieldnameprofile.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='contact')?
                                    fieldnamecontact.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='games')?
                                    fieldnamegame.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='game_details')?
                                    fieldnamedetails.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='tickets')?
                                    fieldnametickets.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='wins') ?
                                    fieldnamewins.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='betting') ?
                                    fieldnamebetting.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    :(this.state.formItems.tablename=='betting_details') &&
                                    fieldnamebettingdetails.map(ff=>(
                                        (ff.indexOf('_')==-1 || ff.indexOf('_')>0) && <option value={ff}>{ff.replace(/_/gi, ' ')}</option>
                                    ))
                                    }
                                </select>
                                </label>
                            </section>
                        </div>
                        <div className="col-xs-12  col-sm-6">
                            <label className="label col col-4 text-right">Placeholder Text: </label>
                            <section className="col col-8">
                                <label className="input">
                                <input type="text" name="placeholderText" readOnly={true} placeholder="Placeholder Text" value={this.state.formItems.placeholderText}  onChange={this.handleChange} aria-required="true" aria-describedby="phone-error" />
                                </label>
                            </section>
                        </div>
                       

                    </div>




                </form>
            </article>
            <article className="col-xs-12 col-sm-2">
                {/* <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('fileLogo')}>
                    <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.logo} style={{width:'100'}} alt="Photo" />
                </div>
                <input type="file" name="logo" style={{display:'none'}} id="fileLogo" onChange={(e)=>this.imageChange(e,'custImgCon')} /> */}
            </article>
        </div>
            
        )
    }
}