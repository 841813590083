
import React from 'react';
import CartTempList from './CartTempList';


function CartTemp() {
    return (
        <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-calculator"></i> </span>
                        <h2><strong>Pending Momo Stakes</strong> </h2>
                    </header>
                    <div>
                        <div className="jarviswidget-editbox"></div>
                        <div id="cartemp_inner" className="widget-body"><CartTempList /></div>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default CartTemp;