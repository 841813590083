import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import jsPDF from "jspdf"
import $ from 'jquery'
import ReactDOM from 'react-dom'
import base64Img from 'base64-img'
import DrawScreenPreview from './DrawScreenPreview';
import WinReport from './WinReport';

const colstyle = {
    fontSize:'30px',
    display:'none'
}

function showReport(img, row, data, comp){
    const pdf = new jsPDF("p", "mm", "a4")
    const columns = [{title:"Bet Name", dataKey:"betname"}, {title:"No. of Bets", dataKey:"noOfBets"}, 
    {title:"Bet Amount", dataKey:"betAmount"}, {title:"Win Amount", dataKey:"winAmount"}]

    pdf.setProperties({
        title: data.title + ' for ' + data.fitm.gamecode,
        subject: data.title + ' for ' + data.fitm.gamecode,
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })
    pdf.autoTable({
        html: '#tbCompany', 
        theme: 'plain',
        styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{cellWidth:15}, 
            1: {halign: 'center'}, 
            2: {halign:'right', valign:'bottom', cellWidth:30, fontSize:8, fontStyle:'normal'}
        }
    })
    if(img !='' && img !=undefined){
        let tp = 'PNG'
        if(img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1){
            tp = 'JPEG'
        }
        pdf.addImage(img,tp,15,10,0,23)
    }
    pdf.line(14, 40, 196, 40)
    pdf.autoTable({
        html: '#tbTitles', 
        theme: 'striped',
        //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{halign:'right', cellWidth:30}, 1: {fontStyle: 'bold', cellWidth:50},
            2 :{halign:'center', valign:'center', fontStyle: 'bold', fontSize:14}
        }
    })
    pdf.autoTable({
        html: '#tbTotals', 
        theme: 'striped',
        //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{halign:'right', cellWidth:30}, 1: {fontStyle: 'bold', cellWidth:60},
            2 :{halign:'right', cellWidth:30}, 3: {fontStyle: 'bold', cellWidth:60}
        }
    })

    pdf.autoTable(columns, row, {
        theme : 'striped',
        styles: {overflow: 'linebreak'},
        headStyles: {halign:'center'},
        columnStyles: {noOfBets: {halign:'right'}, betAmount: {halign:'right'}, winAmount: {halign:'right'}},
        margin: { top: 46 }
    });

    pdf.autoTable({
        html: '#tbGrands', 
        theme: 'striped',
        //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{halign:'right', cellWidth:50, fillColor:'white'}, 
            1: {fontStyle: 'bold', cellWidth:40, fillColor:'white'},
            2 :{halign:'right', cellWidth:50}, 
            3: {fontStyle: 'bold', cellWidth:40, halign:'right'}
        }
    })
    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}

export default class DrawScreenPrint extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            data: this.props.data,
            companySetup:''
        }
    }

    componentDidMount(){
        this.generatePDF()
    }

    componentDidUpdate(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let rowData = nextProps.rowData
            let data = nextProps.data

            this.setState({ rowData, data })

            this.generatePDF()
        }
    }

    generatePDF = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            let url = 'admin/wapp/image/custfiles/' + companySetup.logo
            
            this.setState({ companySetup })
            let rowData = this.state.rowData
            let data = this.state.data
            alert(url)
            this.$http.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                let img = new Buffer(res.data, 'binary').toString('base64')
                
                showReport(img, rowData, data, companySetup)
            }).catch((error)=>{showReport('', rowData, data, companySetup)})
        })
    }

    goBack = () => {
        if($('#wins_inner').is(':visible')){
            /*ReactDOM.render(
                <WinReport  />, document.getElementById('wins_inner')
            )*/
        }

        if($('#drawScreenCon').is(':visible')){
            ReactDOM.render(
                <DrawScreenPreview  />, document.getElementById('drawScreenCon')
            )
        }
    }

    render(){
        let fitm = this.state.data.fitm
        let st = this.state.data.totals
        let scnum = this.state.data.screenNum
        return(
            <div className="row">
                <div className="col-xs-12 col-sm-10">
                    <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                </div>
                <div className="col-xs-12 col-sm-2">
                    <div className="btn-group btn-group-vertical btn-group-sm">
                        <button className="btn btn-warning" onClick={this.goBack}> 
                            <i className="fa fa-backward"></i> Back
                        </button>
                        <button className="btn btn-success"> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                        <button className="btn btn-success"> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                    </div>
                </div>
                <div className="col-xs-12">
                    <table id="tbCompany" className="table table-condensed" style={colstyle}>
                        <tbody>
                            <tr>
                                <td rowSpan="3"></td>
                                <td>{this.state.companySetup.companyName}</td>
                                <td rowSpan="3">
                                    P.O.Box: 16244 KIA <br />TEL:0302 2232 143 <br />ACCRA-GHANA
                                </td>
                            </tr>
                            <tr>
                                <td>{this.state.data.title}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbTitles" className="table table-condensed" style={colstyle}>
                        <tbody>
                            <tr>
                                <td>Game Name: </td><td>{fitm.gamecode}</td>
                                <td rowSpan="3">{scnum.snum0} - {scnum.snum1} - {scnum.snum2} - {scnum.snum3} - {scnum.snum4}</td>
                            </tr>
                            <tr>
                                <td>Event Number: </td><td>{fitm.drawno}</td>
                            </tr>
                            <tr>
                                <td>Draw Date: </td><td>{fitm.drawdate}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbTotals" className="table table-condensed" style={colstyle}>
                        <tbody>
                            <tr>
                                <td>Total Tickets: </td><td>{st.totalTickets}</td>
                                <td>Winning Tickets: </td><td>{st.winTickets}</td>
                            </tr>
                            <tr>
                                <td>Total Amount: </td><td>{st.totalStakes}</td>
                                <td>Winning Amount: </td><td>{st.totalWins}</td>
                            </tr>
                            <tr>
                                <td></td><td></td>
                                <td>Wins Ratio: </td><td>{this.state.data.win}%</td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="tbGrands" className="table table-condensed" style={colstyle}>
                        <tbody>
                            <tr>
                                <td></td><td></td>
                                <td>GROSS: </td><td>{this.state.data.gross}</td>
                            </tr>
                            <tr>
                                <td></td><td></td>
                                <td>VAT/NHIL (17.5%): </td><td>{this.state.data.vat}</td>
                            </tr>
                            <tr>
                                <td></td><td></td>
                                <td>NET: </td><td>{this.state.data.net}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}