import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import GamesForm from './GamesForm'

export default class GameList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            rawData: [],
            rowData: {},
            games: [],
            game: 0,
            loader: false,
            gamestatus: [],
            activegames: []
        }
    }

    componentDidMount(){
        this.setState({ loader:true })

        this.getGames()
    }

    getReportData = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamedetails/0/2')
        .then(res => {
            let rawData = this.auth.decryptData(res.data)

            this.setState({ rawData, reportData:rawData, loader:false })
        })
    }

    getActiveGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/activegames')
        .then(res => {
            let activegames = this.auth.decryptData(res.data)
            
            this.setState({ activegames })
        })
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)

            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/game_status')
            .then(res => {
                let gamestatus = this.auth.decryptData(res.data);

                this.setState({ games, gamestatus })

                this.getActiveGames()
                this.getReportData()
            })
        })
    }

    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            {(cell == 0 || cell == 2) &&
            <button className="btn btn-success" title="Go Live!" onClick={()=>this.handleAction(1, row)} >
                <i className="glyphicon glyphicon-check"></i> Go Live!
            </button>}
            {cell == 1 &&
            <button className="btn btn-danger" title="Stop Game" onClick={()=>this.handleAction(2, row)} >
                <i className="glyphicon glyphicon-stop"></i> Stop
            </button>}
            {cell !== 1 &&
            <button className="btn btn-default" title="Edit Game" data-toggle="modal" data-target="#gameform" onClick={() => this.setRowData(row)} >
                <i className="glyphicon glyphicon-edit"></i> Edit
            </button>}
        </div>)
    }
    gameFormat = (cell, row) => {
        let games = this.state.games
        let game = games.filter(gm => gm.id == cell)
        let gamename = (game[0] !== undefined) ? game[0].gamename : cell
        return <span>{gamename}</span>
    }
    statusFormat = (cell, row) => {
        let gamestatus = this.state.gamestatus
        let status = gamestatus.filter(gs => gs.ivalue == cell)
        let statusname = (status[0]!==undefined) ? status[0].iname : cell
        return <span>{statusname}</span>
    }

    setRowData = (rowData) => {
        this.setState({ rowData })
    }

    handleAction = (status, rowData) => {
        rowData['status'] = status
        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'game/wapp/gamedetail'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data));
                this.getGames();
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    handleChange = (e) => {
        let game = e.target.value
        this.setState({ game })
    }

    handleDisplay = () => {
        let gamecode = parseInt(this.state.game)
        //alert(gamecode)
        let reportData = (gamecode == 0) ? this.state.rawData 
        : this.state.rawData.filter(rdata => rdata.gamecode == gamecode)
        
        this.setState({ reportData })
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Game: </button>
                            <select name="game" className="btn btn-default" style={{width:'150px'}}
                                value={this.state.game} onChange={this.handleChange}>
                                <option value="0">All Games</option>
                                {this.state.games.map(game => (
                                    <option value={game.id}>{game.gamename}</option>
                                ))}
                            </select>
                            <button className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                        </div>     
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-info" data-toggle="modal" data-target="#gameform" onClick={() => this.setRowData('{}')}>
                                <i className="glyphicon glyphicon-plus"></i> Add Game
                            </button>
                        </div>               
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='drawno' isKey dataSort >Draw No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='gamecode' dataSort dataFormat={this.gameFormat}>Game Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='drawDate' dataSort>Draw Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' dataSort dataFormat={this.statusFormat}>Status</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' dataSort dataFormat={this.actionFormat}></TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <GamesForm games={this.state.games} activegames={this.state.activegames} rowData={this.state.rowData} getGames={this.getGames}  />
                </div>
            )
        }
    }
}