import React, { Component } from "react";
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'
import Auth from "../common/Auth";
import Config from "../Config";
import GameChart from "./GameChart";

const curformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2})
const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 0, minimumFractionDigits: 0})

export default class LiveFeeds extends Component {
  constructor() {
    super();

    this.auth = new Auth();
    this.config = new Config();

    this.$http = this.config.DConnect()

    this.state = {
      ticketSummary: {stakes:0, stakesAmount:0.00, retailers:0, commission:0.00},
      hourlySales: [],
      agentSummary: [],
      games: []
    }
  }
  
  componentDidMount() {
    this.getDropDowns()
  }

  getDropDowns = () => {
    this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
    .then(res => {
      let games = this.auth.decryptData(res.data)

      this.setState({ games });
      this.getLiveStakes();
      this.getAgentSummary();
    })
  }

  getLiveStakes = () =>{
    this.$http.get('dashboard/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/livestakes")
    .then(res => {
        let resdata = this.auth.decryptData(res.data)
        let ticketgroup = _.groupBy(resdata, 'ticketSerial')
        let stakes = 0
        Object.entries(ticketgroup).map(([k, v]) => {stakes += 1})
        
        let retailergroup = _.groupBy(resdata, 'retailer')
        let retailers = 0
        Object.entries(retailergroup).map(([k, v]) => {retailers += 1})
        let totalSales = _.reduce(resdata, function(sum, n){return sum + n.ticketTotal}, 0)

        let ticketSummary = {stakes, 'stakesAmount':totalSales.toFixed(2), 
        retailers, 'commission':(totalSales*.2).toFixed(2)}

        let chartTitle = ['Hours']
        let chartHead = ['Hours']
        let games = this.state.games

        let sales = {}
        let gamegroup = _.groupBy(resdata, 'gamecode')
        Object.entries(gamegroup).map(([k, v]) => {
            let hsales = _.groupBy(v, rdata => moment(rdata.saleDate).format('HH'))
            let stotal = _.reduce(v, function(sum, n){return sum + n.ticketTotal}, 0)

            //Game name
            let gamename = games.filter(gm => gm.id == k)
            chartTitle.push(gamename[0].gamename)
            chartHead.push(k)
            sales[k] = []

            Object.entries(hsales).map(([kk, vv]) => {
              let total = _.reduce(vv, function(sum, nn){return sum + nn.ticketTotal}, 0)
              //let saleper[kk] = {kk'sale':total, 'total':stotal.toFixed(2)}
              sales[k].push({'hour':kk, 'sale':total.toFixed(2)})
            })
        })

        chartTitle.push('Commission')
        chartHead.push('Commission')

        let hourlySales = []
        hourlySales.push(chartTitle)
        for(let i=0; i<24; i++){
          let chart = []
          let chartotal = 0
          chartHead.map(ct =>{
            if(ct == 'Hours'){
              chart.push(i)
            }else if(ct == 'Commission'){
              let commission = (chartotal*.2).toFixed(2)
              chart.push(parseFloat(commission))
            }else{
              let gameData = sales[ct].filter(sale => sale.hour == i)
              let st = _.reduce(gameData, function(sum, ss){return sum + parseFloat(ss.sale)}, 0)
              
              chartotal += st
              chart.push(st)
            }
          })

          hourlySales.push(chart)
        }
        

        //alert(JSON.stringify( hourlySales ))
        this.setState({ ticketSummary, hourlySales })
    })
  }

  /*getTicketSummary = () =>{
    this.$http.get('dashboard/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/summary")
    .then(res => {
        let ticketSummary = this.auth.decryptData(res.data)

        this.setState({ ticketSummary })
    })
  }*/

  getAgentSummary = () =>{
    this.$http.get('dashboard/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/agentsummary")
    .then(res => {
        let resdata = this.auth.decryptData(res.data);

        let orderedAS = _.orderBy(resdata,['amount'],['desc']);
        let agentSummary = orderedAS.slice(0,5);

        this.setState({ agentSummary });
    }).catch(error => {alert(error)})
  }

  render() {
    return (
      <div
        className="tab-pane fade active in padding-10 no-padding-bottom"
        id="s1"
      >
        <div className="row no-space">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <GameChart data={this.state.hourlySales} />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 show-stats">
            <h5><b>Top Performing Agents</b></h5>
            <hr className="hrsp" />
            <div className="row">
              {this.state.agentSummary.map(asum => (
              <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12">
                <span className="text">{asum.name} ({asum.username})<span className="pull-right">GH&cent;{curformat.format(asum.amount)}</span></span>
                <div className="progress">
                  <div className="progress-bar bg-color-blueDark" style={{ width: `${(asum.amount/this.state.ticketSummary.stakesAmount)*100}%` }} />
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12"><hr className="hrsp" /></div>
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="panel panel-warning text-align-center" style={{margin:'0px'}}>
              <div class="panel-heading">Today's Stakes</div>
              <div class="panel-body"><b>{numformat.format(this.state.ticketSummary.stakes)}</b></div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="panel panel-primary text-align-center" style={{margin:'0px'}}>
              <div class="panel-heading">Today's Stake Amount</div>
              <div class="panel-body"><b>GH&cent; {curformat.format(this.state.ticketSummary.stakesAmount)}</b></div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
          <div class="panel panel-success text-align-center" style={{margin:'0px'}}>
              <div class="panel-heading">Today's Retailers</div>
              <div class="panel-body"><b>{numformat.format(this.state.ticketSummary.retailers)}</b></div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="panel panel-danger text-align-center" style={{margin:'0px'}}>
              <div class="panel-heading">Today's Commission</div>
              <div class="panel-body"><b>GH&cent; {curformat.format(this.state.ticketSummary.commission)}</b></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
