import React, { Component } from 'react'
import EmailMessages from './EmailMessages'
import EmailLists from './EmailLists'

export default class Emailing extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-building"></i> </span>
                            <h2><strong>Email System</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="email_inner" className="widget-body">
                                <div className="row">
                                    <article className="col-xs-12">
                                        <ul className="nav nav-tabs in" id="tablist">
                                            <li className="active"><a href="#sms_templates" data-toggle="tab">Email Messages</a></li>
                                            <li><a href="#sms_reciever" data-toggle="tab">Internal Email</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade in active padding-10 no-padding-bottom" id="sms_templates"><EmailLists /></div>
                                            <div className="tab-pane fade in padding-10 no-padding-bottom" id="sms_reciever"><EmailMessages /></div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
       )
    }
}