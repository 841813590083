import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as jsPDF from 'jspdf'
import Auth from '../../common/Auth'
import Config from '../../Config'
import AgentSale from '../AgentSale';
import moment from 'moment'

const colstyle = {fontSize:'30px', display:'none'}
const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

function showReport(img, row, data, comp){
    const pdf = new jsPDF("p", "mm", "a4")
    const columns = [{title:"Agent ID-Name", dataKey:"agent"}, {title:"Gross", dataKey:"gross"}, 
    {title:"Commission", dataKey:"commission"}, {title:"Net", dataKey:"net"}]

    pdf.setProperties({
        title: 'GAME SALES REPORT 30%',
        subject: 'GAME SALES REPORT 30%',
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })

    alert(img);
    if(img !== 'null' && img !== ''){
        let tp = 'PNG'
        if(img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1){
            tp = 'JPEG'
        }

            pdf.addImage(img,tp,15,8,0,23)
    }
        
    pdf.autoTable({
        html: '#tbCompany', 
        theme: 'plain',
        styles: {fontSize: 16, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{ cellWidth:15}, 
            1: {halign: 'center', overflow: 'linebreak', cellWidth:80}, 
            2: {halign:'right', valign:'bottom', cellWidth:25, fontSize:8, fontStyle:'normal'}
        }
    })
    pdf.line(14, 40, 196, 40)
    pdf.autoTable({
        html: '#tbTitles', 
        theme: 'striped',
        //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0: {cellWidth:30, halign:'right'}, 1: {cellWidth:50, fontStyle:'bold'}, 
            2: {cellWidth:30, halign:'right'}, 3: {cellWidth:50, fontStyle:'bold'}
        }
    })

    pdf.autoTable(columns, row, {
        theme : 'striped',
        styles: {overflow: 'linebreak'},
        headStyles: {halign:'center'},
        columnStyles: {gross: {halign:'right'}, commission: {halign:'right'}, net: {halign:'right'}},
        margin: { top: 46 }
    })

    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}

function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename?filename+'.xls':'excel_data.xls';
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}
function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
function exportTableToCsv(tableID, filename = ''){
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            
            for (var j = 0; j < cols.length; j++) 
                row.push(cols[j].innerText);
            
            csv.push(row.join(","));		
        }
        download_csv(csv.join("\n"), filename);
}

export default class GameSalePdf extends Component {
    constructor(props) {
        super(props)

        this.config = new Config() 
        this.auth = new Auth()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            data:this.props.data,
            companySetup: ''
        }
    }

    componentDidMount() {
        this.getCompanySetup()
    }

    componentDidUpdate(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let rowData = nextProps.rowData
            let data = nextProps.data

            this.setState({ rowData, data })

            this.getCompanySetup()
        }
    }

    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            let rowData=this.state.rowData
            let data=this.state.data
            let url = 'admin/wapp/image/custfiles/' + companySetup.logo
            this.$http.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                let img = new Buffer(res.data, 'binary').toString('base64')
                showReport(img, rowData, data, companySetup)
            }).catch((error)=>{showReport('', rowData, data, companySetup)})

            this.setState({ companySetup })
        }).catch((error)=>{alert(error)})
    }
    
    goBack = () => {
        ReactDOM.render(
            <AgentSale />,
            document.getElementById('generalReport_inners')
         )
    }
    exportTableToExcels=()=>{
        
        exportTableToExcel('datas', 'gamesales')
    }
    exportTableToCsv=()=>{
        exportTableToCsv('datas', 'gamesales')
    }
    
    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Game Sales Report</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10">
                                        <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                    </div>
                                    <div className="col-xs-12 col-sm-2">
                                        <div className="btn-group btn-group-vertical btn-group-sm">
                                            <button className="btn btn-warning" onClick={this.goBack}> 
                                                <i className="fa fa-backward"></i> Back
                                            </button>
                                            <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                            <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                                        </div>
                                    </div>
                                    <div className="col-xs-12" id="datas">
                                        <table id="tbCompany" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="3">&nbsp;<img src={this.config.BaseURL()+this.state.url} /></td>
                                                    <td>{this.state.companySetup.companyName}</td>
                                                    <td rowSpan="3">
                                                    {this.state.companySetup.address} <br />
                                                    TEL: {this.state.companySetup.phone} <br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>GAME SALES REPORT 30%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tbTitles" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                <tr>
                                                    <td>Event Number: </td><td><b>{this.state.data.event}</b></td>
                                                    <td></td><td><b></b></td>
                                                </tr>
                                                <tr>
                                                    <td>Game Name: </td><td><b>{this.state.data.gamename}</b></td>
                                                    <td>Total Gross: </td><td><b>{numformat.format(this.state.data.gross)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Draw Date: </td><td><b>{moment(this.state.data.drawdate).format('DD MMM, YYYY')}</b></td>
                                                    <td>Total Net: </td><td><b>{numformat.format(this.state.data.net)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-condensed" style={colstyle}>
                                            <tr>
                                                <th>Agent ID-Name</th>
                                                <th>Gross</th>
                                                <th>Commission</th>
                                                <th>Net</th>
                                            </tr>
                                            {this.state.rowData.map(game => (
                                            <tr>
                                                <td>{game.agent}</td>
                                                <td>{game.gross}</td>
                                                <td>{game.commission}</td>
                                                <td>{game.net}</td>
                                            </tr>
                                            ))}
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>

        )
    }
}
