import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import ProfileList from './ProfileList';
import AgentList from '../agents/AgentList';
import RetailersList from '../agents/RetailersList';
import MyProfileDetails from './MyProfileDetails';

const ISDN = [{'id':'MTN', 'name':'MTN'}, {'id':'VDF', 'name':'Vodafone'}, {'id':'ATL', 'name':'Airtel'}, {'id':'TIGO', 'name':'Tigo'}]

export default class ProfileForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            isUsertype: false,
            username: '',
            roles: [],
            formItems: {
                username:'', firstname:'', lastname:'', middlename:'', filex:'',
                email:'', mobile:'', pin:'', auth:0, isNew:true, status:1, isdn:'',
                createdby:this.auth.getUser(), createdon: new Date(), password: '',
                modifiedby:null, modifiedon:null, token:this.auth.getToken(), 
                superior:'', curuser:this.auth.getUser()
            },
            contactItems: {
                id:0, owner:'profile', ownerId:'', phone:'', gps:'', 
                country:1, region:1, area:'', location:'', address:'',
                gtgps:'', curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            usertypeItems:{},
            area: 'profileCon',
            criteria: this.props.criteria,
            docItems: {picture_filename: null},
            rolename: '',
            operators: [],
            curOperator:{}
        }
    }

    componentDidMount(){
        let username = this.props.username
        let isEdit = this.state.isEdit
        let formItems = this.state.formItems
        let isUsertype = this.state.isUsertype
        let usertypeItems = this.state.usertypeItems
        if(username !== ''){
            isEdit = true
            formItems['isNew'] = true
            this.getUserData(username)
        }

        //Check if coming from Agent & Retailers
        if(this.props.uitems!== undefined && this.props.uitems.username !== undefined){
            isUsertype = true
            usertypeItems = this.props.uitems
        }

        //Check area
        let area = (this.props.area !== undefined) ? this.props.area:this.state.area

        this.setState({ formItems, username, isUsertype, usertypeItems, area, isEdit })

        this.getListItems()

        this.getOperators()

        this.getCurOperator()
    }

    componentDidUpdate(nextProps){
        if(nextProps.username !== this.props.username){
            let username = nextProps.username
            let isEdit = this.state.isEdit
            let formItems = this.state.formItems
            let isUsertype = this.state.isUsertype
            let usertypeItems = this.state.usertypeItems
            if(username !== ''){
                isEdit = true
                formItems['isNew'] = true

                this.getUserData(username);
            }

            //Check if coming from Agent & Retailers
            if(nextProps.uitems.username !== undefined){
                isUsertype = true
                usertypeItems = nextProps.uitems
            }

            let criteria = nextProps.criteria

            //Check area
            let area = (nextProps.area !== undefined) ? nextProps.area:this.state.area
            this.setState({ formItems, username, isUsertype, usertypeItems, area, criteria, isEdit })

            this.getListItems()
        }
    }

    getListItems = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/role')
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let roles = resdata.filter(rdata => ![1,4,5,6].includes(rdata.id))

            this.setState({ roles })
        }) 
    }

    getUserData = (username) => {
        this.$http.get('admin/wapp/user/'  + username)
        .then(res => {
            if(isNaN(res.data)){
                let formItems = this.auth.decryptData(res.data)
                formItems['token'] = this.auth.getToken()
                
                this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/contact/'  + username)
                .then(res => {
                    let resdata = this.auth.decryptData(res.data)
                    
                    let contactItems = (resdata.phone !== undefined) ? resdata : this.state.contactItems
                    contactItems['token'] = this.auth.getToken()
                    this.setState({ formItems, contactItems })
                })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        })//.catch((error)=>{alert(error)})
    }

    getOperators = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/user/3')
        .then(res => {
            if (isNaN(res.data)) {
                let operators = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                
                if(operators[0]!== undefined){
                    formItems['superior'] = operators[0].username
                }
                
                this.setState({ operators, formItems })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }

        })
    }

    getCurOperator = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/useroperator')
        .then(res => {
            if (isNaN(res.data)) {
                let curOperator = this.auth.decryptData(res.data)
                
                this.setState({ curOperator })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }

        })
    }

    goBack = () => {
        if(this.state.isUsertype){
            let cpt = (this.state.usertypeItems.area === 'agent') ? <AgentList criteria={this.state.criteria} /> 
            : <RetailersList agent={this.props.agent} agents={this.props.agents} criteria={this.state.criteria} />
            return(
                ReactDOM.render(cpt, document.getElementById('agent_inner'))
            )
        }else{
            let backTo = (this.state.area == 'profileCon') ? <ProfileList role={this.props.role} /> : <MyProfileDetails />
            return(
                ReactDOM.render(backTo, document.getElementById(this.state.area))
            )
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    contactChange = (e) => {
        let contactItems = this.state.contactItems
        contactItems[e.target.name] = e.target.value
        
        this.setState({ contactItems })
    }

    imageClick = (id) => {
        document.getElementById(id).click()
    }

    imageChange = (e, picon) =>{
        let img = e.target.files[0]
        let ext = img.name.split('.')
        ext = ext[ext.length-1].toLowerCase()

        if(ext === 'jpg' || ext === 'jpeg' || ext === 'png'){
            let docItems = this.state.docItems
            let formItems = this.state.formItems
            docItems['picture_filename'] = img
            formItems['filex'] = ext
            
            this.setState({ docItems, formItems })

            let imgurl =  URL.createObjectURL(img)
            ReactDOM.render(
                <img src={imgurl} style={{width:'100px'}} />,
                document.getElementById(picon)
            )
        }else{
            alert('The supported files are: jpg and png...')
        }
    }

    formSubmit = (e) => {
        e.preventDefault()

        let usertypeItems = this.state.usertypeItems
        let formItems = this.state.formItems
        formItems['curuser'] = this.auth.getUser()
        
        //alert(JSON.stringify(this.state.usertypeItems))
        if(usertypeItems.area !== undefined){
            let role = (usertypeItems.area == 'agent') ? 4 : 5
            formItems['auth'] = role
        }
        if(formItems.auth > 6 && formItems.superior == ''){
            formItems['superior'] = this.state.curOperator.username
        }else if(formItems.auth <= 6 && formItems.superior != ''){formItems['superior'] = ''}
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        let contactItems = this.state.contactItems

        if(contactItems.location.trim() == ''){
            alert('Location Field is required...')
            return 0
        }
        contactItems['ownerId'] = formItems.username
        let contactData = this.auth.encryptData(JSON.stringify(contactItems))

        axios(this.config.PostOptions(formData, 'admin/wapp/user'))
        .then((res) => {
            this.saveImage(formItems.username, this.auth.decryptText(res.data))

            if(isNaN(res.data)){
                axios(this.config.PostOptions(contactData, 'admin/wapp/contact'))
                .then((ress) => {
                    
                    if(this.state.isUsertype){
                        let usertypeItems = this.state.usertypeItems
                        usertypeItems['username'] = formItems.username
                        let utypeData = this.auth.encryptData(JSON.stringify(usertypeItems))
                        axios(this.config.PostOptions(utypeData, 'admin/wapp/enableusertype'))
                        .then((res) => {
                            
                        }).catch((error)=>{alert(error)})
                    }
                    this.goBack()
                })//.catch((error)=>{alert(error)})
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    saveImage = (username, msg) =>{
        let docItems = this.state.docItems
        if(docItems.picture_filename !== null){
            axios(this.config.PostMulti(docItems.picture_filename, 'admin/wapp/agentfile/' + username + '.' + this.state.formItems.filex))
            .then((res) => {
                alert(msg)
                this.goBack()
            }).catch((error)=>{alert(error)})
        }else{
            alert(msg)
            this.goBack()
        }
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button className="btn btn-warning" onClick={this.goBack}>
                            <i className="fa fa-lg fa-backward"></i> Go Back
                        </button>
                        <button type="submit" className="btn btn-success" form="frmUser">
                            <i className="glyphicon glyphicon-save"></i> Save User
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10">
                    <form id="frmUser" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Username: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="username" placeholder="Username" required 
                                        value={this.state.formItems.username} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Firstname: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="firstname" placeholder="First Name" required 
                                        value={this.state.formItems.firstname} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Lastname: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input"> 
                                        <input type="text" name="lastname" placeholder="Last Name"  required
                                        value={this.state.formItems.lastname} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Middlename: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="middlename" placeholder="Middle Name" 
                                        value={this.state.formItems.middlename} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>
                            
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Email: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="email" placeholder="Email Address" 
                                        value={this.state.formItems.email} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Mobile: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="mobile" placeholder="Mobile Number" required 
                                        value={this.state.formItems.mobile} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Network: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="select">
                                        <select name="isdn" value={this.state.formItems.isdn} onChange={this.handleChange}>
                                            {ISDN.map(isdn => <option value={isdn.id}>{isdn.name}</option>)}
                                        </select> <i></i> 
                                    </label>
                                </section>
                            </div>

                            {(this.state.usertypeItems.area === undefined || this.state.usertypeItems.area === 'retailer') &&
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">PIN: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="pin" placeholder="PIN Code" minLength="4" maxLength="4" required 
                                        value={this.state.formItems.pin} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>}
                            {!this.state.isEdit && 
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Password: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="password" name="password" placeholder="Password" minLength="8" required 
                                        value={this.state.formItems.password} onChange={this.handleChange}/>
                                    </label>
                                </section>
                            </div>}
                            {this.state.usertypeItems.area === undefined && 
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Role: </label>
                                <section className="col col-8">
                                    {![1,4,5,6].includes(this.state.formItems.auth) ?
                                    <label className="select">
                                        <select name="auth" value={this.state.formItems.auth} onChange={this.handleChange}>
                                            {this.state.roles.map(role => (
                                                <option value={role.id}>{role.rolename}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                    : <div className="ctrltxt table-bordered"> &nbsp; {this.state.formItems.rolename}</div>}
                                </section>
                            </div>}
                            {this.state.formItems.auth > 6 && 
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Operator: </label>
                                {this.state.curOperator.username !== undefined ?
                                <div className="ctrltxt table-borered">{this.state.curOperator.firstname + ' ' + this.state.curOperator.lastname}</div>
                                :<section className="col col-8">
                                    <label className="select">
                                        <select name="superior" value={this.state.formItems.superior} onChange={this.handleChange}>
                                            {this.state.operators.map(op => (
                                                <option value={op.username}>{op.firstname} {op.lastname}</option>
                                            ))}
                                        </select> <i></i> 
                                    </label>
                                </section>}
                            </div>}
                        </div>
                        
                    </form>
                </article>
                <article className="col-xs-12 col-sm-2">
                    <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('pfilename')}>
                        <img src={this.config.ImagePath('custfiles') + '/' + this.state.formItems.username + '.' + this.state.formItems.filex} style={{width:'100'}} alt="Photo" />
                    </div>
                    <input type="file" name="pictureFilename" style={{display:'none'}} id="pfilename" onChange={(e)=>this.imageChange(e,'custImgCon')} />
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10">
                    <form className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Phone: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="phone" placeholder="phone" 
                                        value={this.state.contactItems.phone} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Address: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="address" placeholder="Address" 
                                        value={this.state.contactItems.address} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Location: <span className='text-danger'>*</span></label>
                                <section className="col col-8">
                                    <label className="input"> 
                                        <input type="text" name="location" placeholder="Location"
                                        value={this.state.contactItems.location} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Gt GPS: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="gtgps" placeholder="Gt. GPS" 
                                        value={this.state.contactItems.gtgps} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>
                            
                            {/*<div className="col-xs-12  col-sm-6 col-md-4">
                                <label className="label col col-3 text-right">Country: </label>
                                <section className="col col-9">
                                    <label className="input">
                                        <input type="text" name="country" placeholder="Country" 
                                        value={this.state.contactItems.country} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6 col-md-4">
                                <label className="label col col-3 text-right">Region: </label>
                                <section className="col col-9">
                                    <label className="input">
                                        <input type="text" name="region" placeholder="Region" 
                                        value={this.state.contactItems.region} onChange={this.contactChange}/>
                                    </label>
                                </section>
                                            </div>*/}

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Area: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="area" placeholder="Area" 
                                        value={this.state.contactItems.area} onChange={this.contactChange}/>
                                    </label>
                                </section>
                            </div>
                        </div>
                        
                    </form>
                </article>
            </div>
        )
    }
}