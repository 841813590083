import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as jsPDF from 'jspdf'
import axios from 'axios'
import Auth from '../../common/Auth'
import Config from '../../Config'
import base64Img from 'base64-img'
import moment from 'moment'
import RetailerPerAgent from '../RetailerPerAgent';

const colstyle = {
    fontSize:'30px',
    display:'none'
}
const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});
let images={}
function showReport(img, row, data, comp){
    const pdf = new jsPDF("p", "mm", "a4")

    pdf.setProperties({
        title: 'LIST OF RETAILERS for ' + data.agent + ' (' + data.agentid + ')',
        subject: 'LIST OF RETAILERS for ' + data.agent + ' (' + data.agentid + ')',
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })

   

    pdf.autoTable({
        html: '#tbCompany', 
        theme: 'plain',
        styles: {fontSize: 16, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{cellWidth:30}, 
            1: {halign: 'center'}, 
            2: {halign:'right', valign:'bottom', cellWidth:30, fontSize:8, fontStyle:'normal'}
        }
    })

    if(img !=''){
        let tp = 'PNG'
           if(img.indexOf('jpeg') != -1 || img.indexOf('jpg') != -1){
               tp = 'JPEG'
           }
           pdf.addImage(img,tp,15,10,0,23)
        }

    pdf.line(14, 45, 196, 45)
    let y=45
    
        row.map(rw =>{
        pdf.autoTable({
            html: '#tb_' + rw.username, 
            theme: 'striped',
            //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
            columnStyles: {
                0: {cellWidth:30, fillColor:'#eeeeee'}, 1: {cellWidth:30, halign:'right'},
                2: {cellWidth:40, fontStyle:'bold'}, 3: {cellWidth:30, halign:'right'}, 4: {cellWidth:40, fontStyle:'bold'}
            }
        })
        /*if(images[rw.username] !='' && images[rw.username] !=undefined){
            let tp = 'PNG'
            if(images[rw.username].indexOf('jpeg') != -1 || images[rw.username].indexOf('jpg') != -1){
                tp = 'JPEG'
            }
            pdf.addImage(images[rw.username],tp,15,y,0,29)
            
            
        }
        y+=45*/
    })


    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}
function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename?filename+'.xls':'excel_data.xls';
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}
function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
function exportTableToCsv(tableID, filename = ''){
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            
            for (var j = 0; j < cols.length; j++) 
                row.push(cols[j].innerText);
            
            csv.push(row.join(","));		
        }
        download_csv(csv.join("\n"), filename);
}
export default class RetailersListPdf extends Component {
    constructor(props) {
        super(props)
        this.config = new Config() 
        this.auth = new Auth()
        this.$http = this.config.DConnect()

        this.state = {
            retailer: this.props.retailer, 
            data:this.props.data,
            companySetup: ''
        }
    }

    goBack = () => {
        ReactDOM.render(
            <RetailerPerAgent />,document.getElementById('generalReport_inners')
        )
    }

    componentDidMount() {
        this.getCompanySetup()
    }

    componentDidUpdate(nextProps){
        if(nextProps.retailer !== this.props.retailer){
            let retailer = nextProps.retailer
            let data = nextProps.data

            this.setState({ retailer, data })
            this.getCompanySetup()
        }
    }

    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)

            let retailer=this.state.retailer
            let data=this.state.data

            /*retailer.map(dt=>{
                let aurl='admin/wapp/image/custfiles/' +agt.username + '.'+ agt.filex*/
                let url = 'admin/wapp/image/custfiles/' + companySetup.logo

                /*this.$http.get(aurl, {responseType: 'arraybuffer'})
                .then((res) => {
                    let aimg = new Buffer(res.data, 'binary').toString('base64')
                    images[agt.username.replace(/ /g, '_')] = aimg


                    if(agents[agents.length - 1].username == agt.username){*/
                        this.$http.get(url, {responseType: 'arraybuffer'})
                        .then((res) => {
                            let img = new Buffer(res.data, 'binary').toString('base64')
                            showReport(img, retailer,data, companySetup)
                        }).catch((error)=>{showReport('', retailer, data, companySetup)})
                    /*}
                })
            })*/

            this.setState({ companySetup })
        }).catch((error)=>{alert(error)})
    }
    exportTableToExcels=()=>{
        
        exportTableToExcel('datas', 'Agent-List')
    }
    exportTableToCsv=()=>{
        exportTableToCsv('datas', 'Agent-List')
    }


    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Retailers Per Agent</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10">
                                        <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                    </div>
                                    <div className="col-xs-12 col-sm-2">
                                        <div className="btn-group btn-group-vertical btn-group-sm">
                                            <button className="btn btn-warning" onClick={this.goBack}> 
                                                <i className="fa fa-backward"></i> Back
                                            </button>
                                            <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                            <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                                       </div>
                                    </div>
                                    <div className="col-xs-12" id="datas">
                                        <table id="tbCompany" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                <tr>
                                                        <td rowSpan="3">&nbsp;<img src={this.config.BaseURL()+this.state.url} /></td>
                                                        <td>{this.state.companySetup.companyName}</td>
                                                        <td rowSpan="3">
                                                        {this.state.companySetup.address} <br />
                                                        TEL: {this.state.companySetup.phone} <br />
                                                        </td>
                                                    </tr>
                                                <tr>
                                                    <td>{this.state.data.agent} ({this.state.data.agentid})<br />LIST OF RETAILERS </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.retailer.map(agent => (
                                        <table id={'tb_'+agent.username} className="table table-condensed" style={colstyle}>
                                            <tbody>
                                            
                                                <tr>
                                                    <td rowSpan="5"></td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>Retailer Name: </td>
                                                    <td colSpan="3"><b>{agent.fullname}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Retailer ID: </td>
                                                    <td><b>{agent.username}</b></td>
                                                    <td>Cash Bal.: </td>
                                                    <td><b>GH&cent; {numformat.format(agent.cashinhand)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Phone No.: </td>
                                                    <td><b>{agent.mobile}</b></td>
                                                    <td>Unit Bal.: </td>
                                                    <td><b>{numformat.format(agent.unit)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Location: </td>
                                                    <td><b>{agent.location}</b></td>
                                                    <td>Terminal: </td>
                                                    <td><b>{agent.imei}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}