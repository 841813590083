import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import 'jspdf-autotable'

export default class GeneralReportList extends Component{
    constructor(){
        super()
    }
    
    render(){
        return(
            <div className="row">
                <div className="col-xs-12">
                    hello
                </div>
            </div>
        )
    }
}