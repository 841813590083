import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import 'jspdf-autotable';
import { BrowserRouter, Link } from 'react-router-dom';
import ReportView from './ReportView';
import AgentListPdf from './Pdf/AgentListPdf';
import QueryJson from '../common/QueryJson'


export default class AgentLists extends Component {
    constructor() {
        super()


        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            operators: [],
            agents: [],
            agent: '',
            criteria: 0,
            loader: false,
            operator: '',
            usertypeItems: {
                username: '', usertype: '2', superior: '', bankname: '', area: 'agent',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        this.getOperators(systemDefine)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ loader: true })
            this.getOperators()
        }
    }
    handleChange = (e) => {
        let operator = e.target.value
        //this.getAgents(operator)
        this.setState({ operator })

    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
                .then(res => {
                    if (isNaN(res.data)) {
                        let operators = this.auth.decryptData(res.data)
                        let operator = (operators[0] != undefined) ? operators[0].username : operators.username
                        //this.getAgents(operator)
                        this.setState({ operator, operators, loader: false })

                    } else {
                        this.auth.doAlert(parseInt(res.data))
                    }

                }).catch(error => alert(JSON.stringify(error)));
        }
    }
    

    getAgents = () => {
        this.setState({ loader: true });

        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails/' + this.state.operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data);

                this.setState({ agents, loader: false })
            }).catch(error => {alert(error)})
    }

    goBack= () => {
        ReactDOM.render(
            <ReportView />,
            document.getElementById('generalReport_inners')
        )
    }

    printPreview = () => {
        ReactDOM.render(
            <AgentListPdf agents={this.state.agents} operator={this.operatorName(this.state.operator)} />,
            document.getElementById('generalReport_inners')
        )
    }

    operatorName=(username)=>{
        let operator = new QueryJson(this.state.operators, 'username=' + username)
        return (operator[0]!=undefined)?operator[0].fullname:""
    }

    loading = () => <div id="loader"></div>

    render() {
        if (this.state.loader) {
            return this.loading()
        } else {
            return (

                <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Agent List</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                            <div className="row" id="generalReport_inners">
                <div>



                    
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Operator: </button>
                            <select name="criteria" className="btn btn-default" style={{ width: '200px', height:'30px' }}
                                value={this.state.operator} onChange={this.handleChange}>
                                {this.state.operators.map(op => (
                                    <option value={op.username}>{op.fullname}</option>
                                ))}
                            </select>
                            
                            <button className="btn btn-info btnDisplay" onClick={this.getAgents}>Display</button>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                        <button className="btn btn-warning " onClick={this.goBack}>Go Back</button>
                            <button className="btn btn-primary " onClick={this.printPreview}>Preview</button>
                        </div>
                        <hr className="hrsp" />
                    </div>
                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{padding:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                        List of Agents ({this.operatorName(this.state.operator)}) 
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 table-bordered">
                        <table className="table table-condensed">
                                {this.state.agents.map(agent => (
                                    <tr>
                                        <td>
                                            <table className="table table-condensed table-striped">
                                                <tbody>
                                                <tr>
                                                    <td width="120px" rowSpan="4">
                                                        <div className="table-bordered text-center" style={{width:'100px', lineHeight:'120px'}}>
                                                            <img src={this.config.ImagePath('custfiles') + '/' + agent.username + '.' + agent.filex} alt={agent.username} style={{height:'120px',width:'100px'}} />
                                                        </div>
                                                    </td>
                                                    <td width="100px">&nbsp;</td>
                                                    <td width="150px">&nbsp;</td>
                                                    <td width="100px">&nbsp;</td>
                                                    <td width="150px">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-right" width="100px">Agent Name: </td>
                                                    <td colSpan="3"><b>{agent.fullname}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-right" width="100px">Agent ID: </td>
                                                    <td width="150px"><b>{agent.username}</b></td>
                                                    <td className="text-right" width="100px">Phone No.: </td>
                                                    <td width="150px"><b>{agent.mobile}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-right" width="100px">Location: </td>
                                                    <td width="150px"><b>{agent.location}</b></td>
                                                    <td className="text-right" width="100px">Total Retailers: </td>
                                                    <td width="150px"><b>{agent.retailers}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}
                        </table>
                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            )
        }
    }
}