import React, { Component } from 'react';
import Auth from '../common/Auth';
import Config from '../Config';
import axios from 'axios';
import $ from 'jquery';

var curformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

export default class CashierFunds extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                username:'', amount:0, trantype:1, createdby:this.auth.getUser(),
                createdon:new Date(), curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            cashier: this.props.cashier
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let cashier = nextProps.cashier;

            this.setState({ cashier });
        }
    }

    handleChange = (e) =>{
        let formItems = this.state.formItems;

        formItems[e.target.name] = e.target.value;

        this.setState({ formItems });
    }

    formSubmit = (e) => {
        e.preventDefault()

        let { formItems,cashier } = this.state;
        formItems['username'] = cashier.username;
        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/cashier/topup'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data));
                
                $('#btnFundcancel').click();
                this.props.getReportData();
            }else{
                this.auth.doAlert(parseInt(res.data));
            }
            
        }).catch((error)=>{alert(error)});
    }

    render(){
        let { formItems, cashier } = this.state;
        return(
            <div className="modal fade" id="fundform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Add Fund</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmFund" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">Amount: </label>
                                        <section className="col col-7">
                                            <input type="number" min="0" step="any" name="amount" className="form-control" value={formItems.amount} onChange={this.handleChange} />
                                        </section>
                                    </div>
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">Trans Type: </label>
                                        <section className="col col-7">
                                            <label className="select">
                                                <select name="trantype" className="form-control" value={formItems.trantype} onChange={this.handleChange} style={{height:'32px',width:'180px'}}>
                                                    <option value="1">Increase</option>
                                                    <option value="2">Decrease</option>
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">Cashier Balance: </label>
                                        <section className="col col-7">
                                            <span className="ctrltxt">{(cashier == undefined) ? 0.00 : curformat.format(parseFloat(cashier.cashlimit))}</span>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnFundcancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmFund" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}