import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import Chance from 'chance'
import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import DrawScreeningForm from './DrawScreeningForm';
import DrawScreenPrint from './DrawScreenPrint';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class DrawScreenPreview extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            games: [],
            events: [],
            bets:[],
            formItems: {
                id:0, gamecode:0, drawno:0, screenNum:'', createdby: this.auth.getUser(),
                createdon: new Date(), curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            screenTotals: {},
            betList: [],
            screenNum:{'snum0':'-', 'snum1':'-', 'snum2':'-', 'snum3':'-', 'snum4':'-'},
            isScreen: false,
            isPreview: false
        }
    }

    componentDidMount(){
        if(this.props.rowData !== undefined){
            let formItems = this.props.rowData

            this.setState({ formItems })
        }

        this.getGames()
    }

    componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){
            if(nextProps.rowData !== undefined){
                let formItems = nextProps.rowData

                this.setState({ formItems })
            }

            this.getGames()
        }
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/betting')
        .then(res => {
            let bets = this.auth.decryptData(res.data)

            this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
            .then(res => {
                let games = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                if(games[0] !== undefined){
                    formItems['gamecode'] = games[0].id
                    this.getEvents(games[0].id)
                }

                this.setState({ bets, games, formItems })
            })
        })
    }

    getEvents = (gamecode) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/closedgames/' + gamecode)
        .then(res => {
            let events = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            
            if(events[0] !== undefined){
                formItems['drawno'] =events[0].drawno
            }

            this.setState({ events, formItems, loader:false })
        }).catch((error)=>{this.setState({loader:false})})
    }

    handleDisplay = () => {
        let formItems = this.state.formItems
        let event = this.state.events.filter(ev => ev.gamecode == formItems.gamecode && ev.drawno == formItems.drawno)

        if(event[0] !== undefined && event[0].screenNumbers.trim()!=''){
            let formItems = this.state.formItems
            let screenNum = this.state.screenNum
            let snum = event[0].screenNumbers.split('-')

            screenNum['snum0'] = snum[0]
            screenNum['snum1'] = snum[1]
            screenNum['snum2'] = snum[2]
            screenNum['snum3'] = snum[3]
            screenNum['snum4'] = snum[4]

            formItems['screenNum'] = snum[0] + '-' + snum[1] + '-' + snum[2] + '-' + snum[3] + '-' + snum[4]
            let formData = this.auth.encryptData(JSON.stringify(formItems))
            axios(this.config.PostOptions(formData, 'draw/wapp/generatetotals'))
            .then((res) => {
                if(isNaN(res.data)){
                    let screenTotals = this.auth.decryptData(res.data)
                    let bdata = (screenTotals.bmodel !== undefined) ?  screenTotals.bmodel : []
                    let betList = bdata.map(bd =>{
                        let bet = this.state.bets.filter(bt => bt.id == bd.betcode)
                        let betname = (bet[0]!==undefined) ? bet[0].betname : bd.betcode
                        bd['betname'] = betname

                        return bd
                    }) 

                    this.setState({ screenTotals, betList, screenNum, isScreen:true })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }
            })//.catch((error)=>{alert('Generate: ' + error);})
        }else{
            this.setState({ isScreen:false })
            alert('Screen Numbers has not been generated yet...')
        }
    }

    formatGame = (cell) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0]!==undefined) ? game[0].gamename : cell

        return gamename
    }

    dateFormat = (cell) => {
        let event = this.state.events.filter(ev => ev.drawno == cell)
        let drawdate = (event[0]!==undefined) ? moment(event[0].drawDate).format('DD MMM, YYYY @ hh:mma') : moment()

        return drawdate
    }

    amtFormat = (cell) => numformat.format(cell)

    handleChange = (e) => {
        let isScreen = false
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems, isScreen })

        if(e.target.name === 'gamecode'){
            $('[id^="numCon"]').val('-')
            this.getEvents(e.target.value)
        }
    }

    doPreview = () => {
        let fitm = this.state.formItems
        let st = this.state.screenTotals
        let win = (st.totalStakes>0)? ((parseFloat(st.totalWins)/parseFloat(st.totalStakes)) * 100).toFixed(2):0.00
        let gross = (parseFloat(st.totalStakes) - parseFloat(st.totalWins)).toFixed(2)
        let vat = (gross > 0) ? (0.175 * parseFloat(gross)).toFixed(2) : 0.00
        let net = (parseFloat(gross) - parseFloat(vat)).toFixed(2)
        let betList = this.state.betList.map(bl => {
            bl['betAmount'] = numformat.format(bl.betAmount)
            bl['winAmount'] = numformat.format(bl.winAmount)

            return bl
        })
        fitm['gamecode'] = this.formatGame(fitm.gamecode)
        fitm['drawdate'] = this.dateFormat(fitm.drawno)
        st['totalStakes'] = numformat.format(st.totalStakes)
        st['totalWins'] = numformat.format(st.totalWins)
        gross = numformat.format(gross)
        vat = numformat.format(vat)
        net = numformat.format(net)

        let data = {
            screenNum:this.state.screenNum, totals:st, win:win, 
            gross:gross, vat:vat, net:net, fitm:fitm, title:'Pre-Draw Report'
        }
        ReactDOM.render(
            <DrawScreenPrint rowData={betList} data={data} />,
            document.getElementById('drawScreenCon')
        )
    }

    render(){
        let fitm = this.state.formItems
        let st = this.state.screenTotals
        let win = (st.totalStakes>0)? ((parseFloat(st.totalWins)/parseFloat(st.totalStakes)) * 100).toFixed(2):0.00
        let gross = (parseFloat(st.totalStakes) - parseFloat(st.totalWins)).toFixed(2)
        let vat = (gross > 0) ? (0.175 * parseFloat(gross)).toFixed(2) : 0.00
        let net = (parseFloat(gross) - parseFloat(vat)).toFixed(2)

        return(
            <div className="row">
                <div className="col-xs-12">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">Game: </button>
                        <select name="gamecode" className="btn btn-default" style={{width:'150px', height:'30px'}}
                            value={this.state.formItems.gamecode} onChange={this.handleChange}>
                            {this.state.games.map(game => (
                                <option value={game.id}>{game.gamename}</option>
                            ))}
                        </select>
                        <button className="btn btn-default">Event: </button>
                        <select name="drawno" className="btn btn-def/ ult" style={{width:'150px', height:'30px'}}
                            value={this.state.formItems.drawno} onChange={this.handleChange}>
                            {this.state.events.map(rdata => (
                                <option value={rdata.drawno}>#{rdata.drawno} ({moment(rdata.drawDate).format('DD MMM, YYYY')})</option>
                            ))}
                        </select>
                        <button id="btnScreenGenerate" className="btn btn-primary" onClick={this.handleDisplay}>Pre-Draw</button>
                    </div>
                    <div className="btn-group btn-group-sm pull-right">
                        <button id="btnScreen" className="btn btn-primary" data-toggle="modal" data-target="#screenform">Generate Screen Numbers</button>
                    </div>                   
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                {this.state.isScreen &&
                <div className="col-xs-12 col-sm-2 pull-right">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-primary" onClick={this.doPreview}>Preview</button>
                    </div>
                </div>}
                <div className="col-xs-12 col-sm-10 col-lg-8">
                    {this.state.isScreen &&
                    <table id="tbPreview" className="table table-responsive table-condensed table-stripped">
                        <tbody>
                            <tr>
                                <td colSpan="4">
                                    <div className="row">
                                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                                            <div id="numCon0" className="snumCon bg-color-blueLight txt-color-white">
                                            {this.state.screenNum.snum0}</div>
                                        </div>
                                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                                            <div id="numCon1" className="snumCon bg-color-blueLight txt-color-white">
                                            {this.state.screenNum.snum1}</div>
                                        </div>
                                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                                            <div id="numCon2" className="snumCon bg-color-blueLight txt-color-white">
                                            {this.state.screenNum.snum2}</div>
                                        </div>
                                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                                            <div id="numCon3" className="snumCon bg-color-blueLight txt-color-white">
                                            {this.state.screenNum.snum3}</div>
                                        </div>
                                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                                            <div id="numCon4" className="snumCon bg-color-blueLight txt-color-white">
                                            {this.state.screenNum.snum4}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-right">Game Name: </td><td><b>{this.formatGame(fitm.gamecode)}</b></td>
                                <td className="text-right">Total Tickets: </td><td><b>{st.totalTickets}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Event #: </td><td><b>{fitm.drawno}</b></td>
                                <td className="text-right">Total Amount: </td><td><b>{this.amtFormat(st.totalStakes)}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Draw Date: </td><td><b>{this.dateFormat(fitm.drawno)}</b></td>
                                <td className="text-right">Winning Tickets: </td><td><b>{st.winTickets}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Wins %: </td><td><b>{win} %</b></td>
                                <td className="text-right">Winning Amount: </td><td><b>{this.amtFormat(st.totalWins)}</b></td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <hr className="hrsp" />
                                    <table className="table table-responsive table-condensed table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Bet Name</th><th>No. of Bets</th>
                                                <th className="text-right">Bet Amount</th><th className="text-right">Win Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.betList.map(bet => (
                                                <tr>
                                                    <td>{bet.betname}</td>
                                                    <td>{bet.noOfBets}</td>
                                                    <td className="text-right">{this.amtFormat(bet.betAmount)}</td>
                                                    <td className="text-right">{this.amtFormat(bet.winAmount)}</td>
                                                </tr>)
                                            )}
                                            <tr><td colSpan="4"></td></tr>
                                            <tr>
                                                <td colSpan="3" className="text-right">GROSS: </td>
                                                <td className="text-right"><b>{gross}</b></td>
                                            </tr>
                                            <tr>
                                            <td colSpan="3" className="text-right">VAT/NHIL (17.5%): </td>
                                            <td className="text-right"><b>{vat}</b></td>
                                            </tr>
                                            <tr>
                                            <td colSpan="3" className="text-right">NET: </td>
                                            <td className="text-right"><b>{net}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>}
                </div>
                <DrawScreeningForm events={this.state.events} rowData={this.state.formItems} games={this.state.games} />
            </div>
        )
    }
}