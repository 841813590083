import _ from 'lodash';
import React, { useState, useEffect } from 'react';

function WinPaymentConfirm(props){

    const [agent, setAgent] = useState(props.agent);
    const [unpaidWins, setUnpaidWins] = useState(props.unpaidWins);
    const [groupedWins, setGroupedWins] = useState(props.groupedWins);
    const [totals, setTotals] = useState({'all':0.0, 'active':0.0})

    const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

    useEffect(() => {

        setAgent(props.agent);
        setUnpaidWins(props.unpaidWins);
        setGroupedWins(props.groupedWins);

        getInitData(props.groupedWins, props.agent);
    }, [props.agent, props.groupedWins, props.unpaidWins]);

    const getInitData = (groupedWins, agent) => {

        if(groupedWins === undefined || agent === undefined){
            return;
        }

        let allTotals = 0, activeTotals = 0;
        if(agent == '0'){
            Object.keys(groupedWins).map(key => allTotals += _.sumBy(groupedWins[`${key}`], 'winAmount'));
            Object.keys(groupedWins).map(key => activeTotals += _.sumBy(groupedWins[`${key}`].filter(gw => gw.status == 1), 'winAmount'));
        }else if(groupedWins[`${agent}`] !== undefined){
            allTotals = _.sumBy(groupedWins[`${agent}`], 'winAmount');
            activeTotals = _.sumBy(groupedWins[`${agent}`].filter(gw => gw.status == 1), 'winAmount');
        }

        setTotals({'all':allTotals, 'active':activeTotals});
    }

    const doApprove = (itm) => {
        if(itm === 1){
            props.makePayment(agent);
        }else if(itm === 2){
            props.sendForRejection(agent);
        }
    }

    return(
        <div className="modal fade" id="confirmWins" tabindex="-1" role="dialog" aria-hidden="true">  
            <div className="modal-dialog">  
                <div className="modal-content"> 
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                        <h5 className="modal-title">Wins Payment</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {agent == '0' ?
                            <div className="col-xs-12 text-center">
                                You are about to approve <b>{numformat.format(totals.active)}</b> for all agent wins pending approval
                            </div> :
                            <div className="col-xs-12 text-center">
                                You are about to approve <b>{numformat.format(totals.active)}</b> for <b>{agent} {totals.active === totals.all ? '' : 'retailers'}</b>
                            </div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button id="btnApprovecancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={() => doApprove(1)} data-dismiss="modal">Approve</button>
                        <button type="button" className="btn btn-warning" onClick={() => doApprove(2)} data-dismiss="modal">Reject</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WinPaymentConfirm;
