import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
import Auth from '../common/Auth';
import Config from '../Config';
import _ from 'lodash';
import Axios from 'axios';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { get } from 'core-js/fn/dict';

function CartTempList(){
    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const [formItems, setFormItems] = useState(
        { operator: '', username: '', retailer: '', startDate: moment().startOf('day'), endDate: moment(), curuser: auth.getUser(), token: auth.getToken() }
    );
    const [label, setLabel] = useState(moment().format('MMMM D, YYYY'));
    const [profiles, setProfiles] = useState([]);
    const [tempData, setTempData] = useState([]);

    const ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }
    const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
    const amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    const cartStatus = {1:'Pending',0:'Successfull',2:'Failed'}

    const getProfiles = () => {
        $http.get('admin/wapp/user')
        .then(res => {
            if (isNaN(res.data)) {
                let profiles = auth.decryptData(res.data)

                setProfiles(profiles);
            } else {
                auth.doAlert(parseInt(res.data))
            }
        });
    }

    const getTempdata = () => {
        let formData = auth.encryptData(JSON.stringify(formItems))
        Axios(config.PostOptions(formData, 'transaction/wapp/journal/cartemp'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = auth.decryptData(res.data)

                let tempData = _.orderBy(resdata, ['createdon'], ['desc'])
                
                setTempData(tempData);
            }else{
                auth.doAlert(parseInt(res.data))
            }
                 
        })
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setFormItems(prevState => ({
            ...prevState, [name]:value
        }));
    }

    const handleEvent = (event, picker) => {
        event.preventDefault();

        
        formItems['startDate'] = picker.startDate;
        formItems['endDate'] = picker.endDate;

        const start = formItems.startDate.format('MMMM D, YYYY');
        const end = formItems.endDate.format('MMMM D, YYYY');
        const label = (start === end) ? start : `${start} - ${end}`;
        
        setLabel(label);
        setFormItems(formItems);
    }

    const passTrans = (cart) => {
        cart['curuser'] = auth.getUser();
        cart['token'] = auth.getToken();

        let formData = auth.encryptData(JSON.stringify(cart));
        Axios(config.PostOptions(formData, 'game/wapp/stake_transid'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = auth.decryptText(res.data);

                this.getTempdata();
            }else{
                auth.doAlert(parseInt(res.data))
            }
                 
        });
    }

    const statusFormat = (cell, row) => cartStatus[cell];
    const amountFormat = (cell, row) => amountformat.format(cell);
    const dateFormat = (cell, row) => moment(cell).format("DD MMM, YYYY @ h:mm a").toLocaleString();
    const actionFormat = (cell, row) => /*(row.status == 1) ? <i className="fa fa-lg fa-download" style={{cursor:'pointer'}} onClick={()=>passTrans(row)}></i> :*/ '';

    return(
        <div className="row">
            <div className="col-xs-12 clearfix">
                <div className="btn-group btn-group-sm">
                    <button className="btn btn-default">DateRange: </button>
                    <DatetimeRangePicker
                        startDate={formItems.startDate}
                        endDate={formItems.endDate}
                        ranges={ranges}
                        onEvent={handleEvent}
                        className="selected-date-range-btn btn btn-default">
                        <div className="pull-left">
                            <i className="fa fa-calendar" /> &nbsp; &nbsp;
                                <span>{label}</span>
                        </div>
                        <div className="pull-right">
                            &nbsp; &nbsp;<i className="fa fa-angle-down" />
                        </div>
                    </DatetimeRangePicker>
                    <button onClick={getTempdata} className="btn btn-info">Display</button>
                </div>
            </div>
            <div className="col-xs-12"><hr className="hrsp" /></div>
            <div className="col-xs-12 col-sm-12 ">
                <BootstrapTable data={tempData} striped hover condensed pagination search>
                    <TableHeaderColumn dataField='createdon' width="180px" dataFormat={dateFormat} dataSort>Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='username' width="120px" isKey dataSort>Platform</TableHeaderColumn>
                    <TableHeaderColumn dataField='mobile' width="150px" dataSort >Mobile No.</TableHeaderColumn>
                    <TableHeaderColumn dataField='transid' width="150px" dataSort>Momo Trans ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='amount' dataAlign="right" dataFormat={amountFormat} width="90px" dataSort>Amount</TableHeaderColumn>
                    <TableHeaderColumn dataField='stakeNo' width="300px" tdStyle={{ whiteSpace: 'normal' }} dataSort>No. Staked</TableHeaderColumn>
                    <TableHeaderColumn dataField='betname' width="150px" dataSort>Bet</TableHeaderColumn>
                    <TableHeaderColumn dataField='game' width="200px" dataSort>Game</TableHeaderColumn>
                    <TableHeaderColumn dataField='status' width="100px" dataFormat={statusFormat} dataSort>Status</TableHeaderColumn>
                    <TableHeaderColumn width="80px" dataField='id' dataFormat={actionFormat} dataSort></TableHeaderColumn>
                </BootstrapTable>
            </div>
        </div>
    )
}

export default CartTempList;