import React, { Component } from "react";

export default class LiveWins extends Component {
  constructor() {
    super();
    this.state = {
      
    }
  }

  render() {
    return (
      <div className="tab-pane fade in padding-10 no-padding-bottom" id="s3">
      
      </div>
    );
  }
}
