export default function Menu(){
    let menu = [
        {code:'1', name:'Dashboard', icon:'dashboard'},
        {code:'2', name:'Agents/Retailers', icon:'users'},
        {code:'3', name:'Games', icon:'gamepad'},
        {code:'4', name:'Transactions', icon:'calculator', submenu:[
            /*{code:'41', name:'GL Trans'},*/
            {code:'42', name:'Operator Trans'},
            {code:'46', name:'Cashier Trans'},
            {code:'43', name:'Agent Trans'},
            {code:'44', name:'Retailer Trans'},
            {code:'48', name:'Retailer Staked Trans'},
            {code:'45', name:'Momo Trans'},
            {code:'47', name:'Pending Momo Trans'}
        ]},
        {code:'5', name:'Reports', icon:'file-pdf-o', submenu:[
            {code:'57', name:'General Report'},
            {code:'58', name:'Unit Request Report'},
            /*{code:'51', name:'Agent Details'},
            {code:'53', name:'Game Sales'},
            {code:'54', name:'Agent Payouts'},
            {code:'55', name:'Agent Summaries'},
            {code:'56', name:'Commission'}*/
        ]},
        {code:'6', name:'Administration', icon:'building', submenu:[
            {code:'65', name:'Draw Data'},
            {code:'62', name:'Draw Screening'},
            {code:'66', name:'Risk Managment'},
            {code:'61', name:'Draw Entries'},
            {code:'63', name:'Run Draw'},
            {code:'67', name:'Wins'},
            {code:'64', name:'Sales Commissions'}
        ]},
        {code:'7', name:'Tools', icon:'wrench', submenu:[
            {code:'71', name:'SMS'},
            {code:'72', name:'Email'},
            {code:'73', name:'File Manager'}
        ]},
        {code:'8', name:'Preference', icon:'gear', submenu:[
            {code:'81', name:'User Profiles'},
            {code:'87', name:'Cashiers'},
            {code:'82', name:'Terminals'},
            {code:'83', name:'Audit Trail'},
            {code:'84', name:'Approvers'},
            {code:'85', name:'Game Setup'},
            {code:'86', name:'Bet Setup'}
        ]},
        {code:'9', name:'Configuration', icon:'gears', submenu:[
            {code:'91', name:'General'}
        ]},
        {code:'A', name:'Web Portal', icon:'book', submenu:[
            {code:'A1', name:'Portal'}
        ]}
    ]

    return menu;
}