import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as jsPDF from 'jspdf'
import Auth from '../../common/Auth'
import Config from '../../Config'
import moment from 'moment'
import AgentPayouts from '../AgentPayouts';

const colstyle = {fontSize:'30px', display:'none' }

function showReport(img, row, data, comp){
    const pdf = new jsPDF("l", "mm", "a4")

    pdf.setProperties({
        title: 'Agent Summary Report ',
        subject:' Agent Summary Report ',
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })

    pdf.autoTable({
        html: '#tbCompany', 
        theme: 'plain',
        styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{cellWidth:15}, 
            1: {halign: 'center'}, 
            2: {halign:'right', valign:'bottom', cellWidth:30, fontSize:8, fontStyle:'normal'}
        }
    })

    if(img !='' && img !=undefined){
        let tp = 'PNG'
        if(img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1){
            tp = 'JPEG'
        }
        pdf.addImage(img,tp,15,10,0,23)
    }
    pdf.line(14, 40, 282, 40)
    pdf.autoTable({
        html: '#tbTitles', 
        theme: 'striped',
        styles: {fontSize: 13, overflow: 'linebreak'},
        columnStyles: {
            0 :{halign:'right', cellWidth:40}, 1: {fontStyle: 'bold', cellWidth:100},
            2 :{halign:'right', cellWidth:40}, 3: {fontStyle: 'bold', cellWidth:50}
        }
    })

    pdf.autoTable({
        html: '#tbContent', 
        theme: 'striped',
        styles: {fontSize: 13},
        columnStyles: {
            0 :{cellWidth:60}, 1: {fontStyle: 'bold', fontSize: 18, halign:'center'},
            2: {halign:'right', cellWidth:30},
            3 :{halign:'right', cellWidth:30}, 4: {halign:'right', cellWidth:30},
            5: {halign:'right', cellWidth:30}
        }/*,
        didParseCell(data) {
            var rows = data.table.body
            alert(data.row.cell)
        }*/
    })

    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}
function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename?filename+'.xls':'excel_data.xls';
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}
function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
function exportTableToCsv(tableID, filename = ''){
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            
            for (var j = 0; j < cols.length; j++) 
                row.push(cols[j].innerText);
            
            csv.push(row.join(","));		
        }
        download_csv(csv.join("\n"), filename);
}
export default class AgentPayoutPdf extends Component {
    constructor(props) {
        super(props)

        this.config = new Config() 
        this.auth = new Auth()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            data:this.props.data,
            companySetup: ''
        }
    }

    componentDidMount() {
        this.getCompanySetup()
    }

    componentDidUpdate(nextProps){
        if(nextProps.reportData !== this.props.reportData){
            let reportData = nextProps.reportData
            let rowData = nextProps.rowData

            this.setState({ reportData, rowData })

            this.getCompanySetup()
        }
    }
    
    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            let rowData=this.state.rowData
            let data=this.state.data
            let url = 'admin/wapp/image/custfiles/' + companySetup.logo
            this.$http.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                let img = new Buffer(res.data, 'binary').toString('base64')
                showReport(img, rowData, data, companySetup)
            }).catch((error)=>{showReport('', rowData, data, companySetup)})

            this.setState({ companySetup })
        }).catch((error)=>{alert(error)})
    }
    
    goBack = () => {
        ReactDOM.render(
            <AgentPayouts />,
            document.getElementById('generalReport_inners')
         )
    }
    exportTableToExcels=()=>{
        
        exportTableToExcel('datas', 'Agent-Payout ')
    }
    exportTableToCsv=()=>{
        exportTableToCsv('datas', 'Agent-Payout ')
    }

    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Agent Payout Report</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10">
                                        <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                    </div>
                                    <div className="col-xs-12 col-sm-2">
                                        <div className="btn-group btn-group-vertical btn-group-sm">
                                            <button className="btn btn-warning" onClick={this.goBack}> 
                                                <i className="fa fa-backward"></i> Back
                                            </button>
                                            <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                            <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                                       </div>
                                    </div>
                                    <div className="col-xs-12" id="datas">
                                        <table width="100%" id="tbCompany" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="3">&nbsp;</td>
                                                    <td>{this.state.companySetup.companyName}</td>
                                                    <td rowSpan="3">
                                                        P.O.Box: 16244 KIA <br />TEL:0302 2232 143 <br />ACCRA-GHANA
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.data.agent}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tbTitles" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                
                                                <tr>
                                                    <td>Operator: </td><td><b>{this.state.data.operator}</b></td>
                                                    <td>Total Gross: </td><td><b>{this.state.data.gross}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Start Date: </td><td><b>{moment(this.state.data.startDate).format('DD MMM, YYYY')}</b></td>
                                                    <td>Total Wins: </td><td><b>{this.state.data.wins}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>End Date: </td><td><b>{moment(this.state.data.endDate).format('DD MMM, YYYY')}</b></td>
                                                    <td>Total Pay-In/Out: </td><td><b>
                                                    {this.state.data.total < 0 ? <b className="text-danger">{this.state.data.total}</b> 
                                                    : <b className="text-success">{this.state.data.total}</b>}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tbContent" className="table table-condensed" style={colstyle}>
                                            <tbody>{this.state.rowData}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}
