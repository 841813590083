import React, {Component} from 'react';
import axios from 'axios';
import Auth from '../common/Auth'
import Config from '../Config'
import { Link, BrowserRouter } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PageForm from './PageForm';

const pages = [
    {'id':1,'name':'Home'}, {'id':2,'name':'Result'}, {'id':3,'name':'Game'},
    {'id':4,'name':'About'}, {'id':5,'name':'Contact'}, {'id':6,'name':'Other Pages'}
]

export default class Pages extends Component{
    constructor(props){
        super(props);

        this.auth = new Auth();
        this.config = new Config();
        this.$http = this.config.DConnect();

        this.state = {
            reportData: [],
            formItems: {page:0},
            loader: false,
            rowData: {
                "id":0, "page":0, "category":0, "categorySub":0, "createdon":new Date(),
                "createdby":this.auth.getUser(), "title":"", "content":"", "rate":0,"location":"","hits":0,"status":true
            }
        }
    }

    componentDidMount = () => {

    }

    handleChange = (e) => {
        let formItems = this.state.formItems;
        formItems[e.target.name] = e.target.value;

        if(e.target.name == 'page'){
            this.getReportData(e.target.value);
        }

        this.setState({ formItems });
    }

    getReportData = (page) => {
        this.setState({ loader: true });

        this.$http.get(`web/wapp/portal/${page}`)
        .then(res => {
            if (isNaN(res.data)) {
                let reportData = this.auth.decryptData(res.data)
                
                this.setState({ reportData, loader: false });
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch(error => { alert(error.message); this.setState({ loader: false }); });
        /*let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'web/web/portal/retailer'))
        .then((res) => {
            if(isNaN(res.data)){
                let reportData = this.auth.decryptData(res.data)

                this.setState({ reportData, loader: false });
            }else{
                this.auth.doAlert(parseInt(res.data));
            }   
        }).catch(error => {alert(error.message); this.setState({ loader: false });});*/
    }

    actionFormat = (cell, row) => {
        return(<BrowserRouter>
            <a href='#' title='Edit' data-toggle="modal" data-target="#pageForm" onClick={() => this.setState({ rowData:row })}><i className="fa fa-edit fa-lg"></i></a>  
            {row.status == 1 ? 
            <Link to='#' title='Active' onClick=''> &nbsp;&nbsp; | &nbsp;&nbsp; <i className="fa fa-check-square text-success fa-lg"></i></Link>  
            : <Link to='#' title='Inactive' onClick=''> &nbsp;&nbsp; |  &nbsp;&nbsp; <i className="fa fa-square fa-lg text-danger"></i></Link>}
        </BrowserRouter>);
    }

    newPage = () => {
        let rowData = {
            "id":0, "page":0, "category":0, "categorySub":0, "createdon":new Date(),
            "createdby":this.auth.getUser(), "title":"", "content":"", "rate":0,"location":"","hits":0,"status":true
        };
        this.setState({ rowData });
    }

    loading = () => <div id="loader"></div>

    render(){
        let { formItems, rowData, reportData } = this.state;

        return(
            <div className="row">
                <div className="col-xs-12 clearfix">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">Page: </button>
                        <select id="page" name="page" onChange={this.handleChange} value={formItems.page} className="btn btn-default" style={{ width: '200px', height:'30px' }}>
                            <option value="0"> -- Select Page -- </option>
                            {pages.map(pg => (
                                <option value={pg.id}>{pg.name}</option>
                            ))}
                        </select>
                        <button onClick={() => this.getReportData(formItems.page)} className="btn btn-info">Display</button>
                    </div>
                    <div className="btn btn-sm pull-right" >
                        <button className="btn btn-success" data-toggle="modal" data-target="#pageForm" disabled={formItems.page==0}
                        onClick={this.newPage}>New Page Item</button>
                    </div>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-12 ">
                    <BootstrapTable data={reportData} striped hover condensed pagination search>
                        <TableHeaderColumn dataField='id' width="100px" isKey dataSort>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='title' dataSort>Title</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="150px" dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <PageForm rowData={ rowData } getReportData = {this.getReportData} />
            </div>
        )
    }
}