import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import DropdownForm from './DropdownForm';

export default class Dropdown extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            fieldnames:[],
            fieldname:'',
            dropdowns:[],
            rowData: {}
        }
    }

    componentDidMount(){
        this.getFieldnames()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            if(nextProps.fieldname !== undefined){
                this.setState({ fieldname: nextProps.fieldname})
            }
            this.getFieldnames()
        }
    }

    getFieldnames = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/fieldname')
        .then(res => {
            let fieldnames = this.auth.decryptData(res.data)
            let fieldname = this.state.fieldname
            let rowData = {}

            if(fieldnames[0] !== undefined && fieldname == ''){
                    fieldname = fieldnames[0]
            }

            this.getDropdowns(fieldname)

            this.setState({ fieldnames, fieldname, rowData })
        })
    }

    getDropdowns = (fieldname) =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/' + fieldname)
        .then(res => {
            let dropdowns = this.auth.decryptData(res.data)

            this.setState({ dropdowns })
        })
    }

    actionFormat = (cell, row) => {
        return(
            <div className="btn-group btn-group-xs">
                <button className="btn btn-default" data-toggle="modal" data-target="#dropdownform" 
                onClick={() => this.addDropdown(row)}>
                    <i className="fa fa-edit"></i> Edit
                </button>
                <button className="btn btn-danger" onClick={() => this.deleteDropdown(row)}>
                    <i className="fa fa-trash-o"></i> Delete
                </button>
            </div>
        )
    }

    addDropdown = (rowData) => {
        if(rowData.id == undefined){
            rowData['fieldname'] = this.state.fieldname
        }else{
            rowData['username'] = this.auth.getUser()
            rowData['token'] = this.auth.getToken()
        }

        this.setState({ rowData })
    }

    deleteDropdown = (rowData) => {
        rowData['username'] = this.auth.getUser()
        rowData['token'] = this.auth.getToken()

        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'admin/wapp/delete_dropdown'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.refreshPage(rowData.fieldname)
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    handleChange = (e) => {
        let fieldname = e.target.value

        this.getDropdowns(fieldname)
        this.setState({ fieldname })
    }

    refreshPage = (fieldname) => {
        ReactDOM.render(<Dropdown fieldname={fieldname} />, 
            document.getElementById('generalConfigCon'))
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12" style={{padding:'10px 20px 0 20px'}}>
                    <h3 className="pull-left">Dropdowns Setup</h3>
                    <div className="btn-group btn-group-sm pull-right" >
                        <button className="btn btn-primary" data-toggle="modal" data-target="#dropdownform" 
                        onClick={() => this.setState({ rowData:{}})}>
                            <i className="fa fa-pluss"></i> Add Fieldname
                        </button>
                    </div>
                    <div className="btn-group btn-group-sm pull-right">
                        <button className="btn btn-default">Fieldname: </button>
                        <select name="fieldname" id="fieldname" className="btn btn-default" 
                        value={this.state.fieldname} onChange={this.handleChange}>
                            {this.state.fieldnames.map(fd => (
                                <option value={fd}>{fd}</option>
                            ))}
                        </select>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-3 pull-right">
                    <div className="btn-group btn-group-vertical btn-group-sm">
                        <button className="btn btn-sm btn-primary" data-toggle="modal" data-target="#dropdownform" 
                        onClick={() => this.addDropdown({})}>
                            <i className="fa fa-plus"></i> Add Dropdown
                        </button>
                    </div>
                </article>
                <article className="col-xs-9">
                    <BootstrapTable data={this.state.dropdowns} striped hover condensed search pagination>
                        <TableHeaderColumn dataField='iname' isKey dataSort >Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='ivalue' dataSort>Value</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataSort dataFormat={this.actionFormat}></TableHeaderColumn>
                    </BootstrapTable>
                </article>
                <DropdownForm rowData={this.state.rowData} />
            </div>
        )
    }
}