import React,{ Component } from "react"
import ReactDOM from 'react-dom'
import $ from 'jquery'
import { Link } from "react-router-dom"
import MyProfile from "../administration/MyProfile"


export default class Shortcut extends Component{

    constructor(){
        super()
    }

    showShortcut = () => {
        $('#shortcut').addClass('hide')
    }

    showMail = () => {
        alert('Mail')
    }

    showProfile = () => {
        $('.myprofile').click()
    }

    render(){
        return(
            <div id="shortcut" className="hide" onBlur={this.showShortcut} onClick={this.showShortcut}>
			<ul>
				<li>
					<Link to="#" className="jarvismetro-tile big-cubes bg-color-blue" onClick={this.showMail}> 
                        <span class="iconbox"> <i className="fa fa-envelope fa-4x"></i> <span>Mail <span className="label pull-right bg-color-darken">14</span></span> </span> 
                    </Link>
				</li>
				<li>
					<a href="#" class="jarvismetro-tile big-cubes selected bg-color-pinkDark" onClick={this.showProfile}> <span class="iconbox"> <i class="fa fa-user fa-4x"></i> <span>My Profile </span> </span> </a>
				</li>
			</ul>
		</div>
        )
    }
}