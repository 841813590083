import React, { Component } from 'react';
import TransactionForm from './TransactionForm'
import BatchForm from './BatchForm';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import AgentTransactions from './AgentsTransactions'
import { O_SYMLINK } from 'constants';

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
export default class GlTransList extends Component {
    constructor() {
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        this.state = {
            formItems: { operator: '', agent: '', retailer: '' ,startDate: moment().subtract(29, 'days'),endDate: moment()},
            batchData: [], operators: [], agent: [], goperator: '', retailer: [],batch: 1,

            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        let systemDefine = (this.auth.getUser() == "admin") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1

        this.getBatch();
        this.getUserType(systemDefine)
    }
    getBatch = () => {
        this.$http.get('transaction/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/batch/'+this.auth.getUser())
            .then(res => {
                if(isNaN(res.data)){
                let batchData = this.auth.decryptData(res.data)
                this.state.batch= (batchData[0] !== undefined) ? batchData[0].batchcode : ''
                this.setState({ batchData })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            })
    }
    getUserType = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
                .then(res => {
                    if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let formItems = this.state.formItems
                    formItems['operator'] = (operators[0] != undefined) ? operators[0].username : operators.username
                
                    window.operator=formItems.operator
                    this.setState({ formItems, operators })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }

                })
        }
    }
    getJournaldata = () => {
        this.$http.get('transaction/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/journals/'+this.state.batch +'/'+this.state.formItems.operator )
        .then(res => {
            if(isNaN(res.data)){
            let journaldata = this.auth.decryptData(res.data)
            this.setState({ journaldata })
        }else{
            this.auth.doAlert(parseInt(res.data))
        }
        })
    
}
    batchChange = (e) => {
        let batch = e.target.value
        this.setState({ batch })
    }
    handleNewBatch = () => {
        ReactDOM.render(
            <TransactionForm batch={this.state.batch} />,
            document.getElementById('agent_inner')
        )
    }
    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        window.operator=ptype
        this.setState({ formItems: fitm })
        this.getAgent(ptype)
    }
    handleEvent = (event, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
    }


    render() {
      let bb= <AgentTransactions agent={this.state.formItems.agent} />
        //let buttonStyle = { width: '100%' };
        let start = this.state.startDate.format('MMMM D, YYYY');
        let end = this.state.endDate.format('MMMM D, YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        return (
            
            <div className="row">
            
                <div className="col-xs-12 clearfix">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">Batch: </button>
                        <select id="" name="batch" onChange={this.batchChange} value={this.state.batch} className="btn btn-default" style={{ width: '200px' }}>
                            {this.state.batchData.map(btch =>
                                <option value={btch.batchcode}>{btch.batchname}</option>
                            )}
                        </select>
                        <button className="btn btn-default">Operators: </button>
                        <select id="" name="operator" onChange={this.handleChange} value={this.state.formItems.operator} className="btn btn-default" style={{ width: '200px' }}>
                            {this.state.operators.map(opt =>
                                <option value={opt.username}>{opt.fullname}</option>
                            )}
                        </select>
                        <button onClick={this.getJournaldata} className="btn btn-info">Display</button>
                    </div>
                    <div className="btn-group btn-group-sm pull-right" >
                        <button className="btn btn-success" onClick={this.handleNewBatch}>New Transaction</button>

                        <button className="btn btn-success" data-toggle="modal" data-target="#basicExampleModal">New Batch</button>
                    </div>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                    <BootstrapTable data={this.state.journaldata} striped hover condensed pagination search>
                        <TableHeaderColumn dataField='refno' isKey dataSort>Reference No.</TableHeaderColumn>
                        <TableHeaderColumn dataField='accountid' dataSort>Username</TableHeaderColumn>
                        <TableHeaderColumn dataField='accountid' dataFormat={this.usertypeFomat} dataSort>Account Type</TableHeaderColumn>
                        <TableHeaderColumn dataField='notes' width="300px" dataSort>Description</TableHeaderColumn>
                        <TableHeaderColumn dataField='voucher' dataSort>Voucher</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount' dataAlign="right" dataFormat={this.amountFormat} dataSort>Amount</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdon' width="200px" dataFormat={this.dataFormat} dataSort>Created On</TableHeaderColumn>
                    </BootstrapTable>
                <BatchForm />
            </div>

        )
    }
}
