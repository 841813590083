import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'

const dentry = {width:'60px', height:'60px', lineHeight: '60px', fontSize: '24px', marginRight:'10px', textAlign:'center'}

export default class DrawDataForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: this.props.rowData,
            games: this.props.games,
            gamename:'',
            dnum:['', '', '', '', ''],
            mnum:['', '', '', '', '']
        }
    }

    componentDidMount(){
        let rowData = this.props.rowData
        this.getGameName(rowData)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps!==this.props){
            let formItems = nextProps.rowData
            let games = nextProps.games
            let dnum = this.state.dnum
            let mnum = this.state.mnum
            
            if($.trim(formItems.drawNumbers) != ''){
                dnum = formItems.drawNumbers.split('-').map(dn => dn.trim())
            }

            if($.trim(formItems.machineNumbers) != ''){
                mnum = formItems.machineNumbers.split('-').map(mn => mn.trim())
            }

            this.setState({ formItems, games, dnum, mnum })

            this.getGameName(formItems)
        }
    }

    getGameName = (formItems) =>{
        let game = this.state.games.filter(gm => gm.id == formItems.gamecode)
        let gamename = (game[0] !== undefined) ? game[0].gamename : ''

        this.setState({ gamename })
    }

    handleChange = (e, index) => {
        let dnum = this.state.dnum
        let mnum = this.state.mnum
        if(e.target.name == 'dnum'){
            dnum[index] = e.target.value
            if(dnum[index].length == 2){$('#dnum' + (index+1)).focus()}
            //if(dnum[index].length == 0){$('#dnum' + (index-1)).focus()}
        }else{
            mnum[index] = e.target.value
            if(mnum[index].length == 2){$('#mnum' + (index+1)).focus()}
            //if(mnum[index].length == 0){$('#mnum' + (index-1)).focus()}
        }

        this.setState({ dnum, mnum })
    }

    handleBlur = (e, index) => {
        let dnum = this.state.dnum
        let mnum = this.state.mnum

        let val = e.target.value
        let cnt = 0

        //if(e.target.name == 'dnum'){
            Object.values(dnum).map(dn =>{if(dn === val){cnt++}})
        //}else{
            Object.values(mnum).map(dn =>{if(dn === val){cnt++}})
        //}

        //alert(cnt)
        if(val !== '' && cnt > 1){
            if(e.target.name == 'dnum'){
                dnum[index] = ''
                $('#dnum' + index).focus()
            } else {
                mnum[index] = ''
                $('#mnum' + index).focus()
            }

            this.setState({ dnum, mnum })
            alert('You cannot repeat the same number, check and try again...')
        }
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        formItems['drawNumbers'] = this.state.dnum.join('-')
        formItems['machineNumbers'] = this.state.mnum.join('-')
        let formData = this.auth.encryptData(JSON.stringify(formItems))

        axios(this.config.PostOptions(formData, 'game/wapp/gamedetail'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
            }else{
                this.auth.doAlert(parseInt(res.data))
            }

            $('#btnDrawDatacancel').click()
            $('#btnDrawDataDisplay').click()
        }).catch((error)=>{alert(error);$('#btnDrawDatacancel').click()})
    }

    render(){
        return(
            <div className="modal fade" id="drawdataform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Draw Data</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-responsive table-condensed table-striped">
                                        <tbody>
                                            <tr>
                                                <td>Game: <b>{this.state.gamename}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Draw #: <b>{this.state.formItems.drawno}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <form id="frmDrawData" className="smart-form" onSubmit={this.formSubmit}>
                                    <div className="col-xs-12">
                                        <label className="label col col-3 text-right" style={{lineHeight:'60px'}}>Draw Nos.: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" id="dnum0" name="dnum" className="pull-left" maxLength='2' required style={dentry}
                                                value={this.state.dnum[0]} onChange={(e) => this.handleChange(e, 0)} onBlur={(e) => this.handleBlur(e, 0)} />
                                                <input type="text" id="dnum1" name="dnum" className="pull-left" maxLength='2' required style={dentry}
                                                value={this.state.dnum[1]} onChange={(e) => this.handleChange(e, 1)} onBlur={(e) => this.handleBlur(e, 1)} />
                                                <input type="text" id="dnum2" name="dnum" className="pull-left" maxLength='2' required style={dentry}
                                                value={this.state.dnum[2]} onChange={(e) => this.handleChange(e, 2)} onBlur={(e) => this.handleBlur(e, 2)} />
                                                <input type="text" id="dnum3" name="dnum" className="pull-left" maxLength='2' required style={dentry}
                                                value={this.state.dnum[3]} onChange={(e) => this.handleChange(e, 3)} onBlur={(e) => this.handleBlur(e, 3)} />
                                                <input type="text" id="dnum4" name="dnum" className="pull-left" maxLength='2' required style={dentry}
                                                value={this.state.dnum[4]} onChange={(e) => this.handleChange(e, 4)} onBlur={(e) => this.handleBlur(e, 4)} />
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12">
                                        <label className="label col col-3 text-right" style={{lineHeight:'60px'}}>Machine Nos.: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" id="mnum0" name="mnum" className="pull-left" maxLength='2' style={dentry}
                                                value={this.state.mnum[0]} onChange={(e) => this.handleChange(e, 0)} onBlur={(e) => this.handleBlur(e, 0)} />
                                                <input type="text" id="mnum1" name="mnum" className="pull-left" maxLength='2' style={dentry}
                                                value={this.state.mnum[1]} onChange={(e) => this.handleChange(e, 1)} onBlur={(e) => this.handleBlur(e, 1)} />
                                                <input type="text" id="mnum2" name="mnum" className="pull-left" maxLength='2' style={dentry}
                                                value={this.state.mnum[2]} onChange={(e) => this.handleChange(e, 2)} onBlur={(e) => this.handleBlur(e, 2)}/>
                                                <input type="text" id="mnum3" name="mnum" className="pull-left" maxLength='2' style={dentry}
                                                value={this.state.mnum[3]} onChange={(e) => this.handleChange(e, 3)} onBlur={(e) => this.handleBlur(e, 3)} />
                                                <input type="text" id="mnum4" name="mnum" className="pull-left" maxLength='2' style={dentry}
                                                value={this.state.mnum[4]} onChange={(e) => this.handleChange(e, 4)} onBlur={(e) => this.handleBlur(e, 4)} />
                                            </label>
                                        </section>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="btnDrawDatacancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmDrawData" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}