import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import moment from 'moment'
import DrawScreenPrint from './DrawScreenPrint';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class WinReport extends Component{
    constructor(props){
        super(props)

        this.state = {
            games: this.props.games,
            events: this.props.events,
            bets: this.props.bets,
            formItems: this.props.formItems,
            screenTotals: this.props.rowData,
            betList: [],
            screenNum: this.props.formItems.screenNum.split('-')
        }
    }

    componentDidUpdate(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let screenTotals = nextProps.rowData
            let formItems = nextProps.formItems
            let events = nextProps.events
            let games = nextProps.games
            let bets = nextProps.bets
            let screenNum = nextProps.formItems.screenNum.split('-')
            let betList = screenTotals.bmodel

            this.setState({ screenTotals, formItems, events, games, bets, screenNum, betList })
        }
    }

    formatGame = (cell) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0]!==undefined) ? game[0].gamename : cell

        return gamename
    }

    formatBet = (cell) => {
        let bet = this.state.bets.filter(bt => bt.id == cell)
        let betname = (bet[0]!==undefined) ? bet[0].betname : cell

        return betname
    }

    dateFormat = (cell) => {
        let event = this.state.events.filter(ev => ev.drawno == cell)
        let drawdate = (event[0]!==undefined) ? moment(event[0].drawDate).format('DD MMM, YYYY @ hh:mma') : moment()

        return drawdate
    }

    doPreview = () => {
        let fitm = this.state.formItems
        let st = this.state.screenTotals

        let win = (st.totalStakes>0)? ((parseFloat(st.totalWins)/parseFloat(st.totalStakes)) * 100).toFixed(2):0.00
        let gross = (parseFloat(st.totalStakes) - parseFloat(st.totalWins)).toFixed(2)
        let vat = (gross > 0) ? (0.175 * parseFloat(gross)).toFixed(2) : 0.00
        let net = (parseFloat(gross) - parseFloat(vat)).toFixed(2)
        
        let betList = st.bmodel.map(bl => {
            let bet = this.state.bets.filter(bt => bt.id == bl.betcode)
            let betname = (bet[0]!==undefined) ? bet[0].betname : bl.betcode
            bl['betname'] = betname
            bl['betAmount'] = numformat.format(bl.betAmount)
            bl['winAmount'] = numformat.format(bl.winAmount)

            return bl
        })
        fitm['gamecode'] = this.formatGame(fitm.gamecode)
        fitm['drawdate'] = this.dateFormat(fitm.drawno)
        st['totalStakes'] = numformat.format(st.totalStakes)
        st['totalWins'] = numformat.format(st.totalWins)
        gross = numformat.format(gross)
        vat = numformat.format(vat)
        net = numformat.format(net)

        let sn = this.state.screenNum
        let screenNum = {snum0:sn[0], snum1:sn[1], snum2:sn[2], snum3:sn[3], snum4:sn[4]}

        let data = {
            screenNum:screenNum, totals:st, win:win, 
            gross:gross, vat:vat, net:net, fitm:fitm, title:'Draw Report'
        }
        ReactDOM.render(
            <DrawScreenPrint rowData={betList} data={data} />,
            document.getElementById('wins_inner')
        )
    }

    amtFormat = (cell) => numformat.format(cell)

    render(){
        let fitm = this.state.formItems
        let st = this.state.screenTotals
        let betList = st.bmodel
        let win = (st.totalStakes>0)? ((parseFloat(st.totalWins)/parseFloat(st.totalStakes)) * 100).toFixed(2):0.00
        let gross = (parseFloat(st.totalStakes) - parseFloat(st.totalWins)).toFixed(2)
        let vat = (gross > 0) ? (0.175 * parseFloat(gross)).toFixed(2) : 0.00
        let net = (parseFloat(gross) - parseFloat(vat)).toFixed(2)

        return(
            <div className="row">
                <div className="col-xs-12">
                    <div className="row">
                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                            <div id="numCon0" className="snumCon bg-color-blueLight txt-color-white">
                            {this.state.screenNum[0]}</div>
                        </div>
                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                            <div id="numCon1" className="snumCon bg-color-blueLight txt-color-white">
                            {this.state.screenNum[1]}</div>
                        </div>
                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                            <div id="numCon2" className="snumCon bg-color-blueLight txt-color-white">
                            {this.state.screenNum[2]}</div>
                        </div>
                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                            <div id="numCon3" className="snumCon bg-color-blueLight txt-color-white">
                            {this.state.screenNum[3]}</div>
                        </div>
                        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-1">
                            <div id="numCon4" className="snumCon bg-color-blueLight txt-color-white">
                            {this.state.screenNum[4]}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-2 pull-right">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-primary" onClick={this.doPreview}>Preview</button>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-10 col-lg-8">
                    <table className="table table-responsive table-condensed table-stripped">
                        <tbody>
                            <tr>
                                <td className="text-right">Game Name: </td><td><b>{this.formatGame(fitm.gamecode)}</b></td>
                                <td className="text-right">Total Tickets: </td><td><b>{st.totalTickets}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Event #: </td><td><b>{fitm.drawno}</b></td>
                                <td className="text-right">Total Amount: </td><td><b>{this.amtFormat(st.totalStakes)}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Draw Date: </td><td><b>{this.dateFormat(fitm.drawno)}</b></td>
                                <td className="text-right">Winning Tickets: </td><td><b>{st.winTickets}</b></td>
                            </tr>
                            <tr>
                                <td className="text-right">Wins %: </td><td><b>{win} %</b></td>
                                <td className="text-right">Winning Amount: </td><td><b>{this.amtFormat(st.totalWins)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className='hrsp' />
                    
                    <table className="table table-responsive table-condensed table-stripped">
                        <thead>
                            <tr>
                                <th>Bet Name</th><th>No. of Bets</th>
                                <th className="text-right">Bet Amount</th><th className="text-right">Win Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {betList.map(bet => (
                                <tr>
                                    <td>{this.formatBet(bet.betcode)}</td>
                                    <td>{bet.noOfBets}</td>
                                    <td className="text-right">{this.amtFormat(bet.betAmount)}</td>
                                    <td className="text-right">{this.amtFormat(bet.winAmount)}</td>
                                </tr>)
                            )}
                            <tr><td colSpan="4"></td></tr>
                            <tr>
                                <td colSpan="3" className="text-right">GROSS: </td>
                                <td className="text-right"><b>{gross}</b></td>
                            </tr>
                            <tr>
                            <td colSpan="3" className="text-right">VAT/NHIL (17.5%): </td>
                            <td className="text-right"><b>{vat}</b></td>
                            </tr>
                            <tr>
                            <td colSpan="3" className="text-right">NET: </td>
                            <td className="text-right"><b>{net}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        )
    }
}