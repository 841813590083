import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Winnings from './Winnings';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class WinList extends Component{
    constructor(){
        super()

        
    }

    componentDidMount(){
    }

    render(){

        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>Winnings</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body" id="winListCon"><Winnings /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}