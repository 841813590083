import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import axios from 'axios'
import moment from 'moment'
import SMSLists from './SMSLists'

export default class SendNewSMS extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                phone:'', message:'', createdby:this.auth.getUser(), createdon: new Date(),
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            charsLeft: 500, maxChars: 500
        }

    }

    componentDidMount(){
        
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            
        }
    }

    handleChange=(e)=>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        this.setState({ formItems }) 

        if (e.target.name == 'message') {
            const charCount = e.target.value.length
            const maxChar = this.state.maxChars
            const charLength = maxChar - charCount
            this.setState({ charsLeft: charLength })
        }
    }

    goBack = () => {
        ReactDOM.render(<SMSLists />, 
            document.getElementById('smsListCon'))
    }

    submit=(e)=>{
        e.preventDefault()

        let formItems = this.state.formItems
        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/sendsms'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.goBack()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }
    
    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-default" onClick={this.submit} form="frmNewMessage">
                            <i className="glyphicon glyphicon-upload"></i> Send Message
                        </button>
                        <button className="btn btn-danger" onClick={this.goBack}>
                            <i className="glyphicon glyphicon-backward"></i> Cancel
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12">
                    <form id="frmNewMessage" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12">
                                <label className="label col col-4 text-right">Mobile No.: </label>
                                <section className="col col-8">
                                    <label class="input">
                                        <input type="text" name="phone" placeholder="Phone" value={this.state.formItems.phone} onChange={this.handleChange} aria-required="true" />
                                        <small>Multiple mobile numbers must be comma separated.</small>
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12">
                                <label className="label col col-4 text-right">SMS Message: </label>
                                <section className="col col-8">
                                    <label class="textarea textarea-resizable">
                                        <textarea rows="5" name="message" class="custom-scroll" value={this.state.formItems.message} onChange={this.handleChange} maxLength={this.state.maxChars}></textarea>
                                    </label>
                                    <label class="label"> <small>{this.state.charsLeft} characters more!</small></label>
                                </section>
                            </div>
                        </div>
                    </form>
                </article>
            </div>
        )
    }
}