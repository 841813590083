import React, { Component } from "react";
import Auth from '../common/Auth'
import Config from '../Config'
import moment from "moment"

export default class LiveGames extends Component {
  constructor(props) {
    super(props)

    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
        gametype: this.props.gametype,
        livegames:[],
        games: []
    }
  }

  componentDidMount(){
    this.getGames()
  }

  componentWillReceiveProps(nextProps){
      if(this.props.gametype !== nextProps.gametype){
        this.getGames()
      }
  }

  getLiveGames = (games) => {
    this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamedetails/1')
    .then(res => {
      if(isNaN(res.data)){
        let resdata = this.auth.decryptData(res.data)
        
        let livegames = resdata.filter(rdata => games.includes(rdata.gamecode))
        //alert(JSON.stringify(livegames))
        this.setState({ livegames })

      }else{
        this.auth.doAlert(parseInt(res.data))
      }
    })
  }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
          if(isNaN(res.data)){
            let resdata = this.auth.decryptData(res.data)

            let games = resdata.filter(rdata => rdata.gametype == this.state.gametype)
            let gamecode = games.map(gm => gm.id)
            this.setState({ games })

            this.getLiveGames(gamecode)
          }else{
            this.auth.doAlert(parseInt(res.data))
          }
        })
    }

    gameFormat = (cell) => {
        let games = this.state.games
        let game = games.filter(gm => gm.id == cell)
        let gamename = (game[0] !== undefined) ? game[0].gamename : cell

        return gamename
    }

    extFormat = (cell) => {
      let games = this.state.games
      let game = games.filter(gm => gm.id == cell)
      let ext = (game[0] !== undefined) ? game[0].filex : cell

      return ext
  }

  render(){
      return(
        <div className="widget-body no-padding smart-form">
          <ul id="sortable1" className="todo">
              {this.state.livegames.map(lgm => (
                  <li key={lgm.gamecode}>
                      <span className="handle text-center" style={{width:'80px'}}>
                        <img src={this.config.ImagePath('gamefiles') + '/' + this.gameFormat(lgm.gamecode).replace(/ /g,'_') + '.' + this.extFormat(lgm.gamecode) } style={{width:'60px'}} alt="Img" />
                      </span>
                      <p style={{marginLeft:'105px'}}>
                        <strong>Draw #{lgm.drawno}</strong> - {this.gameFormat(lgm.gamecode)}
                        <span className="date"><b>Draw Date: </b>{moment(lgm.drawDate).format('DD MMM, YYYY')}</span>
                      </p>
                  </li>
              ))}
          </ul>

        </div>
      )
  }
}
