import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import RiskList from './RiskList';

export default class RiskManagement extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>Risk Management</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="risk_inner" className="widget-body"><RiskList /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}