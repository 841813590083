import React, { Component } from 'react';
import TransactionForm from './TransactionForm'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import QueryJson from '../common/QueryJson'
import AgentForm from './AgentForm'

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
const desc = {
    '1':'Purchase of credit from Alpha',
    '-1':'Reversal of credit by Alpha',
    '2':'Reversal of credit',
    '-2':'Transfer of credit or Bonus'
}
const momo = {
    '1':'Loading of wallet by Momo',
    '2':'Using of wallet to purchase credit'
}

export default class AgentTransList extends Component {
    constructor() {
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        this.state = {
            formItems: { operator: '', agent: '', username: '' ,startDate: moment().subtract(29, 'days'),endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken()},
            batchData: [], operators: [], agent: [], goperator: '', retailer: [], batch: 1,

            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        let data=(window.operator==undefined)?this.auth.getUser():window.operator
        this.getAgent(data)
        this.getUserType()
    }

    getJournaldata = () => {
        let formItems = this.state.formItems

        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'transaction/wapp/journal/retailer'))
        .then((res) => {
            if(isNaN(res.data)){
                let journaldata = this.auth.decryptData(res.data)

                //alert(JSON.stringify(journaldata))
                this.setState({ journaldata })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
                 
        })
    }

    getUserType = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/2')
            .then(res => {
                if (isNaN(res.data)) {
                    let userType = this.auth.decryptData(res.data)

                    this.setState({ userType })
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
        this.$http.get('transaction/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertill')
            .then(res => {
                if (isNaN(res.data)) {
                    let usertill = this.auth.decryptData(res.data)
                    this.setState({ usertill })
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/' + this.state.agent + '/3')
            .then(res => {
                if (isNaN(res.data)) {
                    let retailer = this.auth.decryptData(res.data)
                    this.setState({ retailer })
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
    }

    getAgent = (operator) => {
        if (operator != '') {
            if(isNaN(operator)){operator = 'admin'}
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails_rep/' + operator)
                .then(res => {
                    if(isNaN(res.data)){
                    let agent = this.auth.decryptData(res.data)
                    let formItems = this.state.formItems
                    formItems['username'] = (agent[0] != undefined) ? agent[0].username : agent.username
                    
                    this.getRetailer(formItems.username);
                    this.setState({formItems, agent });
                }else{
                    this.auth.doAlert(parseInt(res.data));
                }
            })
        }
    }

    getRetailer = (retailer) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/' + retailer + '/2')
        .then(res => {
            if (isNaN(res.data)) {
                let retailer = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                formItems['retailer'] = (retailer[0] != undefined) ? retailer[0].username : retailer.username

                this.setState({ retailer })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    handleNewBatch = () => {
        ReactDOM.render(
            <TransactionForm batch={this.state.batch} />,
            document.getElementById('agent_inner')
        )
    }
    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        window.agent = ptype
        this.setState({ formItems: fitm })
        this.getRetailer(ptype)
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems
        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate

        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    dateFormat = (cell, row) => {
        return (moment(cell).format("DD MMM, YYYY,h:mm a").toLocaleString())
    }

    amountFormat(cell, row) {
        return amountformat.format(cell)
    }
  
    retailerFormat = (cell, row) => cell == '' ? row.username : cell;
    desFormat = (cell, row) => {
        let notes = (row.tillType == 2) ? momo[cell] : desc[cell];
        return notes;
    }
    drcrFormat = (cell, row) => {
        let amount =  0 ;
        if(row.tillType == 2){
            amount = (cell == 2) ? `(${amountformat.format(row.credits)})` : `${amountformat.format(row.debits)}`;
        }else{
            amount = ([-1,-2,4].includes(cell)) ? `(${amountformat.format(row.credits)})` : `${amountformat.format(row.debits)}`;
        }

        return amount;
    }

    render() {
        //let buttonStyle = { width: '100%' };
        let start = this.state.startDate.format('MMMM D, YYYY');
        let end = this.state.endDate.format('MMMM D, YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <div className="row">
                <div className="col-xs-12 clearfix">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">DateRange: </button>
                        <DatetimeRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            ranges={this.state.ranges}
                            onEvent={this.handleEvent}
                            className="selected-date-range-btn btn btn-default">
                            <div className="pull-left">
                                <i className="fa fa-calendar" /> &nbsp; &nbsp;
                                    <span>{label}</span>
                            </div>
                            <div className="pull-right">
                                &nbsp; &nbsp;<i className="fa fa-angle-down" />
                            </div>
                        </DatetimeRangePicker>
                        <button className="btn btn-default">Agent: </button>
                        <select id="" name="username" onChange={this.handleChange} value={this.state.formItems.username} className="btn btn-default" style={{ width: '200px', height:'30px' }}>
                            {this.state.agent.map(opt =>
                                <option value={opt.username}>{opt.fullname}</option>
                            )}
                        </select>
                        <button onClick={this.getJournaldata} className="btn btn-info">Display</button>

                       
                    </div>
                    <div className="btn btn-sm pull-right" >
                            <button className="btn btn-success" data-toggle="modal" data-target="#agentModal">Agent Transfer</button>
                        </div>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-12 ">
                    <BootstrapTable data={this.state.journaldata} striped hover condensed pagination search>
                        <TableHeaderColumn dataField='id' width="150px" isKey dataSort>Reference No.</TableHeaderColumn>
                        <TableHeaderColumn dataField='ticketSerial' width="200px" dataSort >Retailer</TableHeaderColumn>
                        <TableHeaderColumn dataField='transSource' width="300px" dataSort dataFormat={this.desFormat}>Description</TableHeaderColumn>
                        <TableHeaderColumn dataField='openBal' width="90px" dataFormat={this.amountFormat} dataSort dataAlign="right">Open Bal</TableHeaderColumn>
                        <TableHeaderColumn dataField='transSource' dataAlign="right" width="90px" dataFormat={this.drcrFormat} dataSort >Amount</TableHeaderColumn>
                        <TableHeaderColumn dataField='closeBal' width="90px" dataFormat={this.amountFormat} dataAlign="right" dataSort>Close Bal</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdon' width="150px" dataFormat={this.dateFormat} dataSort>Created On</TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <AgentForm agent={this.state.agent}/>
            </div>
        )
    }
}
