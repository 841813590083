import React, { Component } from 'react';
import AgentList from './AgentList';
import ReactDOM from 'react-dom'

export default class Agents extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>Agents / Retailers </strong> <i>List</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="agent_inner" className="widget-body"><AgentList /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}