import Config from '../Config'
import CryptoJS from 'crypto-js'

export default class Auth {  
  login = (frmLogin) => {
    const config = new Config()

    const $http = config.DConnect()
    $http.get('admin/wapp/validate/'  + frmLogin.email + '/' + frmLogin.pwd)
    .then(res => {
      let islogin = this.decryptData(res.data)
      
      if (islogin) {
        $http.get('admin/wapp/user/' + frmLogin.email)
        .then(res => {
            let user = this.decryptData(res.data)

          $http.get('admin/wapp/' + user.username + '/' + user.token + '/role/'  + user.auth)
          .then(res => {
            let role = this.decryptData(res.data);

            this.setToken(user.token)
            this.setUser(user.username)
            this.setFeatures(role.features)
            this.setRole(role.rolename)

            window.location.reload(true);
          }).catch((error)=>{alert(error)})
        }).catch((error)=>{alert(error)})
      }else {
        alert('Problem login')
      }
    }).catch((error)=>{alert('Validate: ' + error)})
  }

  doAlert = (status) => {
    let msg = (status === 601) ? "You have been logged out by the system..." 
    : "The system is in lock mode, please try again later..."
    alert(msg)
    this.logout()
    window.location.reload(true)
  }

  isAuth = () => {
    return this.loggedIn()
  }

  loggedIn = () => {
    return !!this.getUser()
  }

  setToken = (idToken) => {
    sessionStorage.setItem('alpha_token', idToken)
  }

  getToken = () => {
    return sessionStorage.getItem('alpha_token')
  }

  setRole = (rolename) => {
    sessionStorage.setItem('alpha_rolename', rolename)
  }
  
  getRole = () => {
    return sessionStorage.getItem('alpha_rolename')
  }

  setUser = (user) => {
    sessionStorage.setItem('alpha_user', user)
  }

  getUser = () => {
    return sessionStorage.getItem('alpha_user')
  }

  setOperator = (code) => {
    sessionStorage.setItem('alpha_operator', code)
  }

  getOperator = () => {
    return sessionStorage.getItem('alpha_operator')
  }

  setCompanySetup = (company) => {
    sessionStorage.setItem('zcompanysetup', company)
  }

  getCompanySetup = () => {
    return sessionStorage.getItem('zcompanysetup')
  }

  setCookie = (name, company) => sessionStorage.setItem(name, JSON.stringify(company));


  getCookie = (name) => sessionStorage.getItem(name);

  getJCookie = (name) => JSON.parse(sessionStorage.getItem(name));

  logout = () => {
    //Code goes here
    const config = new Config();

    const $http = config.DConnect();
    $http.get('admin/wapp/logout/'  + this.getUser())
    .then(res => {});

    sessionStorage.removeItem('alpha_token');
    sessionStorage.removeItem('alpha_user');
    sessionStorage.removeItem('alpha_operator');
    sessionStorage.removeItem('features');
    sessionStorage.removeItem('SysDate');
    sessionStorage.removeItem('alpha_rolename');
  }

  getKey = () => {
    return 'V4ksiXwsy1g='
  }

  encryptData = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var encrypted = CryptoJS.DES.encrypt(dt, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString()
  }

  decryptData = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(dt)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
  }

  decryptText = (dt) => {
    var keyHex = CryptoJS.enc.Base64.parse(this.getKey())
    var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(dt)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  setFeatures = (userole) => {
    sessionStorage.setItem('features', userole)
  }

  getFeatures = () => {
    return sessionStorage.getItem('features')
  }

  setSysDate = (SysDate) => {
    sessionStorage.setItem('SysDate', SysDate)
  }

  getSysDate = () => {
    return sessionStorage.getItem('SysDate')
  }
}
