import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import SendSMS from './SendSMS'
import SMSThreshold from './SMSThreshold'
import SMSTemplate from './SMSTemplate'
import SMSNewTemplate from './SMSNewTemplate'
import SMSReciever from './SMSReciever'

export default class SMSConfig extends Component{
    constructor(){
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:'',message:'', threshold:'', senderid:'', provider: 'smsgh',phone:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            } ,charsLeft: 140,
            maxChars: 140,smsconfig:[]
        }
    }
    
    componentDidMount(){
       
    }
    
    render(){
        return(
            <div className="row">
            <article className="col-xs-12">
                <ul className="nav nav-tabs in" id="tablist">
                    <li className="active"><a href="#sms_templates" data-toggle="tab">SMS Templates</a></li>
                    <li><a href="#sms_reciever" data-toggle="tab">SMS Recievers</a></li>
                    <li><a href="#sms_config" data-toggle="tab">SMS Configuration</a></li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade in active padding-10 no-padding-bottom" id="sms_templates"><SMSTemplate /></div>
                    <div className="tab-pane fade in padding-10 no-padding-bottom" id="sms_reciever"><SMSReciever /></div>
                    <div className="tab-pane fade in padding-10 no-padding-bottom" id="sms_config"><SMSThreshold /></div>
                </div>
            </article>
        </div>    
        )
    }
}