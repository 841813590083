import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import moment from 'moment'
import GameSetupList from './GameSetupList';

const weekday = {0: 'All Days', 1:'Monday', 2:'Tuesday', 3:'Wednesday', 4:'Thursday', 5:'Friday', 6:'Saturday', 7:'Sunday' }

export default class GameSetupForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            formItems: {
                id:0, gamename:'', drawday:0, endtime: new Date().getTime(), showOnUssd:0, showOnClient:0,
                minNo:1, maxNo:90, gametype:1, availDaysBefore:1, showOnApp:0, showOnWeb:0, 
                createdby: this.auth.getUser(), createdon: new Date(), filex: '',
                modifiedby: null, modifiedon: null, curuser: this.auth.getUser(),
                token: this.auth.getToken()
            },
            docItems: {picture_filename: null}
        }
    }

    componentDidMount(){
        let formItems

        if(this.props.rowData.id !== undefined){
            formItems = this.props.rowData
            formItems['endtime'] = moment(formItems.endtime).format("HH:mm:ss")
            formItems['curuser'] = this.auth.getUser()
        }else{
            formItems = {
                id:0, gamename:'', drawday:0, endtime: new Date().getTime(),
                minNo:1, maxNo:90, gametype:1, availDaysBefore:1, createdby: this.auth.getUser(), 
                createdon: new Date(), modifiedby: null, modifiedon: null, curuser: this.auth.getUser(),
                token: this.auth.getToken()
            }
        }
        
        this.setState({ formItems })
        //alert(JSON.stringify(formItems))
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            let formItems
            if(nextProps.rowData.id !== undefined){
                formItems = nextProps.rowData
                formItems['endtime'] = moment(formItems.endtime).format("hh:mm:ss")
                formItems['curuser'] = this.auth.getUser()
            }else{
                formItems = {
                    id:0, gamename:'', drawday:0, endtime: new Date().getTime(),
                    minNo:1, maxNo:90, gametype:1, availDaysBefore:1, createdby: this.auth.getUser(), 
                    createdon: new Date(), modifiedby: null, modifiedon: null, curuser: this.auth.getUser(),
                    token: this.auth.getToken()
                }
            }
            
            this.setState({ formItems })
        }
    }

    goBack = () => {
        ReactDOM.render(
            <GameSetupList />,
            document.getElementById('gameSetupCon')
        )
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    imageClick = (id) => {
        document.getElementById(id).click()
    }

    imageChange = (e, picon) =>{
        let img = e.target.files[0]
        let ext = img.name.split('.')
        ext = ext[ext.length-1].toLowerCase()

        if(ext === 'jpg' || ext === 'jpeg' || ext === 'png'){
            let docItems = this.state.docItems
            let formItems = this.state.formItems
            docItems['picture_filename'] = img
            formItems['filex'] = ext
            
            this.setState({ docItems, formItems })

            let imgurl =  URL.createObjectURL(img)
            ReactDOM.render(
                <img src={imgurl} style={{width:'100px'}} />,
                document.getElementById(picon)
            )
        }else{
            alert('The supported files are: jpg and png...')
        }
    }

    formSubmit = (e) => {
        e.preventDefault()
        this.setState({ loader:true })

        let formItems = this.state.formItems
        formItems['endtime'] = moment(formItems.endtime + ':59', 'hh:mm:ss')
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'game/wapp/game'))
        .then((res) => {
            this.setState({ loader:false })
            if(isNaN(res.data)){
                let resdata = this.auth.decryptText(res.data)
                this.saveImage(resdata)
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    saveImage = (gamename) =>{
        let docItems = this.state.docItems
        if(docItems.picture_filename !== null){
            axios(this.config.PostMulti(docItems.picture_filename, 'game/wapp/gamefile/' + gamename + '.' + this.state.formItems.filex))
            .then((res) => {
                alert("Game saved successfully...")
                this.goBack()
            })
        }else{
            this.goBack()
        }
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <article className="col-xs-12">
                        <div className="btn-group btn-group-sm pull-right clearfix">
                            <button className="btn btn-warning" onClick={this.goBack}>
                                <i className="fa fa-lg fa-backward"></i> Go Back
                            </button>
                            <button type="submit" className="btn btn-success" form="frmGameSetup">
                                <i className="glyphicon glyphicon-save"></i> Save Game Setup
                            </button>
                        </div>
                    </article>
                    <article className="col-xs-12"><hr className="hrsp" /></article>
                    <article className="col-xs-12 col-sm-10">
                        <form id="frmGameSetup" className="smart-form" onSubmit={this.formSubmit}>
                            <div className="row">
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Game Name: </label>
                                    <section className="col col-7">
                                        <label className="input">
                                            <input type="text" name="gamename" placeholder="Name of Game" required 
                                            value={this.state.formItems.gamename} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Draw Day: </label>
                                    <section className="col col-7">
                                        <label className="select">
                                            <select name="drawday" value={this.state.formItems.drawday} onChange={this.handleChange}>
                                                {Object.keys(weekday).map(key => (
                                                    <option value={key}>{weekday[key]}</option>
                                                ))}
                                            </select> <i></i> 
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">End Time: </label>
                                    <section className="col col-7">
                                        <label className="input">
                                            <input type="time" name="endtime" required 
                                            value={this.state.formItems.endtime} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Active Days Before: </label>
                                    <section className="col col-7">
                                        <label className="input">
                                            <input type="number" name="availDaysBefore" required 
                                            value={this.state.formItems.availDaysBefore} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Lowest No.: </label>
                                    <section className="col col-7">
                                        <label className="input">
                                            <input type="number" name="minNo" required 
                                            value={this.state.formItems.minNo} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Game Type: </label>
                                    <section className="col col-7">
                                    <label className="select">
                                        <select name="gametype" value={this.state.formItems.gametype} onChange={this.handleChange}>
                                            <option value="1">Alpha Game </option>
                                            <option value="2">Other Game </option>
                                        </select> <i></i> 
                                    </label>
                                    </section>
                                </div>
                                <div className="col-xs-12  col-sm-6">
                                    <label className="label col col-5 text-right">Highest No.: </label>
                                    <section className="col col-7">
                                        <label className="input">
                                            <input type="number" name="maxNo" required 
                                            value={this.state.formItems.maxNo} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <label className="label col col-5 text-right">Show on POS: </label>
                                    <section className="col col-7">
                                        <label className="select">
                                            <select style={{minWidth:'100px'}} name="showOnApp" value={this.state.formItems.showOnApp} onChange={this.handleChange}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select> <i></i> 
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <label className="label col col-5 text-right">Show on WEB: </label>
                                    <section className="col col-7">
                                        <label className="select">
                                            <select style={{minWidth:'100px'}} name="showOnWeb" value={this.state.formItems.showOnWeb} onChange={this.handleChange}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select> <i></i> 
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <label className="label col col-5 text-right">Show on USSD: </label>
                                    <section className="col col-7">
                                        <label className="select">
                                            <select style={{minWidth:'100px'}} name="showOnUssd" value={this.state.formItems.showOnUssd} onChange={this.handleChange}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select> <i></i> 
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <label className="label col col-5 text-right">Show on Client App: </label>
                                    <section className="col col-7">
                                        <label className="select">
                                            <select style={{minWidth:'100px'}} name="showOnClient" value={this.state.formItems.showOnClient} onChange={this.handleChange}>
                                                <option value="0">No</option>
                                                <option value="1">Yes</option>
                                            </select> <i></i> 
                                        </label>
                                    </section>
                                </div>
                            </div>
                        </form>
                    </article>
                    <article className="col-xs-12 col-sm-2">
                        <div id="custImgCon" className="table-bordered imgCon text-center unselectable" onClick={()=>this.imageClick('pfilename')}>
                            <img src={this.config.ImagePath('gamefiles') + '/' + this.state.formItems.gamename.replace(/ /g,'_') + '.' + this.state.formItems.filex} style={{width:'100'}} alt="Photo" />
                        </div>
                        <input type="file" name="pictureFilename" style={{display:'none'}} id="pfilename" onChange={(e)=>this.imageChange(e,'custImgCon')} />
                    </article>
                </div>
            )
        }
    }
}