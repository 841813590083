import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import _ from 'lodash'

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
const requestStatus = {'0':'Successfull','1':'Pending','-1':'Rejected'}

export default class UnitRequestList extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: { startDate: moment().subtract(29, 'days'), endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken() },
            profiles: [], unitRequestData:[],loader: false,

            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        this.getProfiles()
    }

    getProfiles = () => {
        this.$http.get('admin/wapp/user')
        .then(res => {
            if (isNaN(res.data)) {
                let profiles = this.auth.decryptData(res.data)

                this.setState({ profiles })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getUnitRequestData = () => {
        this.setState({loader: true});
        let formItems = this.state.formItems;

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'report/wapp/unitrequest'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = this.auth.decryptData(res.data)

                let unitRequestData = _.orderBy(resdata, ['id'], ['desc'])
                //alert(JSON.stringify(resdata))
                this.setState({ unitRequestData })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
               
            this.setState({loader: false});
        })
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        window.agent = ptype
        this.setState({ formItems: fitm })
        this.getRetailer(ptype)
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems
        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    dateFormat = (cell, row) => {
        return (moment(cell).format("DD MMM, YYYY @ h:mm a").toLocaleString())
    }
    nameFormat = (cell, row) => {
        let user = this.state.profiles.filter(pro => pro.username == cell)
        return (user.length > 0) ? user[0].firstname + ' ' + user[0].lastname : ''
    }
    statusFormat = (cell, row) => requestStatus[cell]
    amountFormat(cell, row) {
        return amountformat.format(cell)
    }
    actionFormat = (cell, row) => {
        return <div>
            {/* <i className="fa fa-lg fa-eye" style={{cursor:'pointer'}} onClick={()=>this.viewTranDetails(cell)}></i> 
            {row.status == 0 &&<> &nbsp; | &nbsp; 
            <i className="fa fa-lg fa-refresh" title="Re-check" style={{cursor:'pointer'}} onClick={()=>this.recheckTrans(row)}></i></>} */}
        </div>
    }
    descFormat = (cell, row) => {
        let user = this.state.profiles.filter(pro => pro.username == row.createdby)
        let username = (user[0] !== undefined) ? user[0].firstname + ' ' + user[0].lastname : ''
        return username + ' (' + row.createdby + ') ' + cell
    }

    render() {
        if(this.state.loader){
            return <div id="loader"></div>;
        }else{
            //let buttonStyle = { width: '100%' };
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {label = start;}

            return (
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">DateRange: </button>
                            <DatetimeRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                ranges={this.state.ranges}
                                onEvent={this.handleEvent}
                                className="selected-date-range-btn btn btn-default">
                                <div className="pull-left">
                                    <i className="fa fa-calendar" /> &nbsp; &nbsp;
                                        <span>{label}</span>
                                </div>
                                <div className="pull-right">
                                    &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                </div>
                            </DatetimeRangePicker>
                            <button onClick={this.getUnitRequestData} className="btn btn-info">Display</button>
                        </div>
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-12 ">
                        <BootstrapTable data={this.state.unitRequestData} striped hover condensed pagination search>
                            <TableHeaderColumn dataField='createdon' width="160px" dataFormat={this.dateFormat} dataSort>Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='transId' width="150px" isKey dataSort>Trans ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='createdby' width="150px" dataFormat={this.nameFormat} dataSort >Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='amount' dataAlign="right" dataFormat={this.amountFormat} width="90px" dataSort>Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' width="100px" dataFormat={this.statusFormat} dataSort>Status</TableHeaderColumn>
                            <TableHeaderColumn dataField='modifiedby' width="150px" dataFormat={this.nameFormat} dataSort >Worked on by</TableHeaderColumn>
                            <TableHeaderColumn width="80px" dataField='id' dataFormat={this.actionFormat} dataSort></TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            );
        }
    }
}
