import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PlaceholderForm from './PlaceholderForm';

export default class Placeholder extends Component {
    constructor(props) {
        super(props)
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            placeholders: [],
            scheduleTypes: [],
            scheduleType: 0
        }
    }

    componentDidMount() {
        this.setState({ loader: true })

        if(this.props.scheduleType !== undefined){
            let scheduleType = this.props.scheduleType

            this.setState({ scheduleType })
        }

        this.getDropdowns()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.setState({ loader: true })

            if(nextProps.scheduleType !== undefined){
                let scheduleType = nextProps.scheduleType
    
                this.setState({ scheduleType })
            }

            this.getDropdowns()
        }
    }

    getDropdowns = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/scheduleType')
            .then(res => {
                let scheduleTypes = this.auth.decryptData(res.data)

                this.setState({ scheduleTypes })

                this.getPlaceholder(this.state.scheduleType)
            })
    }

    getPlaceholder = (messagetype) => {
        this.setState({ loader: true })
        this.$http.get('admin/wapp/placeholder/' + messagetype)
            .then(res => {
                let placeholders = this.auth.decryptData(res.data)
                
                this.setState({ placeholders, loader: false })
            }).catch((error) => { alert(error) })
    }

    addNew = () => {
        ReactDOM.render(<PlaceholderForm scheduleTypes={this.state.scheduleTypes} scheduleType={this.state.scheduleType} />, 
            document.getElementById('generalConfigCon'))
    }

    deletePlaceholder = (row) => {
        let removeData = this.auth.encryptData(JSON.stringify(row))
        axios(this.config.PostOptions(removeData, 'admin/wapp/removeplaceholder'))
        .then((res) => {
            if (isNaN(res.data)) {
                alert(this.auth.decryptText(res.data))
                this.componentDidMount()
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error) => { alert(error) })
    }

    actionFormat = (cell, row) => {
        return (
            <div className="btn-group btn-group-xs">
                <button className="btn btn-danger" onClick={() => this.deletePlaceholder(row)}><i className="fa fa-trash-o"></i> Delete</button>
            </div>
        )
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    handleScheChange = (e) => {
        let scheduleType = e.target.value

        this.setState({ scheduleType })

        this.getPlaceholder(scheduleType)
    }

    loading = () => <div id="loader"></div>

    render() {
        if (this.state.loader) {
            return this.loading()
        } else {
            return (

                <div className="row">
                    <article className="col-xs-12" style={{ padding: '10px 20px 0 20px' }}>
                        <h3 className="pull-left">Placeholder</h3>
                    </article>
                    <article className="col-xs-12" style={{ padding: '10px 20px 0 20px' }}>
                        <hr className="hrsp" />
                        <div className="btn-group btn-group-sm pull-right clearfix">
                            <button type="submit" className="btn btn-success" onClick={this.addNew} form="frmSetup">
                                <i className="glyphicon glyphicon-plus" ></i> New Placeholder
                            </button>
                        </div>
                        
                        <div className="btn-group btn-group-sm">
                        <label className="col col-4 btn btn-default text-right">Schedule Type: </label>
                        <select name="scheduleType" className="btn btn-default" style={{width:'200px',height:'30px'}}
                        value={this.state.scheduleType} onChange={this.handleScheChange}>
                            <option value="0">-- Select schedule type --</option>
                            {this.state.scheduleTypes.map(sche => (
                                <option value={sche.ivalue}>{sche.iname}</option>
                            ))}
                        </select>
                        </div>
                    </article>
                    <div className="col-xs-12" style={{ padding: '10px 20px 0 20px' }}>
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.placeholders} striped hover condensed pagination >
                            <TableHeaderColumn dataField='fieldname' dataFormat={this.getAgentname} isKey dataSort>Fieldname</TableHeaderColumn>
                            <TableHeaderColumn dataField='placeholderText' dataFormat={this.getGross} dataSort>
                                Placeholder Text</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataFormat={this.actionFormat} width="200px" dataSort>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>

            )
        }
    }
}