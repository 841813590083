import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'


/**
 * 
 * This class Assign Terminal to Agents
 * 
 */
export default class AgentTerminalAssignForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            criteria: '',
            opcriterial: 0,
            operators: [],
            agents:[],
            reportData:[],
            selected: {},
            terminals:this.props.terminals
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let terminals = nextProps.terminals

            this.setState({ terminals })
            
            this.getOperators()
        }
    }

    /**
     * Retrieve Operators
     */
    getOperators = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/userole")
        .then(res => {
            let role = this.auth.decryptData(res.data)
            let systemDefine = (role.id <= 2) ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
            
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res => {
                let operators = this.auth.decryptData(res.data)
                let opcriterial = (operators[0]!==undefined && this.state.opcriterial==0) ? operators[0].username : this.state.opcriterial
                
                this.setState({ operators, opcriterial })

                this.getAgents(opcriterial)
            })
        })
    }

    /**
     * Retrieve Agents
     */
    getAgents = (operator) =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/superior/' + operator)
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            
            let agents = resdata.filter(rdata => rdata.usertype == '2')
            let criteria = (agents[0] !== undefined) ? agents[0].username : ''

            let selected = this.state.selected
            let reportData = this.state.terminals.filter(term => (term.username == '' && (term.agent == '' || term.agent == null)) || term.agent == criteria)
            
            reportData.map(rdata => {
                selected[rdata.id] = rdata.agent == criteria
            })
        
            this.setState({ agents, criteria, reportData, selected })
        })
    }

    handleChange = (e) =>{
        let reportData = this.state.terminals
        let formItems = this.state.formItems
        let criteria = this.state.criteria
        let opcriteria = this.state.opcriteria
        let selected = this.state.selected

        if(e.target.name === 'criteria'){
            criteria = e.target.value

            reportData = this.state.terminals.filter(term => (term.username == '' && (term.agent == '' || term.agent == null)) || term.agent == criteria)
            reportData.map(rdata => {
                selected[rdata.id] = rdata.agent == criteria
            })
        }else if(e.target.name === 'opcriteria'){
            opcriteria = e.target.value

            reportData = this.state.terminals.filter(term => (term.username == '' && (term.agent == '' || term.agent == null)) || term.agent == criteria)
            selected = reportData.map(rdata => {
                selected[rdata.id] = rdata.agent == criteria
            })
        }else{
            formItems[e.target.name] = e.target.value
        }

        this.setState({ criteria, opcriteria, formItems, reportData, selected })

        if(e.target.name === 'opcriteria'){this.getAgents(opcriteria)}
    }

    handleChecked = (e) => {
        let selected = this.state.selected
        let id = e.target.value

        selected[id] = !selected[id]

        this.setState({ selected })
    }

    /**
     * Submit assignments
     */
    formSubmit = (e) => {
        e.preventDefault()

        let criteria = this.state.criteria

        if(criteria == ''){
            alert('Agent is empty...')
            return
        }

        let selected = this.state.selected
        let isSelected = []

        Object.keys(selected).map(key => {if(selected[key]){isSelected.push(parseInt(key))}})

        let reportData = this.state.reportData.filter(rdata => {
            if(isSelected.includes(rdata.id)){rdata['agent'] = criteria; rdata['status'] = '4';}
            else{
                rdata['agent'] = null; rdata['username'] = ''; 
                if(rdata['status'] == '4'){rdata['status'] = '3';}
            }

            return (rdata)
        })
        
        let formData = this.auth.encryptData(JSON.stringify(reportData))
        axios(this.config.PostOptions(formData, 'admin/wapp/terminal/agent'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnAassigncancel').click()
                $('#btnTerminalDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade" id="agentterminalform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Agent Terminal Assignment</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmAgentTerminalAssign" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Operator: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="opcriteria" value={this.state.opcriteria} onChange={this.handleChange}>
                                                    {this.state.operators.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>

                                    <div className="col-xs-12 col-md-8 col-md-offset-2">
                                        <label className="label col col-3 text-right">Agents: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="criteria" value={this.state.criteria} onChange={this.handleChange}>
                                                    {this.state.agents.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>

                                    <div className="col-lg-12">
                                        <table className="table table-condensed table-striped table-hover">
                                            <thead>
                                                <tr><th></th><th>IMEI</th><th>Serial No.</th></tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.map(rdata => {
                                                    let selected = this.state.selected
                                                    return(
                                                        <tr>
                                                            <td><input type="checkbox" name="selected" value={rdata.id} checked={selected[rdata.id]} 
                                                            onChange={this.handleChecked} /></td>
                                                            <td>{rdata.imei}</td><td>{rdata.serial}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnAassigncancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmAgentTerminalAssign" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}