import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios';
import _ from 'lodash';

const local = { "format": "DD-MM-YYYY", "sundayFirst": false };
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

class CashTransList extends Component{
    constructor(props){
        super(props);

        this.auth = new Auth();
        this.config = new Config();
        this.$http = this.config.DConnect();

        this.state = {
            formItems: { username: '', agent: '', retailer: '' ,startDate: moment(),endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken()},
            cashiers: [],

            startDate: moment(),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            }, loader: false
        }
    }

    componentDidMount = () =>{
        this.getCashiers();
    }

    getCashiers = () => {
        this.setState({ loader: true });
        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/cashier/profile`)
        .then(res => {
            if(isNaN(res.data)){
                let cashiers = this.auth.decryptData(res.data);

                this.setState({ cashiers });
            }else{
                this.auth.doAlert(parseInt(res.data));
            }
            this.setState({ loader: false });
        }).catch(error => {alert(error.message); this.setState({ loader: false });});
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems

        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    getJournaldata = () => {
        let { formItems } = this.state;
        if(formItems.username === ''){
            alert("Please select a Cashier...");
            return;
        }
        this.setState({ loader: true });
        

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        /*axios(this.config.PostOptions(formData, 'transaction/wapp/journal'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = this.auth.decryptData(res.data)*/

                axios(this.config.PostOptions(formData, 'transaction/wapp/tilltrans/cashier'))
                .then((res) => {
                    if(isNaN(res.data)){
                        let journaldata = this.auth.decryptData(res.data)

                        //alert(JSON.stringify(rdata))
                        //let journaldata = resdata.concat(rdata);
                        journaldata = _.orderBy(journaldata, 'createdon', 'desc');
                        this.setState({ journaldata, loader: false });
                    }else{
                        this.auth.doAlert(parseInt(res.data));
                        this.setState({ loader: false });
                    }
                })
                
            /*}else{
                this.auth.doAlert(parseInt(res.data));
                this.setState({ loader: false });
            }
                 
        })*/
         
     }

    handleChange = (e) => {
        let { formItems } = this.state
        formItems[e.target.name] = e.target.value;

        this.setState({ formItems });
    }

    dateFormat = (cell, row) => (moment(cell).format("DD MMM, YYYY,h:mm a"));
    amountFormat = (cell, row) => amountformat.format(cell);

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading();
        }else{
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {
                label = start;
            }
            return(
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="col-xs-12 btn-group btn-group-sm">
                            <button className="btn btn-default">DateRange: </button>
                            <DatetimeRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                ranges={this.state.ranges}
                                onEvent={this.handleEvent}
                                className="selected-date-range-btn btn btn-default">
                                    <div className="pull-left">
                                        <i className="fa fa-calendar" />&nbsp; &nbsp; 
                                        <span>{label}</span>
                                    </div>
                                    <div className="pull-right">
                                        &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                    </div>
                            </DatetimeRangePicker>
                            <button className="btn btn-default">Cashiers: </button>
                            <select id="username" name="username" onChange={this.handleChange} value={this.state.formItems.username} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                <option value="">-- Select Cashier --</option>
                                {this.state.cashiers.map(cash =>
                                    <option value={cash.username}>{cash.firstname} {cash.lastname}</option>
                                )}
                            </select>
                            <button onClick={this.getJournaldata} className="btn btn-info">Display</button>
                            <div className="btn btn-sm pull-right" >
                                            <button className="btn btn-success" data-toggle="modal" data-target="#operatorModal">Cashier Transfer</button>
                                </div>
                        </div>
                        
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-12 ">
                        <BootstrapTable data={this.state.journaldata} striped hover condensed pagination search>
                            <TableHeaderColumn dataField='refno' width="140px" isKey dataSort >Reference No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='username' width="200px" dataSort >Operator/Agent/Retailer</TableHeaderColumn>
                            <TableHeaderColumn dataField='notes' dataSort>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='openBal' dataAlign="right"  dataSort>Open Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='amount' dataAlign="right" width="100px" dataFormat={this.amountFormat} dataSort>Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField='closeBal' dataAlign="right"  width="100px" dataSort>Close Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='createdon' width="200px" dataFormat={this.dateFormat} dataSort>Created On</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    {/*<OperatorForm operator={this.state.operators} agent={this.state.agent} />*/}
                </div>
            )
        }
    }
}

export default CashTransList;