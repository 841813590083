import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import DrawDataPrint from './DrawDataPrint';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class DrawDataList extends Component{
    constructor(props){
    super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [], loader: false,
            games: [],
            bets: [],
            events: [],
            formItems: { gamecode:0, drawno:0 }
        }
    }

    componentDidMount(){
        this.getGames()
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/betting')
        .then(res => {
            let bets = this.auth.decryptData(res.data)

            this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
            .then(res => {
                let games = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                if(games[0] !== undefined){
                    formItems['gamecode'] = games[0].id
                    this.getEvents(games[0].id, 0)
                }

                this.setState({ games, formItems, bets })
            })
        })
    }

    getEvents = (gamecode) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamedetailcode/' + gamecode)
        .then(res => {
            let events = this.auth.decryptData(res.data);
            let formItems = this.state.formItems;

            if(events[0] !== undefined){
                formItems['drawno'] = events[0].drawno;
            }

            this.setState({ events, formItems });
        })
    }

    handleDisplay = () => {
        this.setState({ loader: true });
        let fitm = this.state.formItems
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/ticketbets/' + fitm.gamecode + "/" + fitm.drawno)
        .then(res => {
            let reportData = this.auth.decryptData(res.data);

            this.setState({ reportData, loader: false });
        })
    }

    handleChange = (e) => {
        let formItems = this.state.formItems;
        formItems[e.target.name] = e.target.value;

        this.setState({ formItems });

        if(e.target.name === 'gamecode'){
            this.getEvents(e.target.value);
        }
    }

    gameFormat = (cell, row) => {
        let game = this.state.games.filter(gm => gm.id == cell);
        let gamename = (game[0]!==undefined)?game[0].gamename : cell;

        return gamename;
    }

    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY @ hh:mma')

    amountFormat = (cell, row) => numformat.format(cell)

    betFormat = (cell, row) => {
        let bet = this.state.bets.filter(bt => bt.id == cell)
        let betname = (bet[0]!==undefined)?bet[0].betname : cell;

        return betname;
    }

    stakeFormat = (cell, row) => {
        let stakenum = cell
        if(row.bets == 11){
            let num = ['', '']
            if(cell.indexOf('~') != -1){
                num = cell.split('~')
            }else{
                let snum = cell.split('-')
                num[0] = snum[0]
                snum.shift()
                let against = snum.join(' - ')
                num[1] = against
            }
            stakenum = num[0] + '<br /><i>Against</i><br />'+ num[1]
        }

        return stakenum
    }

    stakePrintFormat = (cell, row) => {
        let stakenum = cell
        if(row.bets == 11){
            let num = ['', '']
            if(cell.indexOf('~') != -1){
                num = cell.split('~')
            }else{
                let snum = cell.split('-')
                num[0] = snum[0]
                snum.shift()
                let against = snum.join(' - ')
                num[1] = against
            }
            stakenum = num[0] + ' Against '+ num[1]
        }

        return stakenum
    }

    doPreview = () => {
        let rowData = this.state.reportData.map(rdata => {
            rdata['stakeNum'] = this.stakePrintFormat(rdata.stakeNum,rdata)
            rdata['lineTotal'] = this.amountFormat(rdata.lineTotal,rdata)
            rdata['game'] = this.gameFormat(rdata.game,rdata)
            rdata['bets'] = this.betFormat(rdata.bets,rdata)
            rdata['createdon'] = this.dateFormat(rdata.createdon,rdata)

            return rdata
        })

        let data = {
            title:'Draw Data Report', gamename:rowData[0].game, event:rowData[0].event,
            totalbets:rowData.length, drawDate: moment().format('DD MMM, YYYY @ hh:mm a')
        }

        ReactDOM.render(
            <DrawDataPrint rowData={rowData} data={data} />,
            document.getElementById('drawDataCon')
        )
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Game: </button>
                            <select name="gamecode" className="btn btn-default" style={{width:'150px', height:'30px'}}
                                value={this.state.formItems.gamecode} onChange={this.handleChange}>
                                {this.state.games.map(game => (
                                    <option value={game.id}>{game.gamename}</option>
                                ))}
                            </select>
                            <button className="btn btn-default">Event: </button>
                            <select name="drawno" className="btn btn-def/ ult" style={{width:'150px', height:'30px'}}
                                value={this.state.formItems.drawno} onChange={this.handleChange}>
                                {this.state.events.map(rdata => (
                                    <option value={rdata.drawno}>#{rdata.drawno} ({moment(rdata.drawDate).format('DD MMM, YYYY')})</option>
                                ))}
                            </select>
                            <button id="btnDisplay" className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 "><hr className="hrsp" /></div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        {this.state.reportData.length > 0 && 
                        <div className="btn-group btn-group-sm text-right">
                            <button className="btn btn-success" onClick={this.doPreview}>Preview Draw Data</button>
                        </div>}
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='game' isKey dataSort width="120px" dataFormat={this.gameFormat}>Game</TableHeaderColumn>
                            <TableHeaderColumn dataField='event' dataSort width="80px">Event #</TableHeaderColumn>
                            <TableHeaderColumn dataField='ticketSerial' dataSort width="180px" >Ticket #</TableHeaderColumn>
                            <TableHeaderColumn dataField='createdon' dataSort width="180px"  dataFormat={this.dateFormat}>Timestamp</TableHeaderColumn>
                            <TableHeaderColumn dataField='agent' dataSort width="100px">Agent</TableHeaderColumn>
                            <TableHeaderColumn dataField='retailer' width="100px" dataSort >Retailer</TableHeaderColumn>
                            <TableHeaderColumn dataField='bets' dataSort width="120px"  dataFormat={this.betFormat}>Bets</TableHeaderColumn>
                            <TableHeaderColumn dataField='noOfLines' dataSort width="100px">Lines</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} width="200px"  dataField='stakeNum' dataSort dataFormat={this.stakeFormat}>Stake Number</TableHeaderColumn>
                            <TableHeaderColumn dataField='lineTotal' dataAlign="right" width="120px"  dataSort dataFormat={this.amountFormat}>Line Total</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            )
        }
    }
}