import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { BrowserRouter, Link } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import FileManagerForm from './FileManagerForm';
import FileManagerFolder from './FileMangerFolder';

export default class FileManagerList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData:[],
            folders: [],
            rowData: {}
        }
    }

    componentDidMount(){
        this.generalFiles()
    }

    generalFiles = () => {
        this.makeActive(1)
        let reportData = []
        ReactDOM.render(this.showFiles(reportData, 'General Files'), 
            document.getElementById('fileManagerCon'))
    }

    privateFiles = (folder, num) => {
        let extra = (folder !== '') ? ' => ' : ''
        this.makeActive(num)
        let reportData = []
        ReactDOM.render(this.showFiles(reportData, 'Private Files' + extra + folder), 
            document.getElementById('fileManagerCon'))
    }

    createFolder = () => {//popup
        this.makeActive(4)
    }

    addFiles = () => {//popup
        this.makeActive(3)
    }

    viewFileContent = () => {
        this.makeActive(0)
    }

    showFiles = (reportData, title) =>{
        return(
            <div>
                <h3>{title}</h3>
                <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                    <TableHeaderColumn dataField='id' isKey dataSort dataFormat={this.actionFormat}></TableHeaderColumn>
                    <TableHeaderColumn dataField='filenumber' dataSort dataFormat={this.fileFormat}>File #</TableHeaderColumn>
                    <TableHeaderColumn dataField='title'>File Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='createdon'>Archived On</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }

    makeActive = (itm) => {
        $('[id^="btnFile_"]').removeClass('active')
        $('#btnFile_' + itm).addClass('active')
    }

    actionFormat = (cell, row) => {
        return(
            <div className="btn-group btn-group-xs">
                <button className="btn btn-default"><i className="fa fa-edit"></i> Edit </button>
                <button className="btn btn-default"><i className="fa fa-share"></i> Share </button>
                <button className="btn btn-default"><i className="fa fa-trash-o"></i> Delete </button>
            </div>
        )
    }

    fileFormat = (cell, row) =>{
        return(<BrowserRouter>
            <Link to="#" onClick="">{cell}</Link>
        </BrowserRouter>)
    }

    render(){
        let cnt = 4
        return(
            <div className="row">
                <article className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                    <div className="btn-group btn-group-vertical btn-group-sm" style={{width:'100%'}}>
                        <button id="btnFile_1" className="btn btn-info btn-block active" onClick={this.generalFiles}>General Files</button>
                        <button id="btnFile_2" className="btn btn-success btn-block" onClick={() => this.privateFiles('', 2)}>Private Files</button>
                        
                        <hr class="separator hrsp"></hr>
                        <button id="btnFile_3" className="btn btn-primary btn-block" data-toggle="modal" data-target="#frmFileManager" 
                        onClick={this.addFiles}>
                            <i className="fa fa-plus"></i> Add Files
                        </button>
                        <button id="btnFile_4" className="btn btn-warning btn-block" data-toggle="modal" data-target="#frmFileFolder" 
                        onClick={this.createFolder}>
                        <i className="fa fa-plus"></i> Create Folders
                        </button>

                        <hr class="separator hrsp"></hr>
                        {this.state.folders.map(fold => {
                            cnt++
                            return(<button id={'btnFile_'+ cnt} className="btn btn-success btn-block" onClick={() => this.privateFiles('Folder', cnt)}>
                                Folder
                            </button>)
                        })}
                        
                    </div>
                </article>
                <article id="fileManagerCon" className="col-xs-12 col-sm-8 col-md-9 col-lg-10"></article>
                <FileManagerForm />
                <FileManagerFolder />
            </div>
        )
    }
}