import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import AgentList from './AgentLists'
import ReactDOM from 'react-dom'
import RetailerPerAgent from './RetailerPerAgent';
import AgentSale from './AgentSale';
import GameSale from './GameSale';
import RetailerSale from './RetailerSale';
import AgentPayouts from './AgentPayouts';
import AgentSummary from './AgentSummary'
import Commission from './Commission'
import WinsPayRetailerReport from './WinsPayRetailerReport'
import WinsPaymentReport from './WinsPaymentReport'
import AgentPerformanceReport from './AgentPerformanceReport'

export default class ReportView extends Component {
    constructor() {
        super()


        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false
        }
    }

    componentDidMount() {


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {

        }
    }

    showReport = (area) => {
        
        let report = {
            'agentlist': <AgentList />,
            'retailerperagent': <RetailerPerAgent />,
            'agentsale': <AgentSale />,
            'retailersale': <RetailerSale />,
            'gamesale': <GameSale />,
            'agentpayout': <AgentPayouts />,
            'agentsummary': <AgentSummary />,
            'commission':<Commission />,
            'retailerPayment': <WinsPayRetailerReport />,
            'payment': <WinsPaymentReport />,
            'agentPerformance': <AgentPerformanceReport />
        }

        ReactDOM.render(report[`${area}`], document.getElementById('generalReport_inners'))

    }
    loading = () => <div id="loader"></div>
    render() {
        if (this.state.loader) {
            return this.loading()
        } else {
            return (
                <div className="row">
                    <article className="col-xs-12">
                        <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                                <h2><strong>General Reports</strong> </h2>
                            </header>
                            <div>
                                <div className="jarviswidget-editbox"></div>
                                <div className="widget-body">
                                    <div className="row" id="agent_innerview">

                                        <div class="row">
                                            <a href="#" onClick={() => this.showReport("agentlist")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-user fa-4x"></span>
                                                        <h2><b>Agent List</b></h2>
                                                    </div>
                                                </div></a>
                                            <a href="#" onClick={() => this.showReport("retailerperagent")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-users fa-4x"></span>
                                                        <h2><b>Retailer Per Agent</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" onClick={() => this.showReport("agentsale")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-pie-chart fa-4x"></span>
                                                        <h2><b>Agent Sale</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" onClick={() => this.showReport("retailersale")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-area-chart fa-4x"></span>
                                                        <h2><b>Retailer Sale</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <article className="col-xs-12"><hr className="hrsp" /></article>
                                        <div class="row">
                                            <a href="#" onClick={() => this.showReport("gamesale")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-line-chart  fa-4x"></span>
                                                        <h2><b>Game Sale</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" onClick={() => this.showReport("agentpayout")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-bar-chart  fa-4x"></span>
                                                        <h2><b>Agent Payout</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" onClick={() => this.showReport("agentsummary")}>
                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-file fa-4x"></span>
                                                        <h2><b>Agent Summary</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" onClick={() => this.showReport("commission")}>

                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-book fa-4x"></span>
                                                        <h2><b>Commission</b></h2>
                                                    </div>
                                                </div>
                                            </a>

                                            {/* <a href="#" onClick={()=>this.showReport("retailerPayment")}>
                                    <div class="column" style={{background:'url(assets/img/report.jpg) fixed center',height:'200px',border:'1px solid white'}}>
                                        <div class="card">
                                        &nbsp;
                                        <span className="fa fa-book fa-4x"></span>
                                        <h2><b>Retailer Wins Payment</b></h2>
                                        </div>
                                    </div>
                                    </a>
                                    <a href="#" onClick={()=>this.showReport("payment")}>
                                    <div class="column" style={{background:'url(assets/img/report.jpg) fixed center',height:'200px',border:'1px solid white'}}>
                                        <div class="card">
                                        &nbsp;
                                        <span className="fa fa-book fa-4x"></span>
                                        <h2><b>Agent Wins Payment</b></h2>
                                        </div>
                                    </div>
                                    </a> */}
                                        </div>
                                        <article className="col-xs-12"><hr className="hrsp" /></article>
                                        <div class="row">
                                            <a href="#" onClick={() => this.showReport("agentPerformance")}>

                                                <div class="column" style={{ background: 'url(assets/img/report.jpg) fixed center', height: '200px', border: '1px solid white' }}>
                                                    <div class="card">
                                                        &nbsp;
                                                        <span className="fa fa-users fa-4x"></span>
                                                        <h2><b>Agent Performance</b></h2>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            )
        }
    }
}