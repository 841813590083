import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import $ from 'jquery'

export default class BatchForm extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            username: '',
            roles: [],
            formItems: {
                id: '', batchname: '', createdon: new Date(), createdby: this.auth.getUser(), status: 1, batchtotal: 100
            }
        }
    }
    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        this.setState({ formItems })
    }
    componentDidMount(){
        
    }
    formSubmit = (e) => {
        e.preventDefault()
        let formData = this.auth.encryptData(JSON.stringify(this.state.formItems))
        axios(this.config.PostOptions(formData, 'transaction/wapp/'+ this.auth.getUser()+ '/'+ this.auth.getToken() +'/batch')).then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btncancel').click()
                this.componentDidMount()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error) => { alert(error) })
        
    }

    
    render() {
        return (
            <div className="row">
               <div class="modal fade" id="basicExampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="false">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span>
                                </button>
                                <h5 class="modal-title" id="exampleModalLabel">New Batch</h5>

                            </div>
                            <form id="frmBatch" className="smart-form" onSubmit={this.formSubmit}>

                                <div class="modal-body">
                                    <fieldset>
                                        <section>
                                            <div class="row">
                                                <label class="label col col-2">Batch Name</label>
                                                <div class="col col-8">
                                                    <label class="input"> <i class="icon-append fa fa-user"></i>
                                                        <input type="text" name="batchname" value={this.state.formItems.batchname} onChange={this.handleChange} />
                                                    </label>
                                                </div>
                                            </div>
                                        </section>
                                              


                                    </fieldset>

                                </div>
                                <footer>
                                    <button type="submit" class="btn btn-primary">
                                        Save
			</button>
                                    <button type="button" id="btncancel" class="btn btn-default" data-dismiss="modal">
                                        Cancel
			</button>
                                </footer>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
