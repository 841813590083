import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'

export default class SMSThreshold extends Component{
    constructor(props){
        super(props)
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:'', message:'', threshold:0, senderid:'', provider: 0,phone:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            charsLeft: 140, maxChars: 140,smsconfig:[], apis: []
        }
    }

    componentDidMount(){
        this.getSMSAPI()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            this.getSMSAPI()
        }
    }

    getSMSAPI = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/api/1')
        .then(res => {
            let apis = this.auth.decryptData(res.data)
            let formItems = this.state.formItems

            if(apis[0] !== undefined){
                formItems['provider'] = apis[0].id
            }

            this.setState({ apis, formItems })

            this.getSetup()
        }).catch((error) => { alert(error) })
    }

    handleChange=(e)=>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        this.setState({ formItems }) 

        if (e.target.name == 'message') {
            const charCount = e.target.value.length
            const maxChar = this.state.maxChars
            const charLength = maxChar - charCount
            this.setState({ charsLeft: charLength })
        }
    }

    getSetup=()=>{
        this.$http.get('admin/wapp/smsconfig')
        .then(res => {
            let smsconfig = this.auth.decryptData(res.data)
            let formItems =this.state.formItems
            let charsLeft = this.state.charsLeft

            formItems['id']=(smsconfig !='')?smsconfig[0].id:formItems.id
            formItems['provider']=(smsconfig !='')?smsconfig[0].provider:formItems.provider
            formItems['curuser']=this.auth.getUser()
            formItems['threshold']=(smsconfig != '')?smsconfig[0].threshold:formItems.threshold
            formItems['message']=(smsconfig !='')?smsconfig[0].message:formItems.message
            formItems['phone']=(smsconfig !='')?smsconfig[0].phone:formItems.phone
            formItems['senderid']=(smsconfig !='')?smsconfig[0].senderid:formItems.senderid
            formItems['token']=this.auth.getToken()

            charsLeft -= formItems.message.length
             this.setState({ formItems, charsLeft, loader: false })
        }).catch((error) => { alert(error) })
    }

    submit=(e)=>{
        e.preventDefault()

        let formItems = this.state.formItems
        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/smsconfig'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.componentDidMount()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12 col-sm-2 pull-right">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-success" onClick={this.submit} form="frmSmsSetup">
                            <i className="glyphicon glyphicon-save"></i> Save
                        </button>
                    </div>
                </article>
                <article className="col-xs-12 col-sm-10">
                    <form id="frmSmsSetup" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Provider: </label>
                                <section className="col col-8">
                                <label class="select">
                                    <select value={this.state.formItems.provider} onChange={this.handleChange} name="provider">
                                        {this.state.apis.map(api =>(
                                            <option value={api.id}>{api.name}</option>
                                        ))}
                                    </select><i></i>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Sender ID: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="text" name="senderid" placeholder="Sender ID" value={this.state.formItems.senderid} onChange={this.handleChange} aria-required="true" />
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Threshold: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="number" name="threshold" placeholder="Threshold" value={this.state.formItems.threshold} onChange={this.handleChange} aria-required="true" aria-describedby="email-error"/>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Reminder Mobile: </label>
                                <section className="col col-8">
                                    <label className="input">
                                    <input type="text" name="phone" placeholder="Reminder Mobile" value={this.state.formItems.phone}  onChange={this.handleChange} aria-required="true" aria-describedby="phone-error" />
                                    </label>
                                </section>
                            </div>

                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">Reminder Mobile: </label>
                                <section className="col col-8">
                                    <label class="textarea textarea-resizable">
                                        <textarea rows="3" name="message" class="custom-scroll" value={this.state.formItems.message} onChange={this.handleChange} maxLength={this.state.maxChars}></textarea>
                                    </label>
                                    <label class="label"> <small>{this.state.charsLeft} characters more!</small></label>
                                </section>
                            </div>
                        </div>
                    </form>
                </article>
            </div>
        )
    }
}