import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'

export default class TerminalForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:0, serial:'', brand:'', model:'', imei:'', username:'', 
                createdby:this.auth.getUser(), status:'1', agent:null,
                createdon:new Date(), modifiedby:null, modifiedon:null,
                curuser:this.auth.getUser(), token:this.auth.getToken()
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let formItems = {
                id:0, serial:'', brand:'', model:'', imei:'', username:'', 
                createdby:this.auth.getUser(), status: '1', agent: null,
                createdon:new Date(), modifiedby:null, modifiedon:null,
                curuser:this.auth.getUser(), token:this.auth.getToken()
            }
            let rowData = nextProps.rowData
            formItems = (rowData.id !== undefined) ? rowData : formItems
            this.setState({ formItems })
        }
    }

    handleChange = (e) =>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/terminal'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnTerminalcancel').click()
                $('#btnTerminalDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade" id="terminalform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">New Terminal</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmTerminal" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Serial No.: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" name="serial" placeholder="Terminal Serial No." required  
                                                value={this.state.formItems.serial} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Brand: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" name="brand" placeholder="Brand"   
                                                value={this.state.formItems.brand} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Model: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" name="model" placeholder="Model"   
                                                value={this.state.formItems.model} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">IMEI: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" name="imei" placeholder="IMEI"   
                                                value={this.state.formItems.imei} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnTerminalcancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmTerminal" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}