import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import ProfileList from './ProfileList';
import UserTypeForm from './UserTypeForm';
import $ from 'jquery'
import Axios from 'axios';

export default class ProfileDetails extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            user: this.props.user,
            profileRoles: [],
            usertype: {},
            utype:'1',
            usertill:[]
        }
    }

    componentDidMount(){
        this.getProfileRoles()
        this.getUserTill()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.user !== this.props.user){
            let user = nextProps.user

            this.setState({ user })

            this.getProfileRoles()
            this.getUserTill()
        }
    }

    getUserType = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertype/' + this.state.user.username)
        .then(res => {
            let usertype = this.auth.decryptData(res.data)
            
            this.setState({ usertype })
        })//.catch((error)=>{alert(error)})
    }

    getUserTill = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertill/' + this.state.user.username)
        .then(res => {
            let usertill = this.auth.decryptData(res.data)
            
            this.setState({ usertill })
        })//.catch((error)=>{alert(error)})
    }

    getProfileRoles = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/role')
        .then(res => {
            let profileRoles = this.auth.decryptData(res.data)
            let user =this.state.user
            
            let rolename = profileRoles.filter(prole => user.auth==prole.id)
            user['rolename'] = (rolename[0] !== undefined) ? rolename[0].rolename : user.auth
            
            this.setState({ profileRoles, user })
            
            this.getUserType()
        })//.catch((error)=>{alert(error)})
    }

    enableUserType = (utype) => {
        this.setState({ utype })
    }

    changeStatus = () => {
        let {user} = this.state;

        user.status = (user.status >0)?0:1;
        let formData = this.auth.encryptData(JSON.stringify(user))
        Axios(this.config.PostOptions(formData, 'admin/wapp/user'))
        .then((res) => {
            alert('Profile status updated successfully...');

            this.componentDidMount();
        }).catch(error => {alert(error)});
    }

    goBack = () => {
        ReactDOM.render(
            <ProfileList role={this.props.role} />,
            document.getElementById('profileCon')
        )
    }

    render(){
        let curuser = this.state.user
        let online = (isNaN(curuser.token)) ? 'bg-color-green' : 'bg-color-red';
        let onlinetxt = (isNaN(curuser.token)) ? 'Online' : 'Offline';
        let status = (curuser.status>0) ? 'bg-color-green' : 'bg-color-red';
        let statustxt = (curuser.status>0) ? 'Active' : 'Inactive';

        return(
            <div className="row">
                <article className="col-xs-12 col-sm-5 col-md-2 pull-right">
                    <div className="btn-group btn-group-sm btn-group-vertical">
                        <button id="btnProfileBack" className="btn btn-warning" onClick={this.goBack}>
                            <i className="fa fa-lg fa-backward"></i> &nbsp; Go Back
                        </button>
                        {(![1,2].includes(curuser.auth) && (this.state.usertype.username === undefined && curuser.auth<6)) && 
                        <div className="btn-group btn-group-sm btn-group-vertical">
                        <button onClick={() => this.enableUserType('1')} className="btn btn-default" data-toggle="modal" data-target="#usertypeform">
                            Enable Operator
                        </button>
                        <button onClick={() => this.enableUserType('2')} className="btn btn-default" data-toggle="modal" data-target="#usertypeform">
                            Enable Agent
                        </button>
                        <button onClick={() => this.enableUserType('3')} className="btn btn-default" data-toggle="modal" data-target="#usertypeform">
                            Enable Retailer
                        </button>
                        <button onClick={() => this.enableUserType('4')} className="btn btn-default" data-toggle="modal" data-target="#usertypeform">
                            Enable Client
                        </button>
                        </div>}
                    </div>
                </article>
                <article className="col-xs-12 col-sm-7 col-md-10">
                    <div className="row">
                        <div className="well well-sm">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="well well-light well-sm no-margin no-padding">
            
                                        <div className="row">
            
                                            <div className="col-sm-12">
                                                <div id="myCarousel" className="carousel fade profile-carousel">
                                                    <div className="air air-bottom-right padding-10">
                                                        <button className="btn txt-color-white bg-color-teal btn-sm"><i className="fa fa-message"></i> Send SMS</button>
                                                        &nbsp; <button className="btn txt-color-white bg-color-pinkDark btn-sm"><i className="fa fa-mail"></i> Send Email</button>
                                                    </div>
                                                    <div className="air air-top-left padding-10">
                                                        <h4 className="txt-color-white font-md">&nbsp;</h4>
                                                    </div>
                                                    <ol className="carousel-indicators">
                                                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                                        <li data-target="#myCarousel" data-slide-to="1" className=""></li>
                                                        <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                                    </ol>
                                                    <div className="carousel-inner">
                                                        <div className="item active"><img src="assets/img/s1.jpg" alt="" /></div>
                                                        <div className="item"><img src="assets/img/s2.jpg" alt="" /></div>
                                                        <div className="item"><img src="assets/img/m3.jpg" alt="" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-sm-3 profile-pic">
                                                    <img src={this.config.ImagePath('custfiles') + '/' + curuser.username + '.' + curuser.filex} alt={curuser.username} style={{width:'90px',height:'120px',border:'1px solid gray'}} />
                                                        <div className="padding-10">
                                                            {curuser.auth === 3 && <h4 className="font-md"><strong>0</strong><br />
                                                            <small>Agents</small></h4>}<br />
                                                            {this.state.usertype.username !== undefined && [3,4].includes(curuser.auth) && 
                                                            <h4 className="font-md"><strong>0</strong><br />
                                                            <small>Retalers</small></h4>}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h1>{curuser.firstname} {curuser.middlename} <span className="semi-bold">{curuser.lastname}</span><br />
                                                        <small> {curuser.rolename} </small> 
                                                            <span className={'badge bounceIn animated ' + online}> {onlinetxt} </span>
                                                            <span className={'badge bounceIn animated ' + status} style={{cursor:'pointer'}} onClick={this.changeStatus} title="Click to change status..."> {statustxt} </span>
                                                        </h1>

                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <p className="text-muted">
                                                                    <i className="fa fa-phone"></i>&nbsp;&nbsp;<span className="txt-color-darken">{curuser.mobile}</span>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p className="text-muted">
                                                                    <i className="fa fa-envelope"></i>&nbsp;&nbsp;<a href={'mailto:' + curuser.email}>{curuser.email}</a>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                        <br />

                                                    </div>
                                                    {this.state.usertype.username !== undefined && this.state.usertill[0] !== undefined &&
                                                    <div className="col-sm-3"><br />
                                                        <div className="table-bordered padding-5">
                                                            <div><span className="txt-color-darken">Total Unit: </span>
                                                            <b>{this.state.usertill[0].closeBal}</b></div>
                                                            <div><span className="txt-color-darken">Total Cash: </span>
                                                            <b>{this.state.usertill[1].closeBal}</b></div>
                                                        </div>
                                                    </div>}
                                                    <UserTypeForm usertype={this.state.utype} user={this.state.user} /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}