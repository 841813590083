import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import TerminalForm from './TerminalForm';
import TerminalAssignForm from './TerminalAssignForm';
import AgentTerminalAssignForm from './AgentTerminalAssignForm'

export default class TerminaList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            terminalStatus:[],
            curStatus: -1,
            terminals: [],
            reportData: [],
            rowData: {},
            loader: false
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        this.getListItems()
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.role !== undefined && nextProps.role !== this.props.role){
          this.setState({ loader: true })
          let filterItems = this.state.filterItems
          filterItems['role'] = nextProps.role
          this.setState({ filterItems })

          this.getListItems()
      }
    }

    getListItems = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/terminal_status')
        .then(res => {
            let terminalStatus = this.auth.decryptData(res.data)

            this.setState({ terminalStatus })

            this.getReportData()
        })
    }

    getReportData = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/terminal')
        .then(res => {
            let terminals = this.auth.decryptData(res.data)
            
            this.setState({ terminals, reportData: terminals, loader: false })
        })
    }

    setRowData = (rowData) => {
        this.setState({ rowData })
    }

    nameFormat = (cell, row) => {
        return(<span>{cell}</span>)
    }
    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            {[1, 3].includes(parseInt(row.status)) &&
            <button className="btn btn-default" title="Assign Terminal" data-toggle="modal" data-target="#assignterminalform" onClick={() => this.setRowData(row)}>
                <i className="fa fa-unlink fa-lg text-danger"></i>
            </button>}
            {[0, 2].includes(parseInt(row.status)) &&
            <button className="btn btn-default" title="Unassign Terminal" onClick={() => this.unassignTerminal(row, '3')}>
                <i className="fa fa-link fa-lg text-success"></i>
            </button>}
            {parseInt(row.status) == 2 && 
            <button className="btn btn-default" title="Disable Terminal" onClick={() => this.unassignTerminal(row, '0')}>
                <i className="fa fa-unlock fa-lg text-info"></i>
            </button>}
            {parseInt(row.status) == 0 && 
            <button className="btn btn-default" title="Enable Terminal" onClick={() => this.unassignTerminal(row, '2')}>
                <i className="fa fa-lock fa-lg text-warning"></i>
            </button>}
            <button className="btn btn-default" title="Edit Terminal" data-toggle="modal" data-target="#terminalform" onClick={() => this.setRowData(row)}>
                <i className="fa fa-edit fa-lg text-primary"></i>
            </button>
        </div>)
    }
    dateFormat = (cell, row) => {
        if(cell !== '' && cell !== undefined){
        return <span>{moment(cell).format('DD MMM, YYYY')}</span>
        }else{return <span> - </span>}
    }

    unassignTerminal = (rowData, status) => {
        if(parseInt(status) == 3){
            rowData['username'] = ''
            rowData['modifiedby'] = ''
            rowData['modifiedon'] = null
        }
        
        rowData['status'] = status
        rowData['curuser'] = this.auth.getUser()

        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'admin/wapp/terminal'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnTerminalDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    handleChange = (e) =>{
        let curStatus = this.state.curStatus
        curStatus = e.target.value
        
        this.setState({ curStatus })
    }

    handleDisplay = () => {
        let reportData = this.state.terminals
        let status = parseInt(this.state.curStatus)
        
        if(status >= 0){
            reportData = reportData.filter(rdata => parseInt(rdata.status) == status)
        }

        this.setState({ reportData })
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Status: </button>
                            <select name="curStatus" className="btn btn-default" style={{width:'150px',height:'30px'}}
                                value={this.state.curStatus} onChange={this.handleChange}>
                                <option value="-1">All</option>
                                {this.state.terminalStatus.map(tstatus => (
                                    <option value={tstatus.ivalue}>{tstatus.iname}</option>
                                ))}
                            </select>
                            <button id="btnTerminalDisplay" className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-info" data-toggle="modal" data-target="#terminalform" onClick={() => this.setRowData('{}')}>
                                <i className="glyphicon glyphicon-plus"></i> Add Terminal
                            </button>
                            <button className="btn btn-default" data-toggle="modal" data-target="#agentterminalform" onClick={() => this.setRowData('{}')}>
                                <i className="glyphicon glyphicon-link"></i> Assign Agent
                            </button>
                        </div>
                        
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='createdon' dataSort dataFormat={this.dateFormat} width="100px">Reg Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='imei' isKey dataSort width="150px">IMEI</TableHeaderColumn>
                            <TableHeaderColumn dataField='serial' dataSort >Serial No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='agent' dataSort  width="100px">Agent</TableHeaderColumn>
                            <TableHeaderColumn dataField='username' dataSort  width="100px">Writer</TableHeaderColumn>
                            <TableHeaderColumn dataField='modifiedby' dataSort  width="120px">Assigned By</TableHeaderColumn>
                            <TableHeaderColumn dataField='modifiedon' dataSort dataFormat={this.dateFormat}  width="120px">Assigned On</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataSort dataFormat={this.actionFormat}  width="120px">Action</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <TerminalForm rowData={this.state.rowData} />
                    <TerminalAssignForm rowData={this.state.rowData} terminals={this.state.terminals} />
                    <AgentTerminalAssignForm terminals={this.state.terminals} />
                </div>
            )
        }
    }
}