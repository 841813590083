import React, { Component } from 'react'
import BetSetupList from './BetSetupList'

export default class BetSetup extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gamepad"></i> </span>
                            <h2><strong>Bet</strong> <i>Setups</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="betSetupCon" className="widget-body">
                                <BetSetupList />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}