import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import Menu from '../common/Menu'
import ProfileList from './ProfileList'

export default class ProfileRoleForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.menu = new Menu()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            menus: this.menu,
            role: 0,
            isEdit: false,
            formItems: {
                id:0, rolename:'', features:{}, enckey:'', createdby:this.auth.getUser(),
                createdon: new Date(), modifiedby:null, modifiedon:null, 
                username:this.auth.getUser(), token:this.auth.getToken()
            },
            rowData:this.props.rowData
        }
    }

    componentDidMount(){
        if(this.props.rowData !== undefined){
            let formItems = this.props.rowData
            formItems['curuser'] = this.auth.getUser()
            formItems['modifiedby'] = this.auth.getUser()
            formItems['modifiedon'] = new Date()
            formItems['features'] = (formItems.features!=="")? JSON.parse(formItems.features) : {}

            this.setState({formItems})
        }
    }

    componentDidUpdate(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let formItems = nextProps.rowData
            formItems['curuser'] = this.auth.getUser()
            formItems['modifiedby'] = this.auth.getUser()
            formItems['modifiedon'] = new Date()
            formItems['features'] = (formItems.features!=="")? JSON.parse(formItems.features) : {}

            this.setState({formItems})
        }
    }

    getRoleData = (auth) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/role/'  + auth)
        .then(res => {
            if(isNaN(res.data)){
                let formItems = this.auth.decryptData(res.data)
                this.setState({ formItems })
            }else{
                this.auth.doAlert(parseInt(res.data)) 
            }
        })
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        
        if(isNaN(e.target.name) && e.target.name!=='A' && e.target.name!=='B'){
            formItems[e.target.name] = e.target.value
        }else{
            if(formItems.features[e.target.name] === undefined){
                formItems.features[e.target.name] = []
            }

            let index = formItems.features[e.target.name].findIndex(x=>x==e.target.value)

            if(index < 0){
                formItems.features[e.target.name].push(e.target.value)
            }else{
                formItems.features[e.target.name].splice(index,1)
                if(formItems.features[e.target.name].length < 1){
                    delete formItems.features[e.target.name]
                }
            }
        }
        
        this.setState({formItems})
    }

    goBack = () => {
        ReactDOM.render(
            <ProfileList role={this.state.role} />,
            document.getElementById('profileCon')
        )
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formData = this.auth.encryptData(JSON.stringify(this.state.formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/role'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.goBack()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        let fitm = this.state.formItems.features
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button className="btn btn-warning" onClick={this.goBack}>
                            <i className="fa fa-lg fa-backward"></i> Go Back
                        </button>
                        <button type="submit" className="btn btn-success" form="frmRole">
                            <i className="glyphicon glyphicon-save"></i> Save Role
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10 col-md-8 col-lg-6 clearfix">
                    <form id="frmRole" className="smart-form" novalidate="novalidate" onSubmit={this.formSubmit}>
                        <div className="row">
                            <section className="col col-6">
                                <label className="input"> <i className="icon-prepend fa fa-user"></i>
                                    <input type="text" name="rolename" placeholder="Profile Role Name" 
                                    value={this.state.formItems.rolename} onChange={this.handleChange}/>
                                </label>
                            </section>
                        </div>
                    </form>
                </article>
                <article className="col-xs-12">&nbsp;</article>
                {this.state.menus.map(mn => (
                <article className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                    <div class="jarviswidget" data-widget-editbutton="false" data-widget-custombutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-lg fa-square-o"></i> </span>
                            <h2>{mn.name} </h2>
                        </header>
                        <div>
                            <div class="widget-body">
                                <form id="frmFeatures" className="smart-form" novalidate="novalidate" onSubmit="return false">
                                {mn.submenu !== undefined ? 
                                <section>
                                    {mn.submenu.map(smenu => {
                                        let ischecked = (fitm[mn.code] !== undefined && fitm[mn.code].includes(smenu.code))

                                        return(<label class="checkbox">
                                        <input type="checkbox" name={mn.code} value={smenu.code} checked={ischecked} 
                                        onChange={this.handleChange} />
                                        <i></i>{smenu.name}</label>)
                                    })}
                                </section>
                                :<section>
                                    <label class="checkbox">
                                    <input type="checkbox" name="terms" id="terms" checked='true' />
                                    <i></i>{mn.name}</label>
                                </section>}
                                </form>
                            </div>
                        </div>
                    </div>
                </article>))}
            </div>
        )
    }
}