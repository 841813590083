import React, { Component } from 'react'
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import * as jsPDF from 'jspdf'
import axios from 'axios'
import Auth from '../../common/Auth'
import Config from '../../Config'
import base64Img from 'base64-img'
import moment from 'moment'
import AgentLists from '../AgentLists';
import AgentPerformanceReport from '../AgentPerformanceReport'
import _ from 'lodash'

const colstyle = {
    fontSize:'30px',
    display:'none'
}

const curformat = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 2, minimumFractionDigits: 2
});
const numformat = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 0, minimumFractionDigits: 0
});

let images={}

function showReport(img, filter, comp){
    
    const pdf = new jsPDF("l", "mm", "a4")

    pdf.setProperties({
        title: 'AGENT PERFORMANCE for ' + filter.username,
        subject: 'AGENT PERFORMANCE for ' + filter.username,
        author: comp.companyName,
        keywords: 'draw, screen, alpha, lotto',
        creator: 'Mojave Consultancy'
    })
    
    
    pdf.autoTable({
        html: '#tbCompany', 
        theme: 'plain',
        styles: {fontSize: 16, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0 :{cellWidth:10}, 
            1: {halign: 'center'}, 
            2: {halign:'right', valign:'middle', cellWidth:30, fontSize:8, fontStyle:'normal'}
        }
    })
    
    if(img !='' && img !=undefined){
        let tp = 'PNG'
           if(img.indexOf('jpeg') != -1 && img.indexOf('jpg') != -1){
               tp = 'JPEG'
           }
           pdf.addImage(img,tp,15,10,0,23)
        }
    pdf.line(14, 40, 260, 40)
    let y=42;

    pdf.autoTable({
        html: '#tb_performance_total', 
        theme: 'striped',
        styles: {fontStyle:'bold'},
        columnStyles: {
            0: {cellWidth:30, halign:'right'}, 1: {cellWidth:60},
            2: {cellWidth:40, halign:'right'}, 3: {cellWidth:50}
        }
    });

    pdf.autoTable({
        html: '#tb_performance', 
        theme: 'striped',
        //styles: {fontSize: 18, fontStyle:'bold', overflow: 'linebreak'},
        columnStyles: {
            0: {cellWidth:10}, 1: {cellWidth:100}, 
            2: {cellWidth:50}, 3: {cellWidth:40}, 4: {cellWidth:30, halign:'right'}, 
            5: {cellWidth:40, halign:'right'}
        }
    });
    

    var data = pdf.output('datauristring')
    document.getElementById('pdfPreview').src = data
}

function exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    filename = filename?filename+'.xls':'excel_data.xls';
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
    }
}
function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], {type: "text/csv"});
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
function exportTableToCsv(tableID, filename = ''){
        var csv = [];
        var rows = document.querySelectorAll("table tr");
        
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            
            for (var j = 0; j < cols.length; j++) 
                row.push(cols[j].innerText);
            
            csv.push(row.join(","));		
        }
        download_csv(csv.join("\n"), filename);
}

export default class AgentPerformancePdf extends Component {
    constructor(props) {
        super(props)

        this.config = new Config() 
        this.auth = new Auth()
        this.$http = this.config.DConnect()

        this.state = {
            filter: this.props.filter,
            reportData: this.props.reportData, 
            companySetup: '',
            retailerTotal: this.props.retailerTotal
        }
    }

    componentDidMount() {
        this.getCompanySetup()
    }

    componentDidUpdate(nextProps){
        if(nextProps.reportData !== this.props.reportData){
            let reportData = nextProps.reportData,
                filter = nextProps.filter,
                retailerTotal = nextProps.retailerTotal;

            this.setState({ reportData, filter, retailerTotal });

            this.getCompanySetup();
        }
    }

    goBack = () => {
        ReactDOM.render(
            <AgentPerformanceReport filter={this.state.filter} />, 
            document.getElementById('generalReport_inners')
        )
    }

    
    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })

            //reportData = this.state.reportData
            let operator=this.state.operator
            let agents=this.state.agents
            /*agents.map(agt=>{
                let aurl='admin/wapp/image/custfiles/' +agt.username + '.'+ agt.filex*/
                let url = 'admin/wapp/image/custfiles/' + companySetup.logo

                /*this.$http.get(aurl, {responseType: 'arraybuffer'})
                .then((res) => {
                    let aimg = new Buffer(res.data, 'binary').toString('base64')
                    images[agt.username.replace(/ /g, '_')] = aimg

                    if(agents[agents.length - 1].username == agt.username){*/
                        this.$http.get(url, {responseType: 'arraybuffer'})
                        .then((res) => {
                            let img = new Buffer(res.data, 'binary').toString('base64')
                            showReport(img, this.state.filter, companySetup)
                        }).catch((error)=>{showReport('', this.state.filter, companySetup)})
                    /*}
                })
            })*/

            
        }).catch((error)=>{alert(error)})
    }
    exportTableToExcels=()=>{
        
        exportTableToExcel('datas', 'Agent-List')
    }
    exportTableToCsv=()=>{
        exportTableToCsv('datas', 'Agent-List')
    }

    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Agent Performance for {this.state.filter.username}</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-10">
                                        <iframe id="pdfPreview" type="application/pdf" style={{ 'width': '100%', 'height': '550px', 'border': 'none' }}></iframe>
                                    </div>
                                    <div className="col-xs-12 col-sm-2">
                                        <div className="btn-group btn-group-vertical btn-group-sm">
                                            <button className="btn btn-warning" onClick={this.goBack}> 
                                                <i className="fa fa-backward"></i> Back
                                            </button>
                                            <button className="btn btn-success" onClick={this.exportTableToExcels}> <i className="fa fa-file-excel-o"></i> Export Excel</button>
                                            <button className="btn btn-success" onClick={this.exportTableToCsv}> <i className="fa fa-file-archive-o"></i> Export CSV</button>
                                       </div>
                                    </div>
                                    <div className="col-xs-12" id="datas">
                                        <table id="tbCompany" className="table table-condensed" style={colstyle}>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="3">&nbsp;<img src={this.config.BaseURL()+this.state.url} /></td>
                                                    <td>{this.state.companySetup.companyName}</td>
                                                    <td rowSpan="3">
                                                    {this.state.companySetup.address} <br />
                                                    TEL: {this.state.companySetup.phone} <br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>AGENT PERFORMANCE <br />
                                                    {`${this.props.formatAgent(this.state.filter.username)} (${this.state.filter.username})`}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tb_performance_total" className="table table-condensed table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>Total Sales: </td>
                                                    <td><b>{curformat.format(_.sumBy(this.state.reportData,'amount'))}</b></td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Retailers: </td><td width="200px"><b>{numformat.format(this.state.retailerTotal.total)}</b></td>
                                                    <td>Active Retailers: </td><td width="200px"><b>{numformat.format(this.state.retailerTotal.active)}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Period: </td><td><b>{moment(this.state.filter.startDate).format('DD MMM, YYYY')} to {moment(this.state.filter.endDate).format('DD MMM, YYYY')}</b></td>
                                                    <td >Inactive Retailers: </td><td><b>{numformat.format(this.state.retailerTotal.inactive)}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table id="tb_performance" className="table table-condensed table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Retailer</th>
                                                    <th>Last Transaction</th>
                                                    <th>Terminal ID</th>
                                                    <th>No. of Bets</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.map((rdata, idx) =>
                                                <tr>
                                                    <td>{idx+1}</td>
                                                    <td>({rdata.retailer}) {this.props.formatRetailer(rdata.retailer, rdata)}</td>
                                                    <td>{this.props.dateFormat(rdata.lastran, rdata)}</td>
                                                    <td>{rdata.terminal}</td>
                                                    <td>{numformat.format(rdata.bets)}</td>
                                                    <td>{curformat.format(rdata.amount)}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }


}