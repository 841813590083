import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import DrawDataForm from './DrawDataForm';
import DrawDataConfirmApprove from './DrawDataConfirmApprove';

export default class DrawEntryList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            rowData: {},
            games: [],
            game: {},
            loader: false,
            isApprover: false
        }
    }

    componentDidMount(){
        this.setState({ loader: true })
        this.getGames()
        this.getApprovers()
    }

    componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){
            this.setState({ loader: true })
            this.getGames()
            this.getApprovers()
        }
    }

    getReportData = (gamecode) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/closedgames/' + gamecode)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)
            //let reportData = resdata.filter(rdata => rdata.drawNumber !== '')
            //alert(JSON.stringify(reportData))
            this.setState({ reportData, loader:false })
        }).catch((error)=>{alert(error); this.setState({loader:false})})
    }

    getApprovers = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/approvers/1')
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let approver = resdata.filter(rdata => rdata.approversPK.username == this.auth.getUser())
            
            let isApprover = (approver[0] !== undefined)

            this.setState({ isApprover })
        })
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)
            let game = (games[0] !== undefined) ? games[0] : {}
            let gamecode = (game.id !== undefined) ? game.id : 0

            this.setState({ games, game })

            this.getReportData(gamecode)
        })
    }

    dateFormat = (cell, row) => {
        return moment(cell).format('DD MMM, YYYY @ hh:mma')
    }

    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            {((row.drawNumbers == '' || row.machineNumbers == '') && row.status != 4) ?
            <button className="btn btn-default" title="Add Draw" data-toggle="modal" data-target="#drawdataform"
            onClick={() => this.setState({rowData: row})} >
                <i className="glyphicon glyphicon-plus"></i> Add Draw Data
            </button>
            : row.status != 4 && row.drawNumbers != '' && row.machineNumber != '' && 
            <button className="btn btn-default" title="Edit Draw" data-toggle="modal" data-target="#drawdataform"
            onClick={() => this.setState({rowData: row})}>
                <i className="glyphicon glyphicon-edit"></i> Edit
            </button>}
            {row.status != 4 && row.drawNumbers != '' && row.machineNumber != '' && this.state.isApprover &&
            <button className="btn btn-default" title="Approve Draw" data-toggle="modal" data-target="#approveconfirm"
            onClick={() => this.setState({rowData: row})}>
                <i className="glyphicon glyphicon-check"></i> Approve
            </button>}
        </div>)
    }

    setRowData = (rowData) => {
        this.setState({ rowData })
    }

    handleDisplay = () => {
        this.setState({ loader: true })
        this.getReportData(this.state.game.id)
    }

    handleChange = (e) => {
        let game = this.state.game
        let selgame = this.state.games.filter(gm => gm.id == e.target.value)
        if(selgame[0] !== undefined){
            game = selgame[0]
        }

        this.setState({ game })
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Game: </button>
                            <select name="game" className="btn btn-default" style={{width:'150px', height:'30px'}}
                                value={this.state.game.id} onChange={this.handleChange}>
                                {this.state.games.map(game => (
                                    <option value={game.id}>{game.gamename}</option>
                                ))}
                            </select>
                            <button id="btnDrawDataDisplay" className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                        </div>              
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='drawno' isKey dataSort >Draw No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='drawDate' dataSort dataFormat={this.dateFormat}>Draw Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='drawNumbers' dataSort >Draw Nos.</TableHeaderColumn>
                            <TableHeaderColumn dataField='machineNumbers' dataSort >Machine Nos.</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <DrawDataForm rowData={this.state.rowData} games={this.state.games}  />
                    <DrawDataConfirmApprove rowData={this.state.rowData} />
                </div>
            )
        }
    }
}