import React, { Component } from 'react';
import ProfileList from './ProfileList';

export default class UserProfiles extends Component{
    constructor(){
        super()

        this.state = {
            role: 0
        }
    }

    componentDidUpdate(nextProps) {
      if(nextProps.role !== this.state.role){
          this.setState({ role: nextProps.role })
      }
    }
    
    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gear"></i> </span>
                            <h2><strong>User</strong> <i>Profiles</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="profileCon" className="widget-body">
                                <ProfileList role={this.state.role} />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}