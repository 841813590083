import React, { Component } from "react"

export default class UnitTransfer extends Component {
  constructor() {
    super();
    this.state = {
      
    }
  }

  componentDidMount() {}
  render() {
    return (
      <div className="tab-pane fade in padding-10 no-padding-bottom" id="s2">
      
      </div>
    );
  }
}
