import React, { Component } from 'react';
import TransactionForm from './TransactionForm'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import QueryJson from '../common/QueryJson'
import OperatorForm from './OperatorForm'
import axios from 'axios'
import _ from 'lodash'

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

export default class OperatorTransList extends Component {
    constructor() {
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        this.state = {
            formItems: { username: '', agent: '', retailer: '' ,startDate: moment(),endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken()},
            batchData: [], operators: [], agents: [], goperator: '', retailer: [],batch: 1,

            startDate: moment(),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            loader: false
        }
    }

    componentDidMount() {
        let systemDefine = (isNaN(this.auth.getUser())) ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        this.getUserTypes(systemDefine)
    }

    getJournaldata = () => {
        this.setState({ loader: true })
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'transaction/wapp/journal'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = this.auth.decryptData(res.data)

                axios(this.config.PostOptions(formData, 'transaction/wapp/tilltrans/operator'))
                .then((res) => {
                    if(isNaN(res.data)){
                        let rdata = this.auth.decryptData(res.data)

                        //alert(JSON.stringify(rdata))
                        let journaldata = resdata.concat(rdata);
                        journaldata = _.orderBy(journaldata, 'createdon', 'desc');
                        this.setState({ journaldata, loader: false });
                    }else{
                        this.auth.doAlert(parseInt(res.data));
                        this.setState({ loader: false });
                    }
                })
            }else{
                this.auth.doAlert(parseInt(res.data));
                this.setState({ loader: false });
            }
        })
         
     }

    getUserTypes = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res => {
                if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let formItems = this.state.formItems
                    formItems['username'] = (operators[0] != undefined) ? operators[0].username : operators.username
                    window.operator=formItems.username
                    this.getAgents(formItems.username)
                    this.setState({ formItems, operators })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }

            })
        }
    }
    
    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        window.operator=ptype
        this.getAgents(ptype)
        this.setState({ formItems: fitm })
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems

        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/' + operator + '/2')
            .then(res => {
                if(isNaN(res.data)){
                let agents = this.auth.decryptData(res.data)
                this.setState({ agents })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    dateFormat = (cell, row) => (moment(cell).format("DD MMM, YYYY,h:mm a"))
    amountFormat = (cell, row) => amountformat.format(cell)

    usertypeFormat = (cell, row) => {
        let usertill = new QueryJson(this.state.userType, 'username=' + cell)
        let name = (usertill[0] != undefined) ? (usertill[0].usertype==1)?usertill[0].fullname:" ": cell
        return ( name !=undefined)?name:""
    }
    
    agentFormat = (cell, row) => {
         let name =this.state.agents.filter(td=>td.username==cell)
        return (name!='')?name[0].fullname:cell
    }
   
    openBalanceFormat = (cell, row) => {
         let usertill = this.state.usertill.filter(td=>td.username==cell)
         return amountformat.format((usertill[0].openBal!=undefined)?usertill[0].openBal:0.00)
    }
   closeBalanceFormat = (cell, row) => {
        let usertill =this.state.usertill.filter(td=>td.username==cell)
        return amountformat.format((usertill[0].closeBal!=undefined)?usertill[0].closeBal:0.00)
    }

    loading = () => <div id="loader"></div>

    render() {
        if(this.state.loader){
            return this.loading();
        }else{
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {
                label = start;
            }
            return (
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="col-xs-12 btn-group btn-group-sm">
                            <button className="btn btn-default">DateRange: </button>
                            <DatetimeRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                ranges={this.state.ranges}
                                onEvent={this.handleEvent}
                                className="selected-date-range-btn btn btn-default">
                                    <div className="pull-left">
                                        <i className="fa fa-calendar" />&nbsp; &nbsp; 
                                        <span>{label}</span>
                                    </div>
                                    <div className="pull-right">
                                        &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                    </div>
                            </DatetimeRangePicker>
                            <button className="btn btn-default">Operators: </button>
                            <select id="username" name="username" onChange={this.handleChange} value={this.state.formItems.username} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                {this.state.operators.map(opt =>
                                    <option value={opt.username}>{opt.fullname}</option>
                                )}
                            </select>
                            <button onClick={this.getJournaldata} className="btn btn-info">Display</button>
                            <div className="btn btn-sm pull-right" >
                                            <button className="btn btn-success" data-toggle="modal" data-target="#operatorModal">Operator Transfer</button>
                                </div>
                        </div>
                        
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-12 ">
                        <BootstrapTable data={this.state.journaldata} striped hover condensed pagination search>
                            <TableHeaderColumn dataField='refno' width="140px" isKey dataSort >Reference No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='username' width="140px" dataSort >Agent/Retailer</TableHeaderColumn>
                            <TableHeaderColumn dataField='notes' dataSort>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='openBal' width="100px" dataAlign="right"  dataSort>Open Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='amount' dataAlign="right" width="100px" dataFormat={this.amountFormat} dataSort>Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField='closeBal' dataAlign="right"  width="100px" dataSort>Close Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='createdon' width="150px" dataFormat={this.dateFormat} dataSort>Created On</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    {/*<OperatorForm operator={this.state.operators} agent={this.state.agent} />*/}
                </div>
            )
        }
    }
}
