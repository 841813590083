import React, { Component } from 'react';
import WinningList from './WinningList'

export default class Wins extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>Run Draw</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="wins_inner" className="widget-body"><WinningList /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}