import React, {Component} from 'react';
import Pages from './Pages';

export default class Portal extends Component{
    constructor(){
        super();
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gamepad"></i> </span>
                            <h2><strong>Portal</strong> <i>Content Management System</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="portalCon" className="widget-body"><Pages /></div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}