import React from 'react';
import CommissionList from './CommissionList'

function Commission(){
    
    return(
        <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                        <h2><strong>Writers Sales Commission</strong></h2>
                    </header>
                    <div>
                        <div className="jarviswidget-editbox"></div>
                        <div id="commissionCon" className="widget-body"><CommissionList /></div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Commission;