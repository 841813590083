import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import moment from 'moment'
import ReactDOM from 'react-dom'
import SendNewSMS from './SendNewSMS'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import SMSNewTemplate from './SMSNewTemplate'

export default class SMSTemplate extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            smslists: [],
            scheduleTypes: []
        }

    }

    componentDidMount(){
        this.getSMSLogs()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.getSMSLogs()
        }
    }

    getSMSLogs = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/scheduleType')
        .then(res => {
            let scheduleTypes = this.auth.decryptData(res.data)

            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/smstemplate')
            .then(res => {
                let smslists = this.auth.decryptData(res.data)

                this.setState({ scheduleTypes, smslists })
            })
        })
    }

    deleteTemplate = (rowData) => {
        alert('Not activated...')
    }

    newTemplate = (rowData) => {
        ReactDOM.render(<SMSNewTemplate scheduleTypes={this.state.scheduleTypes} rowData={rowData} />, 
            document.getElementById('sms_templates'))
    }

    scheduleFormat = (cell, row) => {
        let stype = this.state.scheduleTypes.filter(us => parseInt(us.ivalue) == parseInt(cell))
        let stypename = (stype[0] === undefined) ? stype[0].iname : cell

        return stypename
    }
    

    actionFormat = (cell, row) => {
        return(
            <div className="btn-group btn-group-xs">
                <button className="btn btn-default" data-toggle="modal" data-target="#dropdownform" 
                onClick={() => this.newTemplate(row)}>
                    <i className="fa fa-edit"></i> Edit
                </button>
                <button className="btn btn-danger" onClick={() => this.deleteTemplate(row)}>
                    <i className="fa fa-trash-o"></i> Delete
                </button>
            </div>
        )
    }
    
    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-default" onClick={() => this.newTemplate('')}>
                            <i className="glyphicon glyphicon-plus"></i> New Template
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12">
                    <BootstrapTable data={this.state.smslists} striped hover condensed pagination>
                        <TableHeaderColumn dataField='sheduleType' width="100px" dataFormat={this.scheduleFormat} isKey dataSort>Schedule Type</TableHeaderColumn>
                        <TableHeaderColumn dataField='smsMessage' dataSort>Message</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataSort width="120px" dataFormat={this.actionFormat}></TableHeaderColumn>
                    </BootstrapTable>
                </article>
            </div>
        )
    }
}