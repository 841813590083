import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import moment from 'moment';
import WinReport from './WinReport';
import WinList from './WinList';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class WinningList extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            games: [],
            events: [],
            bets: [],
            formItems: {
                id:0, gamecode:0, drawno:0, screenNum:'', createdby: this.auth.getUser(),
                createdon: new Date(), curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            winsGenerated: true,
            winsPaid: true,
            loader: false
        }
    }

    componentDidMount(){
        this.setState({ loader:true })
        this.getGames()
    }

    componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){        
            this.getGames()
        }
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/betting')
        .then(res => {
            let bets = this.auth.decryptData(res.data)

            this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
            .then(res => {
                let games = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                if(games[0] !== undefined){
                    formItems['gamecode'] = games[0].id
                    this.getEvents(games[0].id, 0)
                }

                this.setState({ games, formItems, bets })
            })
        })
    }

    getEvents = (gamecode, drawno) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/approvedgames/' + gamecode)
        .then(res => {
            let events = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            let winsGenerated = this.state.winsGenerated
            let winsData = []

            //alert(JSON.stringify(events))
            if(events[0] !== undefined){
                let curEvent = (drawno == 0) ? events : events.filter(rdata => rdata.drawno == drawno)

                formItems['drawno'] = (drawno == 0) ? events[0].drawno :curEvent[0].drawno
                formItems['screenNum'] = curEvent[0].drawNumbers

                winsGenerated = curEvent[0].status != 5
            }

            this.setState({ events, formItems, winsGenerated, winsData, loader:false })
        }).catch((error)=>{alert(error); this.setState({loader:false})})
    }

    showWinReport = (rowData) => {
        ReactDOM.render(
            <WinReport rowData={rowData} formItems={this.state.formItems} 
            bets={this.state.bets} games={this.state.games} events={this.state.events} />, 
            document.getElementById('winingsCon')
        )
    }

    showWinDetails = (betcode) => {
        ReactDOM.render(
            <WinList formItems={this.state.formItems} bets={this.state.bets} betcode={betcode} />, 
            document.getElementById('winingsCon')
        )
    }

    handleDisplay = () => {
        let formItems = this.state.formItems

        if(formItems.drawno > 0){
            this.getEvents(formItems.gamecode, formItems.drawno)
        }else{
            alert('No Draw is ready for this Game')
        }
    }

    handleChange = (e) => {
        this.setState({ loader:true })
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
        
        if(e.target.name === 'gamecode'){
            this.getEvents(e.target.value, 0)
        }else if(e.target.name === 'drawno'){
            this.getEvents(formItems.gamecode, e.target.value)
        }
    }

    generateWins = () => {
        //this.setState({ loader:true })
        let formItems = {...this.state.formItems};
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'draw/wapp/generatewins'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = this.auth.decryptData(res.data)
                
                this.setState({winsGenerated: false, loader:false})
                this.showWinReport(resdata)
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error); this.setState({loader:false})})
    }

    loading = () => <div id="loader"></div>

    render() {
        if(this.state.loader){
            return this.loading();
        }else{
            return (
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm pull-left">
                            <button className="btn btn-default">Game: </button>
                            <select name="gamecode" className="btn btn-default" style={{width:'150px', height: '30px'}}
                                value={this.state.formItems.gamecode} onChange={this.handleChange}>
                                {this.state.games.map(game => (
                                    <option value={game.id}>{game.gamename}</option>
                                ))}
                            </select>
                            <button className="btn btn-default">Event / <i className="fa fa-calendar"></i>: </button>
                            <select name="drawno" className="btn btn-default" style={{width:'200px', height: '30px'}}
                                value={this.state.formItems.drawno} onChange={this.handleChange}>
                                {this.state.events.map(rdata => (
                                    <option value={rdata.drawno}>#{rdata.drawno} ({moment(rdata.drawDate).format('DD MMM, YYYY')})</option>
                                ))}
                            </select>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                            {this.state.events[0] !== undefined &&
                            <button id="btnGenerateWins" className="btn btn-danger" onClick={this.generateWins}>
                                Generate Wins
                            </button>}
                        </div>
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12" id="winingsCon"></div>
                </div>
            )
        }
    }
}