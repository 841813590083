import React, { Component } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import "react-datepicker/dist/react-datepicker.css";
import QueryJson from '../common/QueryJson'
import ReportView from './ReportView';
import _ from 'lodash'
import axios from 'axios'
import AgentSalePdf from './Pdf/AgentSalePdf';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class AgentSale extends Component {
    constructor() {
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        
        this.state = {
            operators:[], operator:{}, agents:[], retailers:[], game:[],
            formItems: { operator: '', username: '', agent: '', game: 0, startDate:'', 
            curuser: this.auth.getUser(), token: this.auth.getToken()}, 
            retailersale:{gamename: '', totalSales:0},
            reportData:[],
            totals:{totalGross:0, totalNet:0}
        }
    }

    componentDidMount(){
        this.setState({ loader:true })
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        //this.getReport()
        this.getOperators(systemDefine)
    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
                .then(res   => {
                    if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let operator = (operators[0] != undefined) ? operators[0].username : operators.username
                   
                    this.setState({ operator, operators })
                    this.getAgents(operator)
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }

                })
        }
    }
    
    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails_rep/' + operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data)
                let formItems=this.state.formItems
                //let agents = resdata.filter(rdata => rdata.usertype == 2)
                 formItems['agent'] = (agents[0] != undefined)?agents[0].username:""
                this.setState({ agents, loader: false ,formItems})
                this.getRetailer(formItems.agent)
            })
    }

    getRetailer = (agent) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/retailerdetails/' + agent)
        .then(res => {
            if (isNaN(res.data)) {
                let retailers = this.auth.decryptData(res.data)
                let formItems = this.state.formItems

                console.log(retailers);
                formItems['username'] = (retailers[0] != undefined)?retailers[0].username:''
                this.setState({ retailers, formItems })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    agentName=(username)=>{
        let agent = new QueryJson(this.state.agents, 'username=' + username)
        return (agent[0]!=undefined)?agent[0].fullname:""
    }

    retailerFomat=(cell, row)=>{
        let retailer = new QueryJson(this.state.retailers, 'username=' + cell)
        return (retailer[0]!=undefined)?cell + ' - ' + retailer[0].fullname: cell
    }

    getReport=()=>{
        let formItems = this.state.formItems

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'report/wapp/retailersale'))
        .then((res) => {
            if (isNaN(res.data)) {
                let retailersale = this.auth.decryptData(res.data)

                let totals = this.state.totals
                let reportData = retailersale.map(rsale => {
                    let commission = rsale.totalSales * .2
                    rsale['commission'] = commission
                    rsale['net'] = rsale.totalSales - commission

                    return rsale
                })

                totals['totalGross'] = _.reduce(reportData, function(total, o) { return total += o.totalSales}, 0)
                totals['totalNet'] = _.reduce(reportData, function(total, o) { return total += o.net}, 0)

                this.setState({ retailersale, reportData, totals })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>alert(error));
    }

    goBack = () => {
        ReactDOM.render(<ReportView  />, document.getElementById('generalReport_inners'))
    }
    
    curFormat = (cell, row) => numformat.format(cell)

    printPreview = () => {
        let formItems = this.state.formItems
        let agentsale = this.state.agentsale
        let totals = this.state.totals
        let reportData = this.state.reportData

        let data = {
            event: agentsale.drawno, 
            drawdate: agentsale.drawdate, gross: totals.totalGross, net: totals.totalNet,
            agent: this.agentName(formItems.username)
        }

        let rowData = reportData.map(rdata => {
            rdata['retailer'] = this.retailerFomat(rdata.retailer, rdata)
            rdata['gross'] = numformat.format(rdata.totalSales)
            rdata['commission'] = numformat.format(rdata.commission)
            rdata['net'] = numformat.format(rdata.net)

            return rdata
        })

        ReactDOM.render(
            <AgentSalePdf data={data}  rowData={rowData} />, document.getElementById('generalReport_inners')
        )
     }

     handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        this.setState({ formItems: fitm })
        if(e.target.name=="operator"){
            this.getAgents(e.target.value)
        }else if(e.target.name=="agent"){
            this.getRetailer(e.target.value)
        }
    }

      loading = () => <div id="loader"></div>

      render(){
         
          if(this.state.loader){
              return this.loading()
          }else{
            let asales = this.state.agentsale

        return(
            <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                        <h2><strong>Retailer Sales</strong> </h2>
                    </header>
                <div>
                <div className="row">
                    <div className="col-xs-12 ">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Operator: </button>
                            <select id="" name="operator" onChange={this.handleChange} value={this.state.formItems.operator} className="btn btn-default" style={{ width: '200px', height:'30px' }}>
                                {this.state.operators.map(agt =>
                                    <option value={agt.username}>{agt.fullname}</option>
                                )}
                            </select>

                            <button className="btn btn-default">Agent: </button>
                            <select id="agent" name="agent" onChange={this.handleChange} value={this.state.formItems.agent} className="btn btn-default" style={{ width: '150px', height:'30px' }}>
                                {this.state.agents.map(agt =>
                                    <option value={agt.username}>{agt.fullname}</option>
                                )}
                            </select>
                        </div>
                        
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-warning " id="back"  onClick={this.goBack}>Go Back</button>
                            <button className="btn btn-primary " onClick={this.printPreview}>Preview</button>
                        </div>
                        <hr className="hrsp" />
                    </div>
                    <div className="col-xs-12 ">
                        <div className="btn-group btn-group-sm ">
                        <button className="btn btn-default">Retailer: </button>
                            <select id="username" name="username" onChange={this.handleChange} value={this.state.formItems.username} className="btn btn-default" style={{ width: '150px', height:'30px' }}>
                                {this.state.retailers.map(retailer =>
                                    <option value={retailer.username}>{retailer.fullname}</option>
                                )}
                            </select>

                            <button className="btn btn-default">Draw Date: </button>
                            <input type="date" class="date" name="startDate" value={this.state.formItems.startDate} id="" className="btn btn-default" style={{ width: '200px', height:'30px' }} onChange={this.handleChange}/>
                             
                            <button onClick={this.getReport} className="btn btn-info">Display</button>
                        </div>
                    
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    
                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{padding:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                        {this.retailerFomat(this.state.formItems.username,[])} SALES REPORT 20%
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                        <table className="table table-condensed table-striped">
                            <tbody>
                                <tr>
                                    <td width="100px" className="text-right">&nbsp;</td>
                                    <td width="100px" className="text-right">Total Gross: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalGross)}</b></td>
                                </tr>
                                <tr>
                                    <td width="100px" className="text-right">Draw Date: </td><td width="150px"><b>{moment(this.state.formItems.startDate).format('DD MMM, YYYY')}</b></td>
                                    <td width="100px" className="text-right">Total Net: </td><td width="150px" ><b>{numformat.format(this.state.totals.totalNet)}</b></td>
                                </tr>
                            </tbody>
                        </table>
               
                    </div>
                        <div className="col-xs-12"><hr className="hrsp" /></div>
                        <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                            <BootstrapTable data={this.state.reportData} striped hover condensed pagination >
                                <TableHeaderColumn dataField='gamename' dataFormat={this.retailerFomat} isKey dataSort>GAME NAME</TableHeaderColumn>
                                <TableHeaderColumn dataField='totalSales' dataSort dataAlign="right" dataFormat={this.curFormat} width="120">GROSS</TableHeaderColumn>
                                <TableHeaderColumn dataField='commission' dataAlign="right" dataFormat={this.curFormat} dataSort width="120">COMMISSION</TableHeaderColumn>
                                <TableHeaderColumn dataField='net' dataAlign="right" dataFormat={this.curFormat} dataSort width="120">NET</TableHeaderColumn>
                            </BootstrapTable>
                        </div> 
                    </div>

                                </div>
                                </div>
                </article>
            </div>
        )
    }}
}
