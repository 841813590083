import React, { Component } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import "react-datepicker/dist/react-datepicker.css";
import ReportView from './ReportView';
import Axios from 'axios';
import WinsPayRetailerPdf from './Pdf/WinsPayRetailerPdf';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});
const curformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 0, minimumFractionDigits: 0});

export default class WinsPayRetailerReport extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        
        this.state = {
            operators:[], operator:{},
            formItems: { 
                operator: '', startDate:moment().startOf('month'), endDate:moment().endOf('month'), 
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }, 
            reportData:[],
            rate: 4, tbStruct:[],

            startDate: moment().startOf('month'),
            endDate: moment().endOf('month')
        }
    }

    componentDidMount(){
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        //this.getReport()
        this.getOperators(systemDefine)
    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res   => {
                if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let operator = (operators[0] != undefined) ? operators[0].username : operators.username
                   
                    this.setState({ operator, operators })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }

            })
        }
    }

    getReport = () => {
        this.setState({ loader: true });
        let {formItems} = this.state;
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        Axios(this.config.PostOptions(formData, 'report/wapp/agentcomission'))
        .then((res) => {
            if(isNaN(res.data)){
                let reportData = this.auth.decryptData(res.data);
        
                this.setState({ reportData, loader: false });

                this.getDatatable(reportData);
            }else{
                this.auth.doAlert(parseInt(res.data));
            }
        }).catch(error => {alert(error); this.setState({ loader: false })});
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        this.setState({ formItems: fitm })
    }

    handleEvent = (event, picker) => {
        //alert(JSON.stringify(event))
        let formItems=this.state.formItems
        formItems['startDate']=picker.startDate
        formItems['endDate']=picker.endDate
		this.setState({ startDate: picker.startDate, endDate: picker.endDate,formItems});
    }

    formatRate = (cell, row) => this.state.rate;
    formatAmount = (cell, row) => numformat.format(cell);
    formatCommission = (cell, row) => {
        let { rate } = this.state;
        return numformat.format((rate/100) * row.amount);
    }

    getDatatable = (reportData) => {
        let { rate } = this.state;
        let tbStruct = [], totalStake = _.sumBy(reportData, 'amount');;

        tbStruct.push(<tr><td>AGENT</td><td>PROCESSED BY</td><td>PROCESSED ON</td><td>TOTAL STAKES</td><td>RATE (%)</td><td>TOTAL COMMISSION</td></tr>);
        reportData.map(rdata => {
            tbStruct.push(<tr><td>{rdata.name}</td><td></td><td></td><td>{numformat.format(rdata.amount)}</td><td>5.00</td><td>{numformat.format(rdata.amount*rate/100)}</td></tr>);
        });
        tbStruct.push(<tr><td>Total</td><td>{curformat.format(reportData.length)}</td><td></td><td>{numformat.format(totalStake)}</td><td></td><td>{numformat.format(totalStake*rate/100)}</td></tr>);

        this.setState({ tbStruct })
    }

    doPreview=()=>{
        let { tbStruct } = this.state;
        ReactDOM.render(
            <WinsPayRetailerPdf rowData={tbStruct} data="" />,
            document.getElementById('generalReport_inners')
        )
    }
    
    goBack= () => {
        ReactDOM.render(
            <ReportView />,
            document.getElementById('generalReport_inners')
        )
    }

    loading = () => <div id="loader"></div>

    render(){
        if (this.state.loader) {
            return this.loading()
        } else {
            let {formItems, startDate, endDate, reportData, operators } = this.state;
            let ranges= {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            }
            let start = startDate.format('MMMM D, YYYY');
            let end = endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {label = start;}

            const footerData = [[ {label: 'Total: ', columnIndex: 0 },
                {label: 'Total Credit', columnIndex: 1,
                    formatter: (tableData) => {
                        let label = reportData.length;
                        return (label == 0) ? '' : curformat.format(label)
                }}, {label: 'Total Credit', columnIndex: 3, align: 'right',
                    formatter: (tableData) => {
                        let label = _.sumBy(reportData, 'amount');
                        return (label == 0) ? '' : numformat.format(label);
                }}, {label: 'Total Credit', columnIndex: 5, align: 'right',
                formatter: (tableData) => {
                    let label = _.sumBy(reportData, 'amount');
                    return (label == 0) ? '' : numformat.format(label*.05);
            }}]]

            return(
                <div className="row">
                    <article className="col-xs-12">
                        <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                            <header>
                                <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                                <h2><strong>Retailer Wins Payment Report</strong> </h2>
                            </header>
                            <div>
                                <div className="jarviswidget-editbox"></div>
                                <div id="winPayment_inner" className="widget-body">
                                    <div className="row">
                                        <div className="col-xs-12 ">
                                            <div className="btn-group btn-group-sm">

                                                <button className="btn btn-default">Payment period: </button>
                                                <DatetimeRangePicker
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    ranges={ranges}
                                                    onApply={this.handleEvent}
                                                    className="selected-date-range-btn btn btn-default">
                                                    <div className="pull-left">
                                                        <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                                                    </div>
                                                    <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                                                </DatetimeRangePicker>
                                                
                                                <button onClick={this.getReport} className="btn btn-info">Display</button>
                                            </div>

                                            <div className="btn-group btn-group-sm pull-right" >
                                                <button className="btn btn-warning " id="back" onClick={this.goBack}>Go Back</button>
                                                <button className="btn btn-primary " onClick={this.doPreview}>Preview</button>
                                            </div>
                                        </div>
                                        <div className="col-xs-12"><hr className="hrsp" /></div>
                                        <div className="col-xs-12 col-md-10 col-md-offset-1 bg-primary text-center" style={{paddingTop:"5px",fontSize:"18px",textTransform:"uppercase",marginBottom:'20px'}}>
                                            Retailer Wins Payment Report
                                        </div>
                                        <div className="col-xs-12 col-md-10 col-md-offset-1">
                                            <BootstrapTable data={reportData} striped hover condensed pagination footerData={footerData} footer>
                                                <TableHeaderColumn dataField='agent' isKey dataSort >Agent</TableHeaderColumn>
                                                <TableHeaderColumn dataField='paidby' dataSort width="150px" dataAlign='right'>Win Amount</TableHeaderColumn>
                                                <TableHeaderColumn dataField='paidon' dataSort width="150px" dataAlign='right'>Paid Amount</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div> 

                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            )
        }
    }
}