import React, {Component} from 'react';
import './App.css';
import flot from "flot";
import Auth from './common/Auth'
import TopBar from './common/TopBar';
import NavBar from './common/NavBar';
import Content from './common/Content';
import Login from './common/Login';
import AutoLogout from './common/AutoLogout';
import Footer from './common/Footer';
import Shortcut from './common/Shortcut';

class App extends Component{
  constructor(){
    super()

    this.auth = new Auth()

    this.state = {
      pagenum: 1
    }
  }

  navClickHandler = (pagenum) => {
    this.setState({ pagenum })
  }

  render(){
    if(this.auth.isAuth()){
      return (
        <div className="App" onContextMenu={''/*(e) => e.preventDefault()*/}>
          <AutoLogout />
          <TopBar />

          <NavBar navClickHandler={this.navClickHandler} />

          <Content pagenum={this.state.pagenum} />

          <Shortcut />

          <Footer />
        </div>
      );
    }else{
      return <Login />
    }
  }
  
}

export default App;
