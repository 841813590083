import React, {Component} from 'react'
import Auth from './Auth'
import Config from '../Config'
import EventListener, {withOptions} from 'react-event-listener';

var t;

export default class AutoLogout extends Component{
    constructor(){
        super()

        this.duration = 10
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.logout = this.logout.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
    }

    componentDidMount(){
        this.$http.get('admin/wapp/setup')
        .then(res => {
            const company = this.auth.decryptData(res.data)
            this.duration = company.autologout
        })

        this.resetTimer()

        window.addEventListener('click', this.resetTimer);
        window.addEventListener('scroll', this.resetTimer);
        window.addEventListener('mousemove', this.resetTimer);
        window.addEventListener('mousedown', this.resetTimer);
        window.addEventListener('keypress', this.resetTimer);
    }

    componentWillUnmount(){
        window.removeEventListener('click', this.resetTimer)
        window.removeEventListener('scroll', this.resetTimer)
        window.removeEventListener('mousemove', this.resetTimer);
        window.removeEventListener('mousedown', this.resetTimer);
        window.removeEventListener('keypress', this.resetTimer);
    }

    logout() {
        this.auth.logout()
        clearTimeout(t)
        window.location.reload()
    }
  
    resetTimer(e) {
        clearTimeout(t)
        t = setTimeout(this.logout, this.duration * 60000); // time is in milliseconds
    }

    render(){
        return null
    }
}