import React,{ Component } from "react"
import MyProfileDetails from "./MyProfileDetails";

export default class MyProfile extends Component{
    
    constructor(props){
        super(props)

    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gear"></i> </span>
                            <h2><strong>My</strong> <i>Profile</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="myProfileCon" className="widget-body">
                                <MyProfileDetails />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}