import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import Chance from 'chance'
import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
import DrawScreenPreview from './DrawScreenPreview';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class DrawScreeningForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            games: this.props.games,
            events: this.props.events,
            rowData: this.props.rowData,
            genNumbers: [],
            curNumbers: [],
            reportData: [],
            formItems: {
                id:0, gamecode:0, drawno:0, screenNum:'', createdby: this.auth.getUser(),
                createdon: new Date(), curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            screenTotals: {totalStakes: 0.00, totalWins:0.00, profitMargin:0.00},
            screenNum:{'snum0':'-', 'snum1':'-', 'snum2':'-', 'snum3':'-', 'snum4':'-'},
            isScreen: false
        }
    }

    componentDidMount(){
        this.getGames()
        this.handleDisplay()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props && this.state.screenNum.snum0 == '-'){
            this.handleDisplay()
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){
            let games = nextProps.games
            let events = nextProps.events
            let rowData = nextProps.rowData

            this.setState({ games, events, rowData })
            this.getGames()
        }
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            if(games[0] !== undefined){
                formItems['gamecode'] = games[0].id
                this.getReportData(games[0].id)
            }

            this.setState({ games, formItems })
        })
    }

    getReportData = (gamecode) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/closedgames/' + gamecode)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            if(reportData[0] !== undefined){
                formItems['drawno'] =reportData[0].drawno
            }

            this.setState({ reportData, formItems, loader:false })
        }).catch((error)=>{alert(error); this.setState({loader:false})})
    }

    handleDisplay = () => {
        let rowData = this.state.rowData
        let isScreen = this.state.isScreen
        let screenNum = this.state.screenNum

        //alert(formItems.drawno)
        if(rowData.drawno > 0){
            isScreen = true
            var chance = new Chance();
            let chanceNum = chance.unique(chance.natural, 5, {min:1, max:90})

            this.setState({ genNumbers: chanceNum, isScreen })
            
            for (var i = 0; i < chanceNum.length; i++) {
                this.generateNum(i)
            }
        }
    }

    generateNum = (index) => {
        let startIndex = this.state.genNumbers[index]
        //alert(startIndex)
        //this.state.curNumbers.push(startIndex)
        const duration = 1000

        let output = $('#numCon' + index);
        let started = new Date().getTime();
        let screenNum = this.state.screenNum

        let animationTimer = setInterval(function() {
            if (output.val() == startIndex || new Date().getTime() - started > duration) {
              clearInterval(animationTimer);
              output.val(startIndex); 
                screenNum['snum' + index] = startIndex;
            } else {
              output.val(
                '' +
                Math.floor(Math.random() * 10) +
                Math.floor(Math.random() * 10)
              );
            }
          }, 100);
    }

    preDraw = () => {
        let event = this.state.events.filter(ev => ev.drawno == this.state.rowData.drawno)
        if(event[0] !== undefined){
            let sn = this.state.screenNum
            let game = event[0]
            game['screenNumbers'] = sn.snum0 + '-' + sn.snum1 + '-' + sn.snum2 + '-' + sn.snum3 + '-' + sn.snum4

            let formData = this.auth.encryptData(JSON.stringify(game))
            axios(this.config.PostOptions(formData, 'game/wapp/gamedetail'))
            .then((res) => {
                if(isNaN(res.data)){
                    this.goBack()
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }
            }).catch((error)=>{alert(error);this.goBack()})
        }
        
        
    }

    formatGame = (cell) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0]!==undefined) ? game[0].gamename : cell

        return gamename
    }

    eventFormat = (cell) => {
        let event = this.state.events.filter(ev => ev.drawno == cell)
        let drawdate = (event[0]!==undefined) ? cell + '   (' + moment(event[0].drawDate).format('DD MMM, YYYY') + ')' : cell

        return drawdate
    }

    goBack = () =>{
        $('#btnScreenCancel').click()
        $('#btnScreenGenerate').click()
    }

    handleChange = (e) => {
        let isScreen = false
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems, isScreen })

        if(e.target.name === 'gamecode'){
            $('[id^="numCon"]').val('-')
            this.getReportData(e.target.value)
        }
    }

    handleNumChange = (e) => {
        let screenNum = this.state.screenNum

        screenNum[e.target.name] = e.target.value
        this.setState({ screenNum })
    }

    handleNumBlur = (e) => {
        let screenNum = this.state.screenNum
        let val = e.target.value
        let cnt = 0

        Object.values(screenNum).map(snum =>{if(snum === val){cnt++}})
        if(val !== '' && cnt > 1){
            screenNum[e.target.name] = ''
            this.setState({ screenNum })
            let pos = parseInt(e.target.name.replace(/\D/g, ''))
            $('#numCon' + pos).focus()
            alert('You cannot repeat the same number, check and try again...')
        }
    }

    render(){
        return(
            <div className="modal fade" id="screenform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Generate Screen Numbers</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <table id='tbScreen' className="table table-condensed table-reponsive table-striped">
                                        <tbody>
                                            <tr>
                                                <td className='text-right'>Game Name: </td><td><b>{this.formatGame(this.state.rowData.gamecode)}</b></td>
                                                <td className='text-right'>Event #: </td><td><b>{this.eventFormat(this.state.rowData.drawno)}</b></td>
                                            </tr>
                                            <tr>
                                                <td className='text-center' colSpan="4">
                                                    <button id="btnScreenDisplay" className="btn btn-primary" onClick={this.handleDisplay}>
                                                    Generate</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                    
                                </div>
                                <div className="col-xs-12 text-center">
                                    <hr className="hrsp" />
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                            <input id="numCon0" name="snum0" type="text" className="numCon bg-color-blueLight txt-color-white" style={{width:'100%'}} 
                                                value={this.state.screenNum.snum0} onChange={this.handleNumChange} onBlur={this.handleNumBlur} />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                            <input id="numCon1" name="snum1" type="text" className="numCon bg-color-blueLight txt-color-white" style={{width:'100%'}} 
                                                value={this.state.screenNum.snum1} onChange={this.handleNumChange} onBlur={this.handleNumBlur} />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                            <input id="numCon2" name="snum2" type="text" className="numCon bg-color-blueLight txt-color-white" style={{width:'100%'}} 
                                                value={this.state.screenNum.snum2} onChange={this.handleNumChange} onBlur={this.handleNumBlur} />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                            <input id="numCon3" name="snum3" type="text" className="numCon bg-color-blueLight txt-color-white" style={{width:'100%'}} 
                                                value={this.state.screenNum.snum3} onChange={this.handleNumChange} onBlur={this.handleNumBlur} />
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                            <input id="numCon4" name="snum4" type="text" className="numCon bg-color-blueLight txt-color-white" style={{width:'100%'}} 
                                                value={this.state.screenNum.snum4} onChange={this.handleNumChange} onBlur={this.handleNumBlur} />
                                        </div>
                                    </div>
                                    <hr className="hrsp" />
                                    <button id="btnScreenCancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                    {this.state.screenNum.snum0 !== '' && this.state.screenNum.snum0 !== '-' &&
                                    <button id="btnPreDraw" className="btn btn-success" onClick={this.preDraw}>
                                    Pre-Draw</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        )
    }
}