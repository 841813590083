import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { TextArea } from 'semantic-ui-react';
import APIForm from './APIForm'

export default class APIConfig extends Component{
    constructor(){
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:'',message:'', threshold:'', senderid:'', provider: 'smsgh',phone:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            } ,charsLeft: 140,
            maxChars: 140,smsconfig:[],
            filter:0,
            apiTypes:[],
            reportData:[],
            rowData:{}
        }
    }

    componentDidMount(){
        this.getDropDowns()
    }

    getDropDowns = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/apitype')
        .then(res => {
            let apiTypes = this.auth.decryptData(res.data)
            let filter = this.state.filter

            if(apiTypes[0] !== undefined){
                filter = apiTypes[0].ivalue
            }

            this.setState({ apiTypes, filter })
            this.getAPIs(filter)
        }).catch((error)=>{alert(error)})
    }

    getAPIs = (filter) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/api/' + filter)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)

            this.setState({ reportData })
        }).catch((error)=>{alert(error)})
    }

    doFilter = (e) => {
        let filter = e.target.value

        this.setState({ filter })

        this.getAPIs(filter)
    }

    actionFormat = (cell, row) => {
        return cell
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12" style={{padding:'10px 20px 0 20px'}}>
                    <h3 className="pull-left">APIs Setup</h3>
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-success" data-toggle="modal" data-target="#apiform">
                            <i className="glyphicon glyphicon-plus"></i> New API
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-10">
                    <form id="frmApiFilter" className="smart-form">
                        <div className="row">
                            <div className="col-xs-12  col-sm-6">
                                <label className="label col col-4 text-right">API Type: </label>
                                <section className="col col-8">
                                    <label class="select">
                                        <select value={this.state.filter} onChange={this.doFilter} name="filter">
                                            {this.state.apiTypes.map(atype =>(
                                                <option value={atype.ivalue}>{atype.iname}</option>
                                            ))}
                                        </select> <i></i>
                                    </label>
                                </section>
                            </div>
                        </div>
                    </form>
                </article>
                <article className="col-xs-12">
                    <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                        <TableHeaderColumn dataField='name' isKey dataSort>Company</TableHeaderColumn>
                        <TableHeaderColumn dataField='api' dataSort>API</TableHeaderColumn>
                        <TableHeaderColumn dataField='username' dataSort>Username</TableHeaderColumn>
                        <TableHeaderColumn dataField='senderId' dataSort>Sender ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                    </BootstrapTable>
                </article>
                <APIForm rowData={this.state.rowData} apiType={this.state.filter} />
            </div>
        )
    }
}