import React, { Component } from 'react';
import DrawScreeningForm from './DrawScreeningForm';
import DrawScreenPreview from './DrawScreenPreview';

export default class DrawScreening extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-unsorted"></i> </span>
                            <h2><strong>Draw</strong> <i>Screening</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="drawScreenCon" className="widget-body">
                                <DrawScreenPreview />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}