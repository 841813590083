import React, { Component } from 'react';
import TransactionForm from './TransactionForm'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import _ from 'lodash'
import QueryJson from '../common/QueryJson'
import AgentForm from './AgentForm'

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
const momostatus = {0:'Failed',1:'Successfull',2:'Pending'}

export default class MomoTransList extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: { operator: '', username: '', retailer: '', startDate: moment().subtract(29, 'days'), endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken() },
            profiles: [], momodata:[],loader: false,

            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        this.getProfiles()
    }

    getProfiles = () => {
        this.$http.get('admin/wapp/user')
        .then(res => {
            if (isNaN(res.data)) {
                let profiles = this.auth.decryptData(res.data)

                this.setState({ profiles })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getMomodata = () => {
        this.setState({loader: true});
        let formItems = this.state.formItems;

        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'transaction/wapp/journal/momo'))
        .then((res) => {
            if(isNaN(res.data)){
                let resdata = this.auth.decryptData(res.data)

                let momodata = _.orderBy(resdata, ['createdon'], ['desc'])
                //alert(JSON.stringify(resdata))
                this.setState({ momodata })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
               
            this.setState({loader: false});
        })
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        window.agent = ptype
        this.setState({ formItems: fitm })
        this.getRetailer(ptype)
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems
        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate
        
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    dateFormat = (cell, row) => {
        return (moment(cell).format("DD MMM, YYYY @ h:mm a").toLocaleString())
    }
    statusFormat = (cell, row) => momostatus[cell]
    amountFormat(cell, row) {
        return amountformat.format(cell)
    }
    actionFormat = (cell, row) => {
        return <div>
            <i className="fa fa-lg fa-eye" style={{cursor:'pointer'}} onClick={()=>this.viewTranDetails(cell)}></i> 
            {row.status == 0 &&<> &nbsp; | &nbsp; 
            <i className="fa fa-lg fa-refresh" title="Re-check" style={{cursor:'pointer'}} onClick={()=>this.recheckTrans(row)}></i></>}
        </div>
    }
    descFormat = (cell, row) => {
        let user = this.state.profiles.filter(pro => pro.username == row.createdby)
        let username = (user[0] !== undefined) ? user[0].firstname + ' ' + user[0].lastname : ''
        return username + ' (' + row.createdby + ') ' + cell
    }

    viewTranDetails = (itm) => {
        //alert(itm)
    }

    recheckTrans = (row) => {
        this.setState({loader: true});

        this.$http.get(`transaction/wapp/${this.auth.getUser()}/${this.auth.getToken()}/momocheck/${row.transid}`)
        .then(res => {
            if (isNaN(res.data)) {
                let rdata = this.auth.decryptData(res.data);
                alert(`${rdata.status}: ${rdata.reason}`)
                this.getMomodata();
            } else {
                this.auth.doAlert(parseInt(res.data))
            }

            this.setState({loader: false});
        }).catch(error => {
            console.log(error.message);
            this.setState({loader: false});
        })
    }

    render() {
        if(this.state.loader){
            return <div id="loader"></div>;
        }else{
            //let buttonStyle = { width: '100%' };
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {label = start;}

            return (
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">DateRange: </button>
                            <DatetimeRangePicker
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                ranges={this.state.ranges}
                                onEvent={this.handleEvent}
                                className="selected-date-range-btn btn btn-default">
                                <div className="pull-left">
                                    <i className="fa fa-calendar" /> &nbsp; &nbsp;
                                        <span>{label}</span>
                                </div>
                                <div className="pull-right">
                                    &nbsp; &nbsp;<i className="fa fa-angle-down" />
                                </div>
                            </DatetimeRangePicker>
                            <button onClick={this.getMomodata} className="btn btn-info">Display</button>
                        </div>
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-12 ">
                        <BootstrapTable data={this.state.momodata} striped hover condensed pagination search>
                            <TableHeaderColumn dataField='createdon' width="160px" dataFormat={this.dateFormat} dataSort>Date</TableHeaderColumn>
                            <TableHeaderColumn dataField='transid' width="150px" isKey dataSort>Ref No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='sender' width="150px" dataSort >Mobile No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='description' width="300px" dataFormat={this.descFormat} dataSort>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='amount' dataAlign="right" dataFormat={this.amountFormat} width="90px" dataSort>Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' width="100px" dataFormat={this.statusFormat} dataSort>Status</TableHeaderColumn>
                            <TableHeaderColumn width="80px" dataField='id' dataFormat={this.actionFormat} dataSort></TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            );
        }
    }
}
