import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from 'axios';
import Auth from "../common/Auth";
import Config from "../Config";
import _, { set } from "lodash";
import ReactDOM from 'react-dom';
import WinsPayment from "./WinsPayment";
import WinsPayRetailerPdf from "../reports/Pdf/WinsPayRetailerPdf";
import WinPaymentConfirm from "./WinPaymentConfirm";

function WinsPaymentWriter(props){

    const [operator, setOperator] = useState(props.operator);
    const [formItems, setFormItems] = useState(props.formItems);
    const [groupedWins, setGroupedWins] = useState({});
    const [retailers, setRetailers] = useState(props.retailers);
    const [groupedRetailers, setGroupedRetailers] = useState({});
    const [agents, setAgents] = useState(props.agents);
    const [agent, setAgent] = useState(props.agent);
    const [game, setGame] = useState(props.game);
    const [isApprover, setApprover] = useState(false);
    const [curAgent, setCurAgent] = useState('');
    const [tbStruct, setTbStruct] = useState([]);
    const [unpaidWins, setUnpaidWins] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const [agentWins, setAgentWins] = useState([]);

    const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    useEffect(() => {

        setAgent(props.agent);
        setOperator(props.operator);
        setFormItems(props.formItems);
        setAgents(props.agents);
        setGame(props.game);
        setRetailers(props.retailers);
        
        getInitData(props.agents, props.agent, props.retailers);
    },[
        props.agent, props.operator, props.formItems, 
        props.agents, props.game, props.retailers
    ]);

    const getInitData = (agents, agent, retailers) => {

        if(agents === undefined || retailers === undefined){
            return;
        }

        let groupedRetailers = _.groupBy(retailers, 'username');
        setGroupedRetailers(groupedRetailers);

        $http.get('admin/wapp/' + auth.getUser() + '/' + auth.getToken() + '/approvers/2')
        .then(res => {
            let resdata = auth.decryptData(res.data)
            let approver = resdata.filter(rdata => rdata.approversPK.username == auth.getUser())

            setApprover((approver[0] !== undefined));
        })

        getWinsData(agent);
    }

    const getWinsData = (agent) =>{
        setLoading(true);

        let fItems = {...formItems};

        fItems.username = agent.username;

        let formData = auth.encryptData(JSON.stringify(fItems))
        axios(config.PostOptions(formData, 'report/wapp/winnings'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = auth.decryptData(res.data);

                let agentWins = resdata.filter(rdata => rdata.agent == agent.username),
                    unpaidWins = agentWins.filter(wdata => wdata.payStatus == 0 && !(wdata.agentPaid || wdata.retailerPaid)),
                    groupedWins = _.groupBy(unpaidWins, 'retailer');

                    getDatatable(agentWins);

                setGroupedWins(groupedWins);
                setUnpaidWins(unpaidWins);
                setAgentWins(agentWins);
            } else {
                auth.doAlert(parseInt(res.data))
            }   

            setLoading(false);        
        }).catch((error)=>{alert(error.message); setLoading(false);})
    }

    const makePayment = async (retailer) => {
        if (window.confirm("This will send money to Retailer(s)... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':retailer, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(retailer === 0){
                winIds = agentWins.map(wdata => wdata.id);
            }else{
                winIds = groupedWins[`${retailer}`].map(wdata => wdata.id);
            }

            formData.winIds = winIds;

            await axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
            'admin/wapp/retailer/payment')) .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data))
                }  
                
                setLoading(false);
                getWinsData(agent);
            }).catch((error)=>{alert(error);setLoading(false);});
        }
    }

    const sendForApproval = async (retailer) => {
        if (window.confirm("This will send Agent(s) wins payment for approval... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':retailer, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(retailer === 0){
                winIds = agentWins.map(wdata => wdata.id);
            }else{
                winIds = groupedWins[`${retailer}`].map(wdata => wdata.id);
            }

            formData.winIds = winIds;

            await axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
            'admin/wapp/winPaymentApproval')) .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data));
                }  
                getWinsData(agent);
                setLoading(false);
            }).catch((error)=>{alert(error.message);setLoading(false)});
        }
    }

    const sendForRejection = async (retailer) => {
        if (window.confirm("This will reject payment for approval... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':retailer, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(retailer === 0){
                Promise.all(Object.keys(groupedWins).map(async key => {

                    winIds = groupedWins[`${key}`].map(wdata => wdata.id);

                    formData.winIds = winIds;
                    formData.agent = key;

                    await axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
                    'admin/wapp/winPaymentReject'));
                })).then(ress => {
                    alert("Payment was successful...");
                    setLoading(false);
                    getWinsData(agent);
                });
            }else{
                winIds = groupedWins[`${agent}`].map(wdata => wdata.id);

                formData.winIds = winIds;

                await axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
                'admin/wapp/winPaymentReject')) .then((res) => {
                    if (isNaN(res.data)) {
                        let resdata = auth.decryptText(res.data);

                        alert(JSON.stringify(resdata));
                    } else {
                        auth.doAlert(parseInt(res.data))
                    }  
                    setLoading(false);
                    getWinsData(agent);
                }).catch((error)=>{alert(error);setLoading(false)});
            }
        }
    }

    const getDatatable = (agentWins) => {
        let tbStruct = [], groupedWins = _.groupBy(agentWins, 'retailer');

        tbStruct.push(<tr><th>Retailer Name</th><th>Total Wins</th><th>Total Paid</th></tr>);
        Object.keys(groupedWins).map((key, idx)  => {
            tbStruct.push(<tr><td>{writerFormat(key, groupedWins[`${key}`])}</td><td>{numformat.format(_.sumBy(groupedWins[`${key}`],'winAmount'))}</td><td>{numformat.format(_.sumBy(groupedWins[`${key}`].filter(gw => gw.agentPaid),'winAmount'))}</td></tr>);
        });
        tbStruct.push(<tr><td>Total</td><td>{numformat.format(_.sumBy(agentWins,'winAmount'))}</td><td>{numformat.format(_.sumBy(agentWins.filter(gw => gw.agentPaid),'winAmount'))}</td></tr>);

        setTbStruct(tbStruct);
    }

    const writerFormat = (cell, row) => {
        let retailer = retailers.filter(rt => rt.username == cell);
        
        return (retailer.length > 0) ? `${cell} - ${retailer[0].fullname}` : cell;
    }

    const doPreview = () => ReactDOM.render(
        <WinsPayRetailerPdf rowData={tbStruct} data={{'game':game,
            'drawDate': formItems.startDate}} agent={agent}
            operator={operator} formItems={formItems} game={game}
            agents={agents} retailers={retailers}  />,
        document.getElementById('winListCon')
    );

    const goBack = () => ReactDOM.render(
        <WinsPayment operator={operator} agents={agents} game={game}
            formItems={formItems} retailers={retailers} agent={agent}  />, 
        document.getElementById('winListCon')
    );

    if(isLoading){
        return <div id="loader"></div>;
    }

    return(
        <div className="row">
            <article className="col-xs-12">
                <div className="row">
                    <div className="col-xs-8">
                        <table className="table table-striped table-responsive">
                            <tbody>
                                <tr><td>Agent: <b>{agent !== undefined && agent.fullname}</b></td></tr>
                                <tr><td>Game: <b>{game.gamename}</b></td></tr>
                                <tr><td>Draw Date: <b>{moment(formItems.startDate).format('DD MMM, YYYY')}</b></td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-4">
                        <button className="btn btn-sm btn-primary" onClick={goBack}><i className="fa fa-backward" /> Back</button>
                        <button className="btn btn-sm btn-info" onClick={doPreview}><i className="fa fa-print" /> Preview</button>
                    </div>
                </div>
            </article>
            <article className="col-xs-12">
                <table className="table table-striped table-condensed">
                    <thead>
                        <tr>
                            <th>Writer Name</th>
                            <th style={{textAlign:'right'}}>Total Wins</th>
                            <th style={{textAlign:'right',width:'250px'}}>
                                {unpaidWins.filter(gw => gw.status == 0).length > 0 &&
                                <button className="btn btn-sm btn-warning" onClick={() => sendForApproval(0)}><i className="fa fa-money" /> Pay All</button>}
                                {unpaidWins.filter(gw => gw.status == 1).length > 0 &&  isApprover &&
                                <button className="btn btn-sm btn-danger" onClick={() => setCurAgent(0)} 
                                    data-toggle="modal" data-target="#confirmWins">
                                    <i className="glyphicon glyphicon-check" /> Approve All
                                </button>}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedWins).map((key, idx) => 
                        <tr>
                            <td>{writerFormat(key, groupedWins[`${key}`])}</td>
                            <td style={{textAlign:'right'}}>{numformat.format(_.sumBy(groupedWins[`${key}`],'winAmount'))}</td>
                            <td style={{textAlign:'right'}}>
                                {groupedWins[`${key}`].filter(gw => gw.status == 0).length > 0 &&
                                <button className="btn btn-xs btn-warning" onClick={() => sendForApproval(key)}><i className="fa fa-money" /> Pay</button>}
                                {groupedWins[`${key}`].filter(gw => gw.status == 1).length > 0 && isApprover &&
                                <button className="btn btn-xs btn-danger" onClick={() => setCurAgent(key)} 
                                    data-toggle="modal" data-target="#confirmWins">
                                    <i className="glyphicon glyphicon-check" /> Approve
                                </button>}
                            </td>
                        </tr>)}
                        <tr>
                            <th>Total</th>
                            <th style={{textAlign:'right'}}>{numformat.format(_.sumBy(unpaidWins,'winAmount'))}</th>
                            <th style={{textAlign:'right'}}>&nbsp;</th>
                        </tr>
                    </tbody>
                </table>
            </article>

            <WinPaymentConfirm
                agent={curAgent} 
                groupedWins={groupedWins} 
                unpaidWins={unpaidWins} 
                makePayment={makePayment} 
                sendForRejection={sendForRejection} />
        </div>
    )
}

export default WinsPaymentWriter;
