import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import Dropdown from './Dropdown';

export default class DropdownForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            isCreateField: false,
            formItems: {
                id:0, fieldname:'', iname:'', ivalue:'', createdby: this.auth.getUser(),
                createdon: new Date(), modifiedby: null, modifiedon: null,
                username: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        let rowData = this.props.rowData
        let formItems = this.state.formItems
        let isEdit =  rowData !== undefined && rowData.id > 0
        let isCreateField = rowData === undefined

        if(isCreateField){
            formItems = {
                id:0, fieldname:'', iname:'', ivalue:'', createdby: this.auth.getUser(),
                createdon: new Date(), modifiedby: null, modifiedon: null,
                username: this.auth.getUser(), token: this.auth.getToken()
            }
        }else if(isEdit){
            formItems = rowData
            formItems['username'] = this.auth.getUser()
            formItems['token'] = this.auth.getToken()
        }else{
            formItems['fieldname'] = rowData.fieldname
        }

        this.setState({ formItems, isEdit, isCreateField })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let rowData = nextProps.rowData
            let formItems = this.state.formItems
            let isEdit =  rowData.id !== undefined && rowData.id > 0
            let isCreateField = rowData.fieldname === undefined

            if(isCreateField){
                formItems = {
                    id:0, fieldname:'', iname:'', ivalue:'', createdby: this.auth.getUser(),
                    createdon: new Date(), modifiedby: null, modifiedon: null,
                    username: this.auth.getUser(), token: this.auth.getToken()
                }
            }else if(isEdit){
                formItems = rowData
                formItems['username'] = this.auth.getUser()
                formItems['token'] = this.auth.getToken()
            }else{
                formItems['fieldname'] = rowData.fieldname
            }

            this.setState({ formItems, isEdit, isCreateField })
        }
    }

    handleChange = (e) =>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/dropdown'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnDropdowncancel').click()
                this.goBack(formItems.fieldname)
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    goBack = (fieldname) => {
        ReactDOM.render(<Dropdown fieldname={fieldname} />, 
            document.getElementById('generalConfigCon'))
    }

    render(){
        return(
            <div className="modal fade" id="dropdownform" tabindex="-1" role="dialog" aria-hidden="true">  
            <div className="modal-dialog">  
                <div className="modal-content"> 
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                        <h5 className="modal-title">{(this.state.isEdit) ? 'Edit' : 'New'} Dropdown</h5>
                    </div>
                    <div className="modal-body">
                        <form id="frmDropdown" className="smart-form" onSubmit={this.formSubmit}>
                            <div className="row">
                                <div className="col-xs-12 col-md-8">
                                    <label className="label col col-4 text-right">Fieldname: </label>
                                    <section className="col col-8">
                                        <label className="input">
                                            <input type="text" name="fieldname" required readonly={this.state.isCreateField?false:'readonly'}
                                            value={this.state.formItems.fieldname} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-md-8">
                                    <label className="label col col-4 text-right">Dropdown Name: </label>
                                    <section className="col col-8">
                                        <label className="input">
                                            <input type="text" name="iname" required 
                                            value={this.state.formItems.iname} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                                <div className="col-xs-12 col-md-8">
                                    <label className="label col col-4 text-right">Dropdown Value: </label>
                                    <section className="col col-8">
                                        <label className="input">
                                            <input type="text" name="ivalue" required 
                                            value={this.state.formItems.ivalue} onChange={this.handleChange}/>
                                        </label>
                                    </section>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button id="btnDropdowncancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button type="submit" form="frmDropdown" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}