import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class RiskList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            games: [],
            bets: [],
            events: [],
            formItems: { gamecode:0, drawno:0 }
        }
    }

    componentDidMount(){
        this.getGames()
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/betting')
        .then(res => {
            let bets = this.auth.decryptData(res.data)

            this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
            .then(res => {
                let games = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                if(games[0] !== undefined){
                    formItems['gamecode'] = games[0].id
                    this.getEvents(games[0].id, 0)
                }

                this.setState({ games, formItems, bets })
            })
        })
    }

    getEvents = (gamecode) => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamedetailcode/' + gamecode)
        .then(res => {
            let events = this.auth.decryptData(res.data)
            let formItems = this.state.formItems

            if(events[0] !== undefined){
                formItems['drawno'] = events[0].drawno
            }

            this.setState({ events, formItems })
        })
    }

    handleDisplay = () => {
        let fitm = this.state.formItems
        this.$http.get('draw/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/risk/' + fitm.gamecode + "/" + fitm.drawno)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)

            this.setState({ reportData })
        })
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        this.setState({ formItems })

        if(e.target.name === 'gamecode'){
            this.getEvents(e.target.value)
        }
    }

    gameFormat = (cell, row) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0]!==undefined)?game[0].gamename : cell

        return gamename
    }

    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY @ hh:mma')

    amountFormat = (cell, row) => numformat.format(cell)

    betFormat = (cell, row) => {
        let bet = this.state.bets.filter(bt => bt.id == cell)
        let betname = (bet[0]!==undefined)?bet[0].betname : cell

        return betname
    }

    rateFormat = (cell, row) => {
        let bet = this.state.bets.filter(bt => bt.id == cell)
        let rate = (bet[0]!==undefined)? 'x' + bet[0].multiplier : 'x240'

        return rate
    }

    winFormat = (cell, row) => {
        let bet = this.state.bets.filter(bt => bt.id == cell)
        let rate = (bet[0]!==undefined)? bet[0].multiplier : 240
        return numformat.format(row.lineTotal * parseFloat(rate) * row.played)
    }

    stakeFormat = (cell, row) => {
        let stakenum = cell
        if(row.betcode == 11){
            let num = ['', '']
            if(cell.indexOf('~') != -1){
                num = cell.split('~')
            }else{
                let snum = cell.split('-')
                num[0] = snum[0]
                snum.shift()
                let against = snum.join(' - ')
                num[1] = against
            }
            stakenum = num[0] + '<br /><i>Against</i><br />'+ num[1]
        }

        return stakenum
    }

    render(){
        return(
            <div className="row">
                <div className="col-xs-12 clearfix">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">Game: </button>
                        <select name="gamecode" className="btn btn-default" style={{width:'150px', height:'30px'}}
                            value={this.state.formItems.gamecode} onChange={this.handleChange}>
                            {this.state.games.map(game => (
                                <option value={game.id}>{game.gamename}</option>
                            ))}
                        </select>
                        <button className="btn btn-default">Event: </button>
                        <select name="drawno" className="btn btn-def/ ult" style={{width:'150px', height:'30px'}}
                            value={this.state.formItems.drawno} onChange={this.handleChange}>
                            {this.state.events.map(rdata => (
                                <option value={rdata.drawno}>#{rdata.drawno} ({moment(rdata.drawDate).format('DD MMM, YYYY')})</option>
                            ))}
                        </select>
                        <button id="btnDisplay" className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 "><hr className="hrsp" /></div>
                <div className="col-xs-12">
                    <hr className="hrsp" />
                    <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                        <TableHeaderColumn dataField='betcode' isKey dataSort width="120px" dataFormat={this.betFormat}>Bet Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='lineTotal' dataSort width="120px" dataFormat={this.amountFormat}>Line Total</TableHeaderColumn>
                        <TableHeaderColumn dataField='played' dataSort width="80px" >Played</TableHeaderColumn>
                        <TableHeaderColumn dataField='betcode' dataSort width="100px"  dataFormat={this.rateFormat}>Rate</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} width="200px"  dataField='stakeNum' dataSort dataFormat={this.stakeFormat}>Stake Number</TableHeaderColumn>
                        <TableHeaderColumn dataField='betcode' dataAlign="right" width="120px"  dataSort dataFormat={this.winFormat}>Win Amount</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        )
    }
}