import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import axios from 'axios'
import moment from 'moment'
import SMSTemplate from './SMSTemplate'

export default class SMSNewTemplate extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                id:0, sheduleType:1, smsMessage:'', createdby:this.auth.getUser(), createdon: new Date(),
                modifiedby:this.auth.getUser(), modifiedon: new Date(), curuser:this.auth.getUser(), 
                token:this.auth.getToken()
            },
            charsLeft: 500, maxChars: 500,
            scheduleTypes: this.props.scheduleTypes,
            rowData:this.props.rowData,
            placeholders: [],
            loader: false
        }

    }

    componentDidMount(){
        this.setState({ loader: true })

        if(this.props.rowData !== ''){
            
            let formItems = this.state.formItems
            let rowData = this.props.rowData

            formItems['id'] = rowData.id
            formItems['sheduleType'] = rowData.sheduleType
            formItems['smsMessage'] = rowData.smsMessage
            formItems['createdby'] = rowData.createdby
            formItems['createdon'] = rowData.createdon
            formItems['modifiedby'] = rowData.modifiedby
            formItems['modifiedon'] = rowData.modifiedon

            this.setState({ formItems })

            this.getDropdowns(rowData.sheduleType)
        }else{
            this.getDropdowns(1)
        }

        
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.setState({ loader: true })
            let scheduleTypes = nextProps.scheduleTypes
            let formItems = this.state.formItems
            let rowData = nextProps.rowData

            formItems['id'] = rowData.id
            formItems['sheduleType'] = rowData.sheduleType
            formItems['smsMessage'] = rowData.smsMessage
            formItems['createdby'] = rowData.createdby
            formItems['createdon'] = rowData.createdon
            formItems['modifiedby'] = rowData.modifiedby
            formItems['modifiedon'] = rowData.modifiedon

            this.setState({ scheduleTypes, formItems })

            this.getDropdowns(rowData.sheduleType)
        }
    }

    getDropdowns = (messagetype) => {
        this.$http.get('admin/wapp/placeholder/' + messagetype)
            .then(res => {
                let placeholders = this.auth.decryptData(res.data)
                
                this.setState({ placeholders, loader: false })
            }).catch((error) => { alert(error) })
    }

    handleChange=(e)=>{
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        this.setState({ formItems }) 

        if (e.target.name == 'smsMessage') {
            const charCount = e.target.value.length
            const maxChar = this.state.maxChars
            const charLength = maxChar - charCount
            this.setState({ charsLeft: charLength })
        }

        if(e.target.name == 'sheduleType'){
            this.getDropdowns(e.target.value)
        }
    }

    goBack = () => {
        ReactDOM.render(<SMSTemplate />, 
            document.getElementById('sms_templates'))
    }

    submit=(e)=>{
        e.preventDefault()

        let formItems = this.state.formItems
        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/template'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                this.goBack()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    loading = () => <div id="loader"></div>
    
    render(){
        if (this.state.loader) {
            return this.loading()
        } else {
            return(
                <div className="row">
                    <article className="col-xs-12">
                        <div className="btn-group btn-group-sm pull-right clearfix">
                            <button type="submit" className="btn btn-default" onClick={this.submit} form="frmNewMessage">
                                <i className="glyphicon glyphicon-save"></i> Save Template
                            </button>
                            <button className="btn btn-danger" onClick={this.goBack}>
                                <i className="glyphicon glyphicon-backward"></i> Cancel
                            </button>
                        </div>
                    </article>
                    <article className="col-xs-12"><hr className="hrsp" /></article>
                    <article className="col-xs-12">
                        <form id="frmNewMessage" className="smart-form" onSubmit={this.formSubmit}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-7">
                                    <div>
                                        <label className="label col col-4 text-right">Schedule Type: </label>
                                        <section className="col col-8">
                                            <label class="select">
                                                <select value={this.state.formItems.sheduleType} onChange={this.handleChange} name="sheduleType">
                                                    {this.state.scheduleTypes.map(stype =>(
                                                        <option value={stype.ivalue}>{stype.iname}</option>
                                                    ))}
                                                </select><i></i>
                                            </label>
                                        </section>
                                    </div>
                                    <div>
                                        <label className="label col col-4 text-right">SMS Message: </label>
                                        <section className="col col-8">
                                            <label class="textarea textarea-resizable">
                                                <textarea rows="5" name="smsMessage" class="custom-scroll" value={this.state.formItems.smsMessage} onChange={this.handleChange} maxLength={this.state.maxChars}></textarea>
                                            </label>
                                            <label class="label"> <small>{this.state.charsLeft} characters more!</small></label>
                                        </section>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-5 text-center text-align-center">
                                    <h3>Allowed Placeholders</h3>
                                    <hr className="hrsp" />
                                    <div className="row">
                                        {this.state.placeholders.map(phold => (
                                            <div className="col-xs-12 col-sm-6">{phold.placeholderText}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </article>
                </div>
            )
        }
    }
}