import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'

export default class EmailContent extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
    }

    componentDidMount(){

    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){

        }
    }

    render(){
        return(
            <div>Email Content</div>
        )
    }
}