import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Auth from '../common/Auth'
import Config from '../Config';
import CashierForm from './CashierForm';
import CashierFunds from './CashierFunds';

var curformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })

class CashierList extends Component{
    constructor(props){
        super(props);

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            cashiers:[],
            casheir: ''
        }
    }

    componentDidMount = () => {
        this.getReportData()
    }

    getReportData = () => {
        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/cashier/profile`)
        .then(res => {
            let reportData = this.auth.decryptData(res.data);

            this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/cashier`)
            .then(res => {
                let cashiers = this.auth.decryptData(res.data)
            
                //alert(JSON.stringify(reportData));
                this.setState({ reportData, cashiers, loader: false });
            }).catch(error => {alert(error.message)});
        }).catch(error => {alert(error.message)});
    }

    nameFormat = (cell, row) => `${cell} ${row.lastname}`;
    balFormat = (cell, row) => {
        let cashier = this.state.cashiers.filter(cash => cash.username == cell);
        let bal = (cashier[0] !== undefined) ? cashier[0].cashlimit : 0;

        return curformat.format(bal);
    }

    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            <button className="btn btn-default" title="Add Funds" data-toggle="modal" data-target="#fundform" onClick={() => this.addFunds(cell)}>
                <i className="glyphicon glyphicon-plus"></i> Add Funds
            </button>
            <button className="btn btn-default" title="Remove Cashier" data-toggle="modal" data-target="#" onClick={() => this.removeCashier(cell)} >
                <i className="glyphicon glyphicon-trash"></i> Delete
            </button>
        </div>)
    }

    addFunds = (username) => {
        let cash = this.state.cashiers.filter(cash => cash.username == username);
        let cashier = cash[0];
        this.setState({ cashier });
    }

    removeCashier = (username) => {
        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/cashier/${username}`)
        .then(res => {
            let result = this.auth.decryptText(res.data)
            
            alert(result);
        }).catch(error => {alert(error.message)});
    }

    render(){
        return(
            <div className="row">
                <div className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right">
                        <button className="btn btn-info" data-toggle="modal" data-target="#cashierform">
                            <i className="glyphicon glyphicon-plus"></i> New Cashier
                        </button>
                    </div>
                </div>
                <div className="col-xs-12">
                <hr className="hrsp" />
                    <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                        <TableHeaderColumn dataField='firstname' dataSort dataFormat={this.nameFormat}>Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='mobile'width="120px" isKey dataSort >Mobile</TableHeaderColumn>
                        <TableHeaderColumn dataField='email' dataSort >Email</TableHeaderColumn>
                        <TableHeaderColumn dataField='username' width="100px"  dataSort dataAlign="right" dataFormat={this.balFormat}>Balance</TableHeaderColumn>
                        <TableHeaderColumn dataField='username' width="250px"  dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <CashierForm cashiers={this.state.cashiers} getReportData={this.getReportData} />
                <CashierFunds cashier={this.state.cashier} getReportData={this.getReportData} />
            </div>
        )
    }
}

export default CashierList;