import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import GeneralReportList from './GeneralReportList';
import ReportView from './ReportView';


export default class GeneralReport extends Component{
    constructor(){
        super()
    }
    
    getPage = () =>{
        ReactDOM.render(
            <ReportView/>, 
            document.getElementById('generalReport_inners')
        )
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
           this.getPage()
        }
    }

    render(){
        return(
           
            <div className="row" id="generalReport_inners">
            <ReportView/>
            </div>
        )
    }
}