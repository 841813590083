import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'

export default class TerminalAssignForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:0, serial:'', brand:'', model:'', imei:'', username:'', 
                createdby:this.auth.getUser(), status:'1', agent:null,
                createdon:new Date(), modifiedby:null, modifiedon:null,
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            criteria: '',
            opcriterial: 0,
            operators: [],
            agents:[],
            retailers:[],
            retailersFilter:[],
            terminals:this.props.terminals
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            let formItems = nextProps.rowData
            let terminals = nextProps.terminals

            if(formItems.status !== undefined){
                formItems['curuser'] = this.auth.getUser()
                //formItems['status'] = '2'
                this.setState({ formItems })
            }

            this.setState({ terminals })
            
            this.getOperators()
        }
    }

    getOperators = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/userole")
        .then(res => {
            let role = this.auth.decryptData(res.data)
            let systemDefine = (role.id <= 2 || [8].includes(role.ids)) ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
            
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res => {
                let operators = this.auth.decryptData(res.data)
                let opcriterial = (operators[0]!==undefined && this.state.opcriterial==0) ? operators[0].username : this.state.opcriterial
                
                this.setState({ operators, opcriterial })

                this.getAgents(opcriterial)
            })
        })
    }

    getAgents = (operator) =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/superior/' + operator)
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            
            let agents = resdata.filter(rdata => rdata.usertype == '2')
            let criteria = (agents[0] !== undefined) ? agents[0].username : ''
        
            this.setState({ agents, criteria })
            
            this.getRetailers(criteria)
        })
    }

    getRetailers = (itm) =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/superior/' + itm)
        .then(res => {
            let retailers = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            let terminals = this.state.terminals
            let retailersFilter = retailers.filter(ret => {
                let retailer = terminals.filter(term => term.username == ret.username)
                if(retailer[0] === undefined){
                    return ret
                }
            })
            formItems['username'] = (retailersFilter[0]!==undefined) ? retailersFilter[0].username : ''
            this.setState({ retailers, retailersFilter, formItems })
        })
    }

    handleChange = (e) =>{
        if(e.target.name === 'criteria'){
            let criteria = e.target.value
            this.setState({ criteria })

            this.getRetailers(criteria)
        }else if(e.target.name === 'opcriteria'){
            let opcriteria = e.target.value
            this.setState({ opcriteria })

            this.getAgents(opcriteria)
        }else{
            let formItems = this.state.formItems
            formItems[e.target.name] = e.target.value
            
            this.setState({ formItems })
        }
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems

        if(formItems.username.trim() == ''){
            alert("The POS is not assigned to a writer....")
            return
        }

        formItems['modifiedby'] = this.auth.getUser()
        formItems['modifiedon'] = new Date()
        formItems['status'] = '2'
        
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/terminal'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnTassigncancel').click()
                $('#btnTerminalDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade" id="assignterminalform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Assign Terminal</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmTerminalAssign" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Operator: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="opcriteria" value={this.state.opcriteria} onChange={this.handleChange}>
                                                    {this.state.operators.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>

                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Agents: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="criteria" value={this.state.criteria} onChange={this.handleChange}>
                                                    {this.state.agents.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>

                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Retailers: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="username" value={this.state.formItems.username} onChange={this.handleChange}>
                                                    {this.state.retailersFilter.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnTassigncancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmTerminalAssign" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}