import React, { Component } from "react";
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import ChangePassword from "./ChangePassword";
import ProfileForm from "./ProfileForm";

export default class MyProfileDetails extends Component{

    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            user: {},
            profileRoles: [],
            usertype: {},
            utype:'1',
            usertill:[]
        }
    }

    componentDidMount(){
        this.getProfileRoles()
        this.getUserTill()
    }

    getProfileRoles = () => {
        this.$http.get('admin/wapp/user/' + this.auth.getUser())
        .then(res => {
            let user = this.auth.decryptData(res.data)
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/role')
            .then(res => {
                let profileRoles = this.auth.decryptData(res.data)
                
                let rolename = profileRoles.filter(prole => user.auth==prole.id)
                user['rolename'] = (rolename[0] !== undefined) ? rolename[0].rolename : user.auth
                
                this.setState({ profileRoles, user })
                
                this.getUserType()
            })//.catch((error)=>{alert(error)})
        }).catch((error)=>{alert(error)})
    }

    getUserType = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertype/' + this.state.user.username)
        .then(res => {
            let usertype = this.auth.decryptData(res.data)
            
            this.setState({ usertype })
        }).catch((error)=>{alert(error)})
    }

    getUserTill = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertill/' + this.state.user.username)
        .then(res => {
            let usertill = this.auth.decryptData(res.data)
            
            this.setState({ usertill })
        }).catch((error)=>{alert(error)})
    }

    handleNewUser = () => {
        ReactDOM.render(
            <ProfileForm username={this.state.user.username} role={this.state.user.role} area="myProfileCon" />,
            document.getElementById('myProfileCon')
        )
    }

    render(){
        let curuser = this.state.user
        return(
            <div className="row">
                <article className="col-xs-12 col-sm-7 col-md-10">
                    <div className="row">
                        <div className="well well-sm">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="well well-light well-sm no-margin no-padding">
            
                                        <div className="row">
            
                                            <div className="col-sm-12">
                                                <div id="myCarousel" className="carousel fade profile-carousel">
                                                    <div className="air air-bottom-right padding-10">
                                                        <button className="btn txt-color-white bg-color-teal btn-sm" onClick={this.handleNewUser}><i class="fa fa-user"></i> Edit Profile</button>
                                                        &nbsp; <button className="btn txt-color-white bg-color-pinkDark btn-sm" title="Change Password" data-toggle="modal" data-target="#changepassword"><i class="fa fa-lock"></i> Change Password</button>
                                                    </div>
                                                    <div className="air air-top-left padding-10">
                                                        <h4 className="txt-color-white font-md">&nbsp;</h4>
                                                    </div>
                                                    <ol className="carousel-indicators">
                                                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                                        <li data-target="#myCarousel" data-slide-to="1" className=""></li>
                                                        <li data-target="#myCarousel" data-slide-to="2" className=""></li>
                                                    </ol>
                                                    <div className="carousel-inner">
                                                        <div className="item active"><img src="assets/img/s1.jpg" alt="" /></div>
                                                        <div className="item"><img src="assets/img/s2.jpg" alt="" /></div>
                                                        <div className="item"><img src="assets/img/m3.jpg" alt="" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-3 profile-pic">
                                                        <img src={this.config.ImagePath('custfiles') + '/' + curuser.username + '.' + curuser.filex} alt={curuser.username} style={{maxWidth:'90px'}} />
                                                        <div class="padding-10">
                                                            {curuser.auth === 3 && <h4 class="font-md"><strong>0</strong><br />
                                                            <small>Agents</small></h4>}<br />
                                                            {this.state.usertype.username !== undefined && [3,4].includes(curuser.auth) && 
                                                            <h4 class="font-md"><strong>0</strong><br />
                                                            <small>Retalers</small></h4>}
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <h1>{curuser.firstname} {curuser.middlename} <span class="semi-bold">{curuser.lastname}</span><br />
                                                        <small> {curuser.rolename} </small> 
                                                            {curuser.token !== '0' && curuser.token !== '-1' ? 
                                                            <span className="badge bg-color-green bounceIn animated"> Online </span>
                                                            :<span className="badge bg-color-red bounceIn animated"> Offline </span>}
                                                            {curuser.status > 0 ? 
                                                            <span className="badge bg-color-green bounceIn animated"> Active </span>
                                                            :<span className="badge bg-color-red bounceIn animated"> Inactive </span>}
                                                        </h1>

                                                        <ul class="list-unstyled">
                                                            <li>
                                                                <p class="text-muted">
                                                                    <i class="fa fa-phone"></i>&nbsp;&nbsp;<span class="txt-color-darken">{curuser.mobile}</span>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p class="text-muted">
                                                                    <i class="fa fa-envelope"></i>&nbsp;&nbsp;<a href={'mailto:' + curuser.email}>{curuser.email}</a>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                        <br />

                                                    </div>
                                                    {this.state.usertype.username !== undefined && this.state.usertill[0] !== undefined &&
                                                    <div class="col-sm-3"><br />
                                                        <div className="table-bordered padding-5">
                                                            <div><span className="txt-color-darken">Total Unit: </span>
                                                            <b>{this.state.usertill[0].closeBal}</b></div>
                                                            <div><span className="txt-color-darken">Total Cash: </span>
                                                            <b>{this.state.usertill[1].closeBal}</b></div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <ChangePassword rowData={this.state.user} area="myProfileCon" />
            </div>
        )
    }
}