import React, { Component } from 'react';
import GlTransList from './GlTransList'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'

export default class TransactionForm extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            username: '',
            roles: [],
            formItems: {
                fromLevel1: '', fromLevel3: '', toLevel1: '', toLevel3: '',
                fromDescription: '', toDescription: '', amount: '',batch:this.props.batch,
                createdby: this.auth.getUser(), createdon: new Date(),token: this.auth.getToken()
            }
        }
    }

    goBack = () => {
        ReactDOM.render(
            <GlTransList />,
            document.getElementById('gltrans_inner')
        )
    }
    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button className="btn btn-warning" onClick={this.goBack}>
                            <i className="fa fa-lg fa-backward"></i> Go Back
                    </button>
                        <button type="submit" className="btn btn-success" form="frmUser">
                            <i className="glyphicon glyphicon-save"></i> Save Transaction
                    </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12 col-sm-12">
                    <form id="frmUser" className="smart-form" onSubmit={this.formSubmit}>
                        <div className="row">

                        <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-3 text-right">Debit Account: </label>
                                <section className="col col-9">
                                    <label className="select">
                                        <select name="auth" value={this.state.formItems.rolename} onChange={this.handleChange}>
                                            {this.state.roles.map(role => (
                                                <option value={role.id}>{role.rolename}</option>
                                            ))}
                                        </select> <i></i>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-3 text-right">Credit Account: </label>
                                <section className="col col-9">
                                    <label className="select">
                                        <select name="auth" value={this.state.formItems.rolename} onChange={this.handleChange}>
                                            {this.state.roles.map(role => (
                                                <option value={role.id}>{role.rolename}</option>
                                            ))}
                                        </select> <i></i>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-3 text-right">Debit Description: </label>
                                <section className="col col-9">
                                    <label className="input">
                                        <input type="text" name="username" placeholder="Credit Description" required
                                            value={this.state.formItems.username} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>

                           
                           
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-3 text-right">Credit Description: </label>
                                <section className="col col-9">
                                    <label className="input">
                                        <input type="text" name="firstname" placeholder="Credit Description" required
                                            value={this.state.formItems.firstname} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>


                
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-3 text-right">Amount: </label>
                                <section className="col col-9">
                                    <label className="input">
                                        <input type="text" name="pin" placeholder="Amount" required
                                            value={this.state.formItems.pin} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>

                           
                        
                        
                        
                        </div>

                    </form>
                </article>

                
            </div>
        )
    }
}
