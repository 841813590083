import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import { BrowserRouter, Link } from 'react-router-dom'
import ReportView from './ReportView'
import QueryJson from '../common/QueryJson'
import RetailersListPdf from './Pdf/RetailerListPdf';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class RetailerPerAgent extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            operators: [],
            operator:'',
            agent:'',
            retailer:'',
            agents:[],
            retailers: [],
            systemDefine: (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1,
            loader: false,
            usertypeItems:{
                username:'', usertype:'3', superior:'', bankname: '', area:'retailer',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        this.setState({ loader:true })
    
        this.getOperators(this.state.systemDefine)
    }
       
    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res => {
                if (isNaN(res.data)) {
                    let operators = this.auth.decryptData(res.data)
                    let operator = (operators[0] != undefined) ? operators[0].username : operators.username
                    this.getAgents(operator)
                    this.setState({ operator, operators })

                } else {
                    this.auth.doAlert(parseInt(res.data))
                }

            })
        }
    }
            

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails/' + operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data),
                agent=(agents.length > 0)?agents[0].username:"";
                
                this.setState({ agents, agent,loader: false })
            })
    }

    getRetailers = () =>{
        this.setState({ loader:true })
        let { agent } = this.state;
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/retaildetails/' + agent)
        .then(res => {
            let retailers = this.auth.decryptData(res.data);

            this.setState({ retailers, loader:false });
        })
    }

    handleChanges = (e) => {
        let agent = this.state.agent
        agent = e.target.value
        this.setState({ agent, retailers:[] })
        //this.getRetailers(agent)
    }

    handleChange = (e) => {
        let operator = e.target.value
        this.getAgents(operator)
        this.setState({ operator, retailers:[] })

    }

    statusFormat = (cell) => {
        let online = (isNaN(cell)) ? 'bg-color-green' : 'bg-color-red';
        let onlinetxt = (isNaN(cell)) ? 'Online' : 'Offline';

        return (<span className={'badge bounceIn animated ' + online}> {onlinetxt} </span>)
    }

    goBack = () => {
        ReactDOM.render(
            <ReportView />,
            document.getElementById('generalReport_inners')
        )
    }

    printPreview = () => {
        // alert("is here")
        let data = {agent:this.agentName(this.state.agent), agentid:this.state.agent}
        ReactDOM.render(
            <RetailersListPdf data={data} retailer={this.state.retailers} />,
            document.getElementById('generalReport_inners')
        )
    }

    transferUnit = (retailer) =>{
        this.setState({ retailer })
    }
    
    agentName=(username)=>{
        let agent = new QueryJson(this.state.agents, 'username=' + username)
        return (agent[0]!=undefined)?agent[0].fullname:""
    }

    loading = () => <div id="loader"></div>

    render(){
        let retailers=this.state.retailers
        if(this.state.loader){
            return this.loading()
          }else{
            return(
                <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Retailer Per Agent</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                            <div className="row" id="generalReport_inners">
                       <div>
                <div className="col-xs-12 clearfix">
                <div className="btn-group btn-group-sm">
                       <button className="btn btn-default">Operator: </button>
                       <select name="criteria" className="btn btn-default" style={{ width: '200px', height:'30px' }}
                           value={this.state.operator} onChange={this.handleChange}>
                           {this.state.operators.map(op => (
                               <option value={op.username}>{op.fullname}</option>
                           ))}
                       </select>
                       <button className="btn btn-default">Agent: </button>
                            <select name="agent" className="btn btn-default" style={{ width: '200px', height:'30px' }}
                                value={this.state.agent} onChange={this.handleChanges}>
                                {this.state.agents.map(op => (
                                    <option value={op.username}>{op.fullname}</option>
                                ))}
                            </select>
                       <button className="btn btn-info btnDisplay" onClick={this.getRetailers}>Display</button>
                       </div>
                       <div className="btn-group btn-group-sm pull-right">
                       <button className="btn btn-warning " onClick={this.goBack}>Go Back</button>
                           <button className="btn btn-primary " onClick={this.printPreview}>Preview</button>
                       </div>
                       <hr className="hrsp" />
               </div>
       
               <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{padding:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                  Retailers For   {this.agentName(this.state.agent)} ({this.state.agent})
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 table-bordered">
                    <table className="table table-condensed">
                    {retailers.map(retailer => (
                        <tr>
                            <td>
                                <table className="table table-condensed table-striped">
                                    <tbody>
                                    <tr>
                                        <td width="120px" rowSpan="5">
                                            <div className="table-bordered text-center img img-rounded" style={{width:'100px', lineHeight:'120px'}}>
                                                <img src={this.config.ImagePath('custfiles') + '/' + retailer.username + '.' + retailer.filex} alt={retailer.username} style={{height:'120px',width:'100px'}} />
                                            </div>
                                        </td>
                                        <td width="100px">&nbsp;</td>
                                        <td width="150px">&nbsp;</td>
                                        <td width="100px">&nbsp;</td>
                                        <td width="150px">&nbsp;</td>
                                        
                                    </tr>
                                    <tr>
                                        <td className="text-right" width="100px">Retailer Name: </td>
                                        <td colSpan="3"><b>{retailer.fullname}</b></td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" width="100px">Retailer ID: </td>
                                        <td width="150px"><b>{retailer.username}</b></td>
                                        <td className="text-right" width="100px">Cash Bal.: </td>
                                        <td width="150px"><b>GH&cent; {numformat.format(retailer.cashinhand)}</b></td>
                                    </tr>
                                    <tr>
                                        <td className="text-right" width="100px">Phone No: </td>
                                        <td width="150px"><b>{retailer.mobile}</b></td>
                                        <td className="text-right" width="100px">Unit Bal.: </td>
                                        <td width="150px"><b>{numformat.format(retailer.unit)}</b></td>
                                    </tr>
                                    <tr>
                                    <td className="text-right" width="100px">Location: </td>
                                        <td width="150px"><b>{retailer.location}</b></td>
                                        <td className="text-right" width="100px">Terminal: </td>
                                        <td width="150px"><b>{retailer.imei}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        ))} 
                    </table>
                </div>

                            </div>
                                </div>
                         </div>
                        </div>
                    </div>
                </article>
            </div>
            )
        }
    }
}