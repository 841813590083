import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import axios from 'axios'

const integration = ['SMS','Email']

export default class SMSReciever extends Component{
    constructor(){
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{id:0, username:'', intType:'1', feactures:{}, 
            curuser: this.auth.getUser(), token: this.auth.getToken()},
            intTypes: [],
            scheduleTypes: [],
            users: []
        }
    }
    
    componentDidMount(){
        this.getDropdowns()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.getDropdowns()
        }
    }

    getDropdowns = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/user')
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let users = resdata.filter(rdata => ![5,6].includes(rdata.auth))

            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/int_type')
            .then(res => {
                let intTypes = this.auth.decryptData(res.data)

                this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/scheduleType')
                .then(res => {
                    let scheduleTypes = this.auth.decryptData(res.data)

                    this.setState({ intTypes, scheduleTypes, users })
                })
            })
        })
    }

    getUserReciever = (e) => {
        let username = e.target.value
        let formItems = {id:0, username:username, intType:'1', feactures:{}, curuser: this.auth.getUser(), token: this.auth.getToken()}

        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/receiver/' + username)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)
            
            if(reportData.id !== undefined){
                formItems = reportData
            }

            //alert(JSON.stringify(formItems))
            let features = (formItems.id > 0) ? JSON.parse(formItems.feactures) : ''
            formItems['feactures'] = features
            this.setState({ formItems })

            this.showData(features)
        })
    }

    handleChange = (e) => {
        let formItems = {...this.state.formItems};
        
        if(isNaN(e.target.name)){
            formItems[e.target.name] = e.tartet.value
        }else{
            if(formItems.feactures[e.target.name] === undefined){
                if(formItems.feactures == ''){formItems.feactures = {}}
                formItems.feactures[e.target.name] = [];
            }

            let index = formItems.feactures[e.target.name].findIndex(x=>x==e.target.value)

            if(index < 0){
                formItems.feactures[e.target.name].push(e.target.value)
            }else{
                formItems.feactures[e.target.name].splice(index,1)
                if(formItems.feactures[e.target.name].length < 1){
                    delete formItems.feactures[e.target.name]
                }
            }

            this.showData(formItems.feactures)
        }

        this.setState({ formItems })
    }

    submit = (e) => {
        e.preventDefault()
        let formItems = this.state.formItems
        if(formItems['curuser'] === undefined){
            formItems['curuser'] = this.auth.getUser()
        }

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        //alert(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/receiver'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data));
            }else{
                this.auth.doAlert(parseInt(res.data));
            }
            
        }).catch((error)=>{alert(error)})
    }

    showData = (fitm) => {
        let itm = ''

        itm = this.state.formItems.username !== '' && this.state.intTypes.map( it => (
            <article className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="jarviswidget" data-widget-editbutton="false" data-widget-custombutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-lg fa-square-o"></i> </span>
                        <h2>{it.iname} </h2>
                    </header>
                    <div>
                        <div class="widget-body">
                            <form id="frmReciever" className="smart-form" novalidate="novalidate" onSubmit="return false">
                            
                            <section>
                                {this.state.scheduleTypes.map( sche => {
                                    let ischecked = (fitm[it.ivalue] !== undefined && fitm[it.ivalue].includes(sche.ivalue))

                                    return(<label class="checkbox">
                                    <input type="checkbox" name={it.ivalue} value={sche.ivalue} checked={ischecked} 
                                    onChange={this.handleChange} />
                                    <i></i>{sche.iname}
                                    </label>)
                                })}
                            </section>
                            </form>
                        </div>
                    </div>
                </div>
            </article>))

            //alert(JSON.stringify(itm))

        ReactDOM.render(itm, document.getElementById('receiverTypeCon'))
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <button type="submit" className="btn btn-default pull-right" onClick={this.submit} form="frmReciever">
                        <i className="glyphicon glyphicon-save"></i> Save Receiver
                    </button>
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">User: </button>
                        <select className="btn btn-default" style={{width:'200px', height:'30px'}} 
                            value={this.state.formItems.username} onChange={this.getUserReciever} name="user">
                            <option value="">-- Select User --</option>
                            {this.state.users.map(stype =>(
                                <option value={stype.username}>{stype.firstname} {stype.lastname}</option>
                            ))}
                        </select>
                    </div>
                    
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <div id="receiverTypeCon"></div>
            </div>
        )
    }
}