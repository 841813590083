import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'

export default class AgentForm extends Component {
    constructor(props) {
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false,
            username: '',
            roles: [],
            agent:[],
            formItems: {
                fromLevel1: '', fromLevel3: '', toLevel1: '', toLevel3: '',
                fromDescription: '', toDescription: '', amount: '',batch:this.props.batch,
                createdby: this.auth.getUser(), createdon: new Date(),token: this.auth.getToken()
            }
        }
    }
componentWillUpdate(nextProps){
    if (this.props !== nextProps) {
        let agent = nextProps.agent
        this.setState({ agent: agent })
}
}
    
   
    render() {
        return (
            <div className="row">
               <div class="modal fade" id="agentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="false">
                    <div class="modal-dialog" role="document" style={{width:"60%",background:"white"}}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span>
                                </button>
                                <h5 class="modal-title" id="exampleModalLabel">Agent Transfer</h5>
                            </div>
                            <hr className="hrsp" />
                  <div className="row">
                  
                      <form id="frmUser" className="smart-form" onSubmit={this.formSubmit}>
                      {/* <div className="col-sm-12 col-md-12" style={{width: "80%", height: "200px",marginLeft:"30px", background: "white"}}>
                        */}
                        <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-4 text-right">Debit Account: </label>
                                <section className="col col-sm-8">
                                    <label className="select">
                                        <select name="auth" value={this.state.formItems.rolename} onChange={this.handleChange}>
                                            {this.state.agent.map(td => (
                                                <option value={td.username}>{td.fullname}</option>
                                            ))}
                                        </select> <i></i>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-4 text-right">Credit Account: </label>
                                <section className="col col-sm-8">
                                    <label className="select">
                                        <select name="auth" value={this.state.formItems.rolename} onChange={this.handleChange}>
                                        {this.state.agent.map(td => (
                                                <option value={td.username}>{td.fullname}</option>
                                            ))}
                                        </select> <i></i>
                                    </label>
                                </section>
                            </div>
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-4 text-right">Debit Description: </label>
                                <section className="col col-sm-8">
                                    <label className="input">
                                        <input type="text" name="username" placeholder="Debit Description" required
                                            value={this.state.formItems.username} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>

                           
                           
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-4 text-right">Credit Description: </label>
                                <section className="col col-sm-8">
                                    <label className="input">
                                        <input type="text" name="firstname" placeholder="Credit Description" required
                                            value={this.state.formItems.firstname} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>


                
                            <div className="col-xs-12  col-sm-6 col-md-6">
                                <label className="label col col-4 text-right">Amount: </label>
                                <section className="col col-8">
                                    <label className="input">
                                        <input type="text" name="pin" placeholder="Amount" required
                                            value={this.state.formItems.pin} onChange={this.handleChange} />
                                    </label>
                                </section>
                            </div>

                           
                        
                            
                                {/* </div> */}
                                <div className="col-sm-10">
                                <div className="btn-group btn-group-sm pull-right" style={{marginBottom:"10px"}}>
                                <button type="button" id="btncancel" class="btn btn-default" data-dismiss="modal">
                                        Cancel
			</button>
                                    <button type="submit" class="btn btn-primary">
                                        Transfer
			</button>
                                    
            </div>
            </div>
                        </form>
                        

 </div>
                    
                   
                 </div>
                    </div>
                </div>

            </div>
        )
    }
}
