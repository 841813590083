import React, { Component } from 'react';
import CashTransList from './CashTransList';

class CashierTransaction extends Component{
    constructor(){
        super();
    }

    componentDidMount = () => {

    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>Cashier Transactions</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="cashtrans_inner" className="widget-body"><CashTransList /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default CashierTransaction;