import React, { Component } from 'react';
import GlTransList from './GlTransList'

export default class Transactions extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-user"></i> </span>
                            <h2><strong>GL Transactions</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="gltrans_inner" className="widget-body"><GlTransList /></div>
                        </div>
                    </div>
                </article>
            </div>
       )
    }
}