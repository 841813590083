import React, { Component } from 'react';
import Config from '../Config';
import Auth from './Auth';
import moment from 'moment'

export default class Footer extends Component{

    constructor(){
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            companySetup:{
                companyName:'', address:'', phone:'', email:'', autologout:5, logo:'', mobileApi:'',
                curuser:this.auth.getUser(), token:this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        this.getCompanySetup()
    }

    getCompanySetup = () => {
        this.$http.get('admin/wapp/setup')
        .then(res => {
            let companySetup = this.auth.decryptData(res.data)
            
            this.setState({ companySetup })
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div class="page-footer">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                    <span class="txt-color-white"
                        >{this.state.companySetup.companyName}
                        <span class="hidden-xs"> - Lottery Management System</span> ©
                        2017-{moment().format('YYYY')}</span
                    >
                    </div>

                    <div class="col-xs-6 col-sm-6 text-right hidden-xs">
                    <div class="txt-color-white inline-block">
                        <i class="txt-color-blueLight hidden-mobile"
                        >Powered by <strong>Mojave</strong>
                        </i>
                    </div>
                    </div>
                </div>
            </div>
		
        )
    }
}