import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import { Link, BrowserRouter } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ProfileRoleForm from './ProfileRoleForm'
import ProfileForm from './ProfileForm'
import ReactDOM from 'react-dom'
import axios from 'axios'
import $ from 'jquery'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';
import Axios from 'axios';

export default class ProfileList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            roles: [],
            statuslist: [],
            reportData: [],
            filterItems: {role: 0},
            rowData: {},
            loader: false
        }
    }

    componentDidMount() {
        this.setState({ loader:true })
        this.getListItems()
    }

    componentDidUpdate(nextProps) {
      if(nextProps.role !== undefined && nextProps.role !== this.props.role){
          this.setState({ loader:true })
          let filterItems = this.state.filterItems
          filterItems['role'] = nextProps.role
          this.setState({ filterItems })
      }
    }

    getListItems = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/role')
        .then(res => {
            if(isNaN(res.data)){
                let roles = this.auth.decryptData(res.data)

                this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/status')
                .then(res => {
                    let statuslist = this.auth.decryptData(res.data)

                    this.setState({ roles, statuslist })

                    this.getReportData(0)
                })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getReportData = (role) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/user/' + role)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)
            
            this.setState({ reportData, loader: false })
        })
    }

    changeStatus = (user) => {

        user.status = (user.status >0)?0:1;
        let formData = this.auth.encryptData(JSON.stringify(user))
        Axios(this.config.PostOptions(formData, 'admin/wapp/user'))
        .then((res) => {
            alert('Profile status updated successfully...');

            this.componentDidMount();
        }).catch(error => {alert(error)});
    }

    handleChange = (e) => {
        let filterItems = this.state.filterItems
        filterItems[e.target.name] = e.target.value
        
        this.setState({ filterItems })
    }

    handleDisplay = () => {
        this.getReportData(this.state.filterItems.role)
    }
    
    nameFormat = (cell, row) => cell + ' ' + row.lastname
    authFormat = (cell, row) => {
        let role = this.state.roles.filter(role => role.id === cell)
        let rolename = (role[0] !== undefined) ? role[0].rolename : cell
        return rolename
    }
    
    statusFormat = (cell, row) => {
        let itm = this.state.statuslist.filter(status => status.ivalue == cell)
        let status = (cell == '0') ? 'bg-color-red' : 'bg-color-green';
        let online = (isNaN(row.token)) ? 'bg-color-green' : 'bg-color-red';
        let onlinetxt = (isNaN(row.token)) ? 'Online' : 'Offline';

        return (<div>
            <span className={'badge bounceIn animated ' + status} style={{cursor:'pointer'}} onClick={() => this.changeStatus(row)}> {itm[0].iname} </span>
            <span className={'badge bounceIn animated ' + online}> {onlinetxt} </span>
        </div>)
    }

    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            <button className="btn btn-default" title="Edit Profile" onClick={() => this.handleNewUser(cell)}>
                <i className="glyphicon glyphicon-edit"></i> Edit
            </button>
            <button className="btn btn-default" title="Change Password" data-toggle="modal" data-target="#changepassword" onClick={() => this.changePassword(row)} >
                <i className="glyphicon glyphicon-lock"></i> Password
            </button>
            <button className="btn btn-danger" title="Reset Password" onClick={() => this.resetPassword(row)} >
                <i className="glyphicon glyphicon-lock"></i> Reset Pass
            </button>
        </div>)
    }

    changePassword = (rowData) =>  this.setState({ rowData })
    resetPassword = (rowData) => {
        confirmAlert({
            title: 'Reset Password',
            message: 'This will reset the password for ' + rowData.firstname,
            buttons: [
              {
                label: 'Reset',
                onClick: () => {
                    this.setState({ loader:true })
                    rowData['curuser'] = this.auth.getUser()
                    rowData['token'] = this.auth.getToken()
                    let formData = this.auth.encryptData(JSON.stringify(rowData))
                    axios(this.config.PostOptions(formData, 'admin/wapp/resetpass'))
                    .then((res) => {
                        if(isNaN(res.data)){
                            let resdata = this.auth.decryptText(res.data)
                            alert(resdata)
                            this.setState({ loader:false })
                        }else{
                            this.auth.doAlert(parseInt(res.data))
                        }
                    }).catch((error)=>{alert(error)})
                }
              },
              {label: 'Cancel'}
            ]
          })
    }

    detailFormat = (cell, row) => {
        return(<BrowserRouter>
            <Link to='#' onClick={() => this.handleUserDetails(row)}>
                <span className="text-primary">{cell}</span>
            </Link>
        </BrowserRouter>)
    }

    handleNewRole = (row) => {
        let rowData = this.state.roles.filter(role => role.id == row)
        ReactDOM.render(
            <ProfileRoleForm rowData={rowData[0]} />,
            document.getElementById('profileCon')
        )
    }

    handleNewUser = (user) => {
        ReactDOM.render(
            <ProfileForm username={user} role={this.state.filterItems.role} />,
            document.getElementById('profileCon')
        )
    }

    handleUserDetails = (row) => {
        ReactDOM.render(
            <ProfileDetails user={row} />,
            document.getElementById('profileCon')
        )
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Profile Role: </button>
                            <select id="role" name="role" className="btn btn-default" style={{width:'150px'}}
                                value={this.state.filterItems.role} onChange={this.handleChange}>
                                <option value="0">All</option>
                                {this.state.roles.map(role => (
                                    <option value={role.id}>{role.rolename}</option>
                                ))}
                            </select>
                            <button className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                            {this.state.filterItems.role > 0 && 
                            <button className="btn btn-warning" onClick={() => this.handleNewRole(this.state.filterItems.role)}>
                                <i className="glyphicon glyphicon-edit"></i> Edit Role
                            </button>}
                            <button className="btn btn-info" onClick={() => this.handleNewRole(0)}>
                                <i className="glyphicon glyphicon-plus"></i> New Role
                            </button>
                            <button className="btn btn-info" onClick={() => this.handleNewUser('')}>
                                <i className="glyphicon glyphicon-plus"></i> New User
                            </button>
                        </div>
                        
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='username' isKey width="100px" dataSort dataFormat={this.detailFormat}>Code</TableHeaderColumn>
                            <TableHeaderColumn dataField='firstname' dataSort dataFormat={this.nameFormat}>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='mobile'width="120px"  dataSort >Mobile</TableHeaderColumn>
                            <TableHeaderColumn dataField='email' dataSort >Email</TableHeaderColumn>
                            <TableHeaderColumn dataField='auth' width="100px"  dataSort dataFormat={this.authFormat}>Role</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' dataSort dataFormat={this.statusFormat}>Status</TableHeaderColumn>
                            <TableHeaderColumn dataField='username' width="250px"  dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <ChangePassword rowData={this.state.rowData} />
                </div>
            )
        }
    }
}