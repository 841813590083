import React, { useEffect, useState } from "react";
import _ from 'lodash';
import ReactDOM from "react-dom";
import Winnings from "./Winnings";
import moment from "moment";
import Axios from "axios";
import Auth from "../common/Auth";
import Config from "../Config";
import WinsPaymentWriter from "./WinsPaymentWriter";
import WinPaymentConfirm from "./WinPaymentConfirm";
import WinsPaymentPdf from "../reports/Pdf/WinsPaymentPdf";

function WinsPayment(props){

    const [winsData, setWinsData] = useState([]);
    const [unpaidWins, setUnpaidWins] = useState([]);
    const [groupedWins, setGroupedWins] = useState({});
    const [operator, setOperator] = useState(props.operator);
    const [formItems, setFormItems] = useState(props.formItems);
    const [game, setGame] = useState(props.game);
    const [agents, setAgents] = useState(props.agents);
    const [groupAgents, setGroupAgents] = useState({});
    const [retailers, setRetailers] = useState(props.retailers);
    const [isApprover, setApprover] = useState(false);
    const [curAgent, setCurAgent] = useState('');
    const [tbStruct, setTbStruct] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const ISDN = [{'id':'MTN', 'name':'MTN'}, {'id':'VDF', 'name':'Vodafone'}, {'id':'ATL', 'name':'Airtel'}, {'id':'TIGO', 'name':'Tigo'}]
    const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    useEffect(() => {

        setOperator(props.operator);
        setFormItems(props.formItems);
        setAgents(props.agents);
        setGame(props.game);
        setRetailers(props.retailers);

        getInitData(props.agents);

        // eslint-disable-next-line
    },[props.operator, props.formItems, props.agents, props.game, props.retailers]);

    const getInitData = (agents) => {

        let groupAgents = _.groupBy(agents, 'username');
        setGroupAgents(groupAgents);

        $http.get('admin/wapp/' + auth.getUser() + '/' + auth.getToken() + '/approvers/2')
        .then(res => {
            let resdata = auth.decryptData(res.data)
            let approver = resdata.filter(rdata => rdata.approversPK.username == auth.getUser())

            setApprover((approver[0] !== undefined));
        })

        getWinsData();
    }

    const getWinsData = () =>{
        setLoading(true);

        let formData = auth.encryptData(JSON.stringify(formItems))
        Axios(config.PostOptions(formData, 'report/wapp/winnings'))
        .then((res) => {
            if (isNaN(res.data)) {
                let winsData = auth.decryptData(res.data);

                let unpaidWins = winsData.filter(wdata => wdata.payStatus == 0 && !(wdata.agentPaid || wdata.retailerPaid)),
                    groupedWins = _.groupBy(unpaidWins, 'agent');

                getDatatable(winsData);

                setGroupedWins(groupedWins);
                setUnpaidWins(unpaidWins);
                setWinsData(winsData);
            } else {
                auth.doAlert(parseInt(res.data))
            }   

            setLoading(false);        
        }).catch((error)=>{alert(error); setLoading(false);})
    }

    const makePayment = async (agent) => {
        if (window.confirm("This will send money to Agent(s)... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':agent, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(agent === 0){
                Promise.all(Object.keys(groupedWins).map(async key => {

                    winIds = groupedWins[`${key}`].filter(wdata => wdata.status == 1).map(wdata => wdata.id);

                    formData.winIds = winIds;
                    formData.agent = key;

                    await Axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
                    'admin/wapp/agent/payment'));
                })
                ).then(ress => {
                    alert("Payment was successful...");
                    setLoading(false);
                    getWinsData();
                });
            }else{
                winIds = groupedWins[`${agent}`].filter(wdata => wdata.status == 1).map(wdata => wdata.id);

                formData.winIds = winIds;

                await Axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
                'admin/wapp/agent/payment')) .then((res) => {
                    if (isNaN(res.data)) {
                        let resdata = auth.decryptText(res.data);

                        alert(JSON.stringify(resdata));
                    } else {
                        auth.doAlert(parseInt(res.data))
                    }  
                    setLoading(false);
                    getWinsData();
                }).catch((error)=>{alert(error);setLoading(false)});
                }
        }
    }

    const sendForApproval = async (agent) => {
        if (window.confirm("This will send Agent(s) wins payment for approval... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':agent, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(agent === 0){
                winIds = winsData.map(wdata => wdata.id);
            }else{
                winIds = groupedWins[`${agent}`].map(wdata => wdata.id);
            }

            formData.winIds = winIds;

            await Axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
            'admin/wapp/winPaymentApproval')) .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data));
                }  
                setLoading(false);
                getWinsData();
            }).catch((error)=>{alert(error);setLoading(false)});
        }
    }

    const sendForRejection = async (agent) => {
        if (window.confirm("This will reject payment for approval... Do you want to continue?") == true) {
            setLoading(true);

            let formData = {'winIds':[], 'agent':agent, 'createdBy': auth.getUser()}, //area = agent, retailer 
                winIds = [];

            if(agent === 0){
                winIds = winsData.map(wdata => wdata.id);
            }else{
                winIds = groupedWins[`${agent}`].map(wdata => wdata.id);
            }

            formData.winIds = winIds;

            await Axios(config.PostOptions(auth.encryptData(JSON.stringify(formData)), 
            'admin/wapp/winPaymentReject')) .then((res) => {
                if (isNaN(res.data)) {
                    let resdata = auth.decryptText(res.data);

                    alert(JSON.stringify(resdata));
                } else {
                    auth.doAlert(parseInt(res.data))
                }  
                setLoading(false);
                getWinsData();
            }).catch((error)=>{alert(error);setLoading(false)});
        }
    }

    const agentFormat = (cell, row) => {
        let agent = agents.filter(ag => ag.username == cell);
        
        return <span className="text-primary" role="button" onClick={() => showWriterWins(cell)}>
            {(agent.length > 0) ? `${cell} - ${agent[0].fullname}` : cell}
            </span>;
    }

    const getDatatable = (winsData) => {
        let tbStruct = [], groupedWins = _.groupBy(winsData, 'agent');

        tbStruct.push(<tr><th>Agent Name</th><th>Total Wins</th><th>Total Paid</th></tr>);
        Object.keys(groupedWins).map((key, idx)  => {
            tbStruct.push(<tr><td>{agentFormat(key, groupedWins[`${key}`])}</td><td>{numformat.format(_.sumBy(groupedWins[`${key}`],'winAmount'))}</td><td>{numformat.format(_.sumBy(groupedWins[`${key}`].filter(gw => gw.agentPaid),'winAmount'))}</td></tr>);
        });
        tbStruct.push(<tr><td>Total</td><td>{numformat.format(_.sumBy(winsData,'winAmount'))}</td><td>{numformat.format(_.sumBy(winsData.filter(gw => gw.agentPaid),'winAmount'))}</td></tr>);

        setTbStruct(tbStruct);
    }

    const showWriterWins = (agent) => {
        let agentOne = agents.filter(ag => ag.username == agent)

        ReactDOM.render(
            <WinsPaymentWriter agent={agentOne[0]} formItems={formItems} agents={agents} 
                game={game} operator={operator} retailers={retailers} />, 
            document.getElementById('winListCon')
        );
    }

    const doPreview = () => ReactDOM.render(
            <WinsPaymentPdf rowData={tbStruct} data={{'game':game,
                'drawDate': formItems.startDate}}
                operator={operator} formItems={formItems} game={game}
                agents={agents} retailers={retailers}  />,
            document.getElementById('winListCon')
        );

    const goBack = () => ReactDOM.render(<Winnings />, document.getElementById('winListCon'));

    if(isLoading){
        return <div id="loader"></div>;
    }

    return(
        <div className="row">
            <article className="col-xs-12">
                <div className="row">
                    <div className="col-xs-8">
                        <table className="table table-striped table-responsive">
                            <tbody>
                                <tr><td>Operator: <b>{operator.fullname}</b></td></tr>
                                <tr><td>Game: <b>{game.gamename}</b></td></tr>
                                <tr><td>Draw Date: <b>{moment(formItems.startDate).format('DD MMM, YYYY')}</b></td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-4">
                        <button className="btn btn-sm btn-primary" onClick={goBack}><i className="fa fa-backward" /> Back</button>
                        <button className="btn btn-sm btn-info" onClick={doPreview}><i className="fa fa-print" /> Preview</button>
                    </div>
                </div>
            </article>
            <article className="col-xs-12">
                <table className="table table-striped table-condensed">
                    <thead>
                        <tr>
                            <th>Agent Name</th>
                            <th style={{textAlign:'right',width:'150px'}}>Total Wins</th>
                            <th style={{textAlign:'right',width:'150px'}}>Pending Approval</th>
                            <th style={{textAlign:'right',width:'250px'}}>
                                {unpaidWins.filter(gw => gw.status == 0).length > 0 &&
                                <button className="btn btn-sm btn-warning" onClick={() => sendForApproval(0)}><i className="fa fa-money" /> Pay All</button>}
                                {unpaidWins.filter(gw => gw.status == 1).length > 0 &&  isApprover &&
                                <button className="btn btn-sm btn-danger" onClick={() => setCurAgent(0)} 
                                    data-toggle="modal" data-target="#confirmWins">
                                    <i className="glyphicon glyphicon-check" /> Approve All
                                </button>}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedWins).map((key, idx) => 
                        <tr>
                            <td>{agentFormat(key, groupedWins[`${key}`])}</td>
                            <td style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(groupedWins[`${key}`],'winAmount'))}</td>
                            <td style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(groupedWins[`${key}`].filter(gw => gw.status == 1),'winAmount'))}</td>
                            <td style={{textAlign:'right'}}>
                                {groupedWins[`${key}`].filter(gw => gw.status == 0).length > 0 &&
                                <button className="btn btn-xs btn-warning" onClick={() => sendForApproval(key)}><i className="fa fa-money" /> Pay</button>}
                                {groupedWins[`${key}`].filter(gw => gw.status == 1).length > 0 && isApprover &&
                                <button className="btn btn-xs btn-danger" onClick={() => setCurAgent(key)} 
                                    data-toggle="modal" data-target="#confirmWins">
                                    <i className="glyphicon glyphicon-check" /> Approve
                                </button>}
                            </td>
                        </tr>)}
                        <tr>
                            <th>Total</th>
                            <th style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(unpaidWins,'winAmount'))}</th>
                            <th style={{textAlign:'right',width:'150px'}}>{numformat.format(_.sumBy(unpaidWins.filter(gw => gw.status == 1),'winAmount'))}</th>
                            <th style={{textAlign:'right'}}>&nbsp;</th>
                        </tr>
                    </tbody>
                </table>
            </article>

            <WinPaymentConfirm 
                agent={curAgent} 
                groupedWins={groupedWins} 
                unpaidWins={unpaidWins} 
                makePayment={makePayment} 
                sendForRejection={sendForRejection} />
        </div>
    );
}

export default WinsPayment