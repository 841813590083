import React, { Component } from 'react';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Link, BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import _ from 'lodash'
import { Table } from "semantic-ui-react"
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import ReportView from './ReportView';
import AgentSummaryPdf from './Pdf/AgentSummaryPdf';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2})
const lineb = { borderBottom:'1px solid #cccccc', padding:'5px'}
const linehb = {borderBottom:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px'}
const linehbl = {borderBottom:'1px solid #cccccc', borderLeft:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const linebr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px'}
const linehbr = { borderBottom:'1px solid #cccccc', borderRight:'1px solid #cccccc', padding:'5px', fontSize:'14px', fontWeight:'bold'}
const title = {
    border:'1px solid #000000',
    textAlign:'center',
    padding:'5px',
    fontSize:'18px',
    fontWeight:'bold'
}
const heading = {
    fontSize:'14px',
    fontWeight:'bold'
}

export default class AgentSummary extends Component {
    constructor() {
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: {
                operator:'', startDate: moment(), endDate: moment(),
                curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            totals:{gross:0, wins:0, net:0},
            operators: [],
            selectedOperator:{},
            agents: [], 
            retailers: [],
            games:[],
            loader: false,
            tbStruct: '',

            PreviewData:[],
            topData:[],
            agentList:[],
            PreviewName:'',
            tickets:[],

            batchData: [], 
            location:0, 
            goperator: '',  batch: 1, userTill: [], client: [],
            systemDefine :(this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1,

            startDate: moment(),
            endDate: moment()
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        this.getOperators()
    }

    componentDidUpdate(nextProps) {
        if (nextProps !== this.props) {
            this.setState({ loader: true })
            this.getOperators()
        }
    }

    getOperators = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + this.state.systemDefine)
        .then(res => {
            if (isNaN(res.data)) {
                let operators = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                let selectedOperator = this.state.selectedOperator

                if(operators[0] != undefined){
                    selectedOperator = operators[0]
                    formItems['operator'] = operators[0].username
                    this.getAgents(operators[0].username)
                }
                
                this.setState({ formItems, operators, selectedOperator })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails_rep/' + operator)
        .then(res => {
            if (isNaN(res.data)) {
                let agents = this.auth.decryptData(res.data)
                
                this.setState({ agents})
                this.getGames()
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getGames = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let games = this.auth.decryptData(res.data)
            this.setState({ games, loader: false})

            //this.getReport()
        })
    }
    
    handleChange = (e) => {
        let selectedOperator = this.state.selectedOperator
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value

        if(e.target.name == 'operator'){
            let operator = this.state.operators.filter(op => op.username == e.target.value)
            selectedOperator = (operator[0] !== undefined) ? operator[0] : selectedOperator
        }

        this.setState({ selectedOperator, formItems })

        this.getReport()
    }

    getReport = () => {
        this.setState({ loader: true })
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agent/' + this.state.formItems.operator)
        .then(res => {
            if (isNaN(res.data)) {
                let agents = this.auth.decryptData(res.data)
            
                let formItems = this.state.formItems
                let formData = this.auth.encryptData(JSON.stringify(formItems))
                axios(this.config.PostOptions(formData, 'report/wapp/gamesalesbydate'))
                .then((res) => {
                    if(isNaN(res.data)){
                        let resdata = this.auth.decryptData(res.data)

                        let tickets = _.groupBy(resdata, 'agent');
                    

                        //alert(JSON.stringify(tickets));
                        this.setState({ tickets, agents, loader: false })

                        ReactDOM.render(this.showAgentSummary(tickets, agents), 
                            document.getElementById('agentSummary_Inner'))
                        
                    }else{
                        this.auth.doAlert(parseInt(res.data))
                    }
                }).catch((error)=>{alert(error); this.setState({ loader: false });})
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    doPreview=()=>{
        /*var divToPrint=document.getElementById("agentSummary_Inner");
        let newWin= window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close()*/
        let formItems = this.state.formItems
        let totals = this.state.totals

        let data = {
            operator:this.state.selectedOperator.fullname + ' ('+formItems.operator +')', 
            startDate:formItems.startDate, gross: numformat.format(totals.gross),
            wins: numformat.format(totals.wins), net: numformat.format(totals.net),
            endDate:formItems.endDate
        }

        ReactDOM.render(
            <AgentSummaryPdf rowData={this.state.tbStruct} data={data} />,
            document.getElementById('generalReport_inners')
        )
    }

    showAgentSummary = (tickets, agents) => {
        let reportData = {};

        Object.keys(tickets).map(tick => {
            let groups = _.groupBy(tickets[tick], 'gamecode')

            reportData[tick] = _.map(groups, function(value, key) {
                return { 
                    game:key,
                    winTotal: _.reduce(value, function(total, o) { 
                        return total += o.winTotal;
                    }, 0),
                    ticketTotal: _.reduce(value, function(total, o) { 
                        return total += o.ticketTotal;
                    }, 0) 
                }
            })
        })

        let itm = []
        let pdfitm = []
        let totalGross = 0
        let totalNets = 0
        let totalWins = 0
        Object.keys(reportData).map(key => {
            let lineGross = 0, lineNets = 0, lineWins = 0
            itm.push(<tr><td></td><td></td><td></td><td></td><td></td><td></td></tr>)
            itm.push(<tr><td></td><td colspan="5" style={title}>{this.agentFormat(key,agents)}</td></tr>)
            itm.push(<tr><td></td><td></td>
            <td width="120px" className="text-right" style={linehbl}>Gross</td>
            <td width="120px" className="text-right" style={linehb}>Nets</td>
            <td width="120px" className="text-right" style={linehb}>Wins</td>
            <td width="120px" className="text-right" style={linehbr}>Pay In/Out</td></tr>)

            reportData[key].map(rdata => {
                let net = rdata.ticketTotal - (rdata.ticketTotal * .2)
                let win = (isNaN(rdata.winTotal)) ? 0.00 : rdata.winTotal
                let pay = net - win
                let payinout = (pay < 0) ? '(' + numformat.format(Math.abs(pay)) + ')' : numformat.format(pay)

                totalGross += rdata.ticketTotal
                totalNets += net
                totalWins += win
                lineGross += rdata.ticketTotal
                lineNets += net
                lineWins += win

                itm.push(<tr><td className="table-bordered">{this.gameFormat(rdata.game, rdata)}</td>
                <td></td>
                <td className="text-right" style={lineb}>{numformat.format(rdata.ticketTotal)}</td>
                <td className="text-right" style={lineb}>{numformat.format(net)}</td>
                <td className="text-right" style={lineb}>{numformat.format(win)}</td>
                <td className="text-right" style={linebr}>
                    {(pay < 0) ? <b className="text-danger">{payinout}</b> : <b className="text-success">{payinout}</b>}
                </td></tr>)
            })

            let linepay = lineNets - lineWins
            let linepayinout = (linepay < 0) ? '(' + numformat.format(Math.abs(linepay)) + ')' : numformat.format(linepay)
            itm.push(<tr><td className="table-bordered text-right" style={heading}>Line Total: </td>
            <td></td>
                <td className="text-right" style={linehb}>{numformat.format(lineGross)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineNets)}</td>
                <td className="text-right" style={linehb}>{numformat.format(lineWins)}</td>
                <td className="text-right" style={linehbr}>
                    {(linepay < 0) ? <b className="text-danger">{linepayinout}</b> : <b className="text-success">{linepayinout}</b>}
                </td></tr>)
            itm.push(<tr><td colSpan="6">&nbsp;</td></tr>)
        })

        let totals = {gross:totalGross, wins:totalWins, net:totalNets}
        this.setState({ totals, tbStruct: itm })
        
        return(
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{paddingTop:"5px",fontSize:"18px",textTransform:"uppercase"}}>
                    Agents Pay In/Out Summary
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>
                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                    <table id="" className="table table-condensed table-responsive table-striped">
                        <tbody>
                            <tr>
                                <td width="100px" align="right">Operator: </td>
                                <td width="150px"><b>{this.state.selectedOperator.fullname} ({this.state.selectedOperator.username})</b></td>
                                <td width="100px" align="right">Total Gross: </td>
                                <td width="150px"><b>{numformat.format(totalGross)}</b></td>
                            </tr>
                          
                            <tr>
                                <td width="100px" align="right">Start Date: </td>
                                <td width="150px"><b>{this.state.startDate.format('MMMM D, YYYY')}</b></td>
                                <td width="100px" align="right">Total Wins: </td><td width="150px"><b>{numformat.format(totalWins)}</b></td>
                            </tr>
                            <tr>
                                <td width="100px" align="right">End Date: </td>
                                <td width="150px"><b>{this.state.endDate.format('MMMM D, YYYY')}</b></td>
                                <td width="100px" align="right">Total Net: </td><td width="150px"><b>{numformat.format(totalNets)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-xs-12"><hr className="hrsp" /></div>

                <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2" id="games">
                    <table className="table table-responsive table-condensed">
                        {itm}
                    </table>
                </div>
            </div>
        )
    }
    commissionFormat = (cell, row) => numformat.format((cell * 0.10) + (cell * 0.20))
    netFormat = (cell, row) => numformat.format(cell - (cell * 0.10) - (cell * 0.20))
    curFormat = (cell, row) => numformat.format(cell)
    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY @ hh:mm:ss a')
    gameFormat = (cell, row) => {
        let game = this.state.games.filter(gm => gm.id == cell)
        let gamename = (game[0]!==undefined) ? game[0].gamename : cell
        
        return gamename
    }
    agentFormat = (cell, row) => {
        let agent = row.filter(gm => gm.username == cell)
        let agentname = (agent[0]!==undefined) ? agent[0].firstname + ' ' + agent[0].lastname : cell
        
        return agentname
    }

    handleEvent = (event, picker) => {
        //alert(JSON.stringify(event))
        let formItems=this.state.formItems
        formItems['startDate']=picker.startDate
        formItems['endDate']=picker.endDate
		this.setState({ startDate: picker.startDate, endDate: picker.endDate,formItems});
    }
    
    goBack= () => {
        ReactDOM.render(
            <ReportView />,
            document.getElementById('generalReport_inners')
        )
    }
   
    loading = () => <div id="loader"></div>

    render() {
        if (this.state.loader) {
            return this.loading()
        } else {
            let ranges= {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            }
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;
            if (start === end) {label = start;}
            
            return (
                <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-book"></i> </span>
                            <h2><strong>Agent Summary Report</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="btn-group btn-group-sm">

                                    <button className="btn btn-default">Operator: </button>
                                    <select id="operator" name="operator" className="btn btn-default form-control" style={{ width: '200px', height:'30px' }}
                                        onChange={this.handleChange} value={this.state.formItems.operator} >
                                        {this.state.operators.map(op => (
                                            <option value={op.username}>{op.fullname}</option>
                                        ))}
                                    </select>

                                    <button className="btn btn-default">Draw Date Range: </button>
                                    <DatetimeRangePicker
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        ranges={ranges}
                                        onApply={this.handleEvent}
                                        className="selected-date-range-btn btn btn-default">
                                        <div className="pull-left">
                                            <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                                        </div>
                                        <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                                    </DatetimeRangePicker>
                                    
                                    <button onClick={this.getReport} className="btn btn-info">Display</button>
                                    
                                </div>
                                <div className="btn-group btn-group-sm pull-right" >
                                    <button className="btn btn-warning " id="back" onClick={this.goBack}>Go Back</button>
                                    <button className="btn btn-primary " onClick={this.doPreview}>Preview</button>
                                </div>
                                <div className="col-xs-12"><hr className="hrsp" /></div>  
                                <div id="agentSummary_Inner"></div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
                
            )
        }
    }
}
