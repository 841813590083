import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'

const pageTitle = {1:'Operator', 2:'Agent', 3:'Retailer', 4:'User'}

export default class UserTypeForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            usertype: this.props.usertype,
            user: this.props.user,
            formItems:{
                username:'', usertype:'', superior:'', bankname: '',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            },
            operators:[],
            agents:[]
        }
    }

    componentDidMount(){
        this.getOperators()
        this.getAgents()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.usertype !== this.props.usertype){
            let usertype = nextProps.usertype
            let user = nextProps.user

            this.setState({ usertype, user })

            this.getOperators()
            this.getAgents()
        }
    }

    getOperators = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/1')
        .then(res => {
            let operators = this.auth.decryptData(res.data)
            //alert(JSON.stringify(operators))
            this.setState({ operators })
        })
    }

    getAgents = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/2')
        .then(res => {
            let agents = this.auth.decryptData(res.data)

            this.setState({ agents })
        })
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        formItems['username'] = this.state.user.username
        formItems['usertype'] = this.state.usertype
        if((this.state.usertype === '2' || this.state.usertype === '4') && formItems.superior === ''){
            formItems['superior'] = this.state.operators[0].username
        }else if(this.state.usertype === '3' && formItems.superior === ''){
            formItems['superior'] = this.state.agents[0].username
        }

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/enableusertype'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btncancel').click()
                $('#btnProfileBack').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade" id="usertypeform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">New {pageTitle[this.state.usertype]}</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmUserType" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    {this.props.usertype === '1' &&
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Bank name: </label>
                                        <section className="col col-9">
                                            <label className="input">
                                                <input type="text" name="bankname" placeholder="Name of Bank"  
                                                value={this.state.formItems.bankname} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>}
                                    {(this.props.usertype === '2' || this.props.usertype === '4') &&
                                    <div className="col-xs-12  col-md-8">
                                        <label className="label col col-3 text-right">Operator: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="superior" value={this.state.formItems.superior} onChange={this.handleChange}>
                                                    {this.state.operators.map(op => (
                                                        <option value={op.username}>{op.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>}
                                    {this.props.usertype === '3' &&
                                    <div className="col-xs-12  col-md-8">
                                        <label className="label col col-3 text-right">Agent: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="superior" value={this.state.formItems.superior} onChange={this.handleChange}>
                                                    {this.state.agents.map(ag => (
                                                        <option value={ag.username}>{ag.fullname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btncancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmUserType" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}