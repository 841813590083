import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import ReactDOM from 'react-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import GameSetupForm from './GameSetupForm';

const weekday = {0: 'All Days', 1:'Monday', 2:'Tuesday', 3:'Wednesday', 4:'Thursday', 5:'Friday', 6:'Saturday', 7:'Sunday' }

export default class GameSetupList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            rowData: {},
            gametypes: [],
            loader: false
        }
    }

    componentDidMount(){
        this.setState({ loader:true })

        this.getDropdowns()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            this.setState({ loader:true })

            this.getDropdowns()
        }
    }

    getDropdowns = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/gametype')
        .then(res => {
            let gametypes = this.auth.decryptData(res.data)

            this.setState({ gametypes })

            this.getReportData()
        })
    }

    getReportData = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/game')
        .then(res => {
            let reportData = this.auth.decryptData(res.data)

            this.setState({ reportData, loader:false })
        })
    }

    showGameSetupForm = (rowData) => {
        ReactDOM.render(
            <GameSetupForm rowData={rowData} />,
            document.getElementById('gameSetupCon')
        )
    }

    statusFormat = (cell, row) => {
        let itm = <div className="btn-group btn-group-xs">
            {cell == 1 ? <button className='btn btn-xs btn-success' >POS</button>
            :<button className='btn btn-xs btn-danger' >POS</button>}
            {row.showOnWeb == 1 ? <button className='btn btn-xs btn-success' >WEB</button>
            :<button className='btn btn-xs btn-danger' style={{padding:'0 15px'}} >WEB</button>}
            {row.showOnUssd == 1 ? <button className='btn btn-xs btn-success' >USSD</button>
            :<button className='btn btn-xs btn-danger' >USSD</button>}
            {row.showOnClient == 1 ? <button className='btn btn-xs btn-success' >APP</button>
            :<button className='btn btn-xs btn-danger' >APP</button>}
        </div>
        

        return  itm
    }
    rangeFormat = (cell, row) => <span>{row.minNo} to {row.maxNo}</span>
    dayFormat = (cell, row) => <span>{weekday[cell]}</span>
    timeFormat = (cell, row) => <span>{moment(cell).format('hh:mm:ss A')}</span>
    gtypeFormat = (cell, row) => {
        let gametype = this.state.gametypes.filter(gtype => gtype.ivalue == cell)
        let gtypename = (gametype[0] !== undefined) ? gametype[0].iname : cell

        return gtypename
    }
    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            <button className="btn btn-default" title="Edit Game Setup" onClick={() => this.showGameSetupForm(row)}>
                <i className="glyphicon glyphicon-edit"></i> Edit
            </button>
        </div>)
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-info" onClick={() => this.showGameSetupForm('{}')}>
                                <i className="glyphicon glyphicon-plus"></i> New Game Setup
                            </button>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='id' isKey dataSort width="100px" >#</TableHeaderColumn>
                            <TableHeaderColumn dataField='gamename' dataSort>Name of Game</TableHeaderColumn>
                            <TableHeaderColumn dataField='gametype' dataSort dataFormat={this.gtypeFormat}>Game Type</TableHeaderColumn>
                            <TableHeaderColumn dataField='drawday' dataSort dataFormat={this.dayFormat}>Draw Day</TableHeaderColumn>
                            <TableHeaderColumn dataField='endtime' dataSort dataFormat={this.timeFormat}>Close Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='minNo' dataSort dataFormat={this.rangeFormat}>Range</TableHeaderColumn>
                            <TableHeaderColumn dataField='showOnApp' dataSort dataFormat={this.statusFormat}>Status</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataSort dataFormat={this.actionFormat}>Action</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            )
        }
    }
}