import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import BetSetupForm from './BetSetupForm';

export default class BetSetupList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            rowData: {},
            reportData: []
        }
    }

    componentDidMount(){
        this.setState({ loader:true })

        this.getReportData()
    }

    getReportData = () => {
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/betting')
        .then(res => {
            let reportData = this.auth.decryptData(res.data)

            this.setState({ reportData, loader:false })
        })
    }

    setRowData = (rowData) => this.setState({ rowData })

    actionFormat = (cell, row) => {
        return(
            <div className='btn-group btn-group-xs'>
                <button className='btn btn-default' data-toggle="modal" data-target="#betsetupform" onClick={() => this.setRowData(row)}>
                    <i className='fa fa-edit'></i> Edit
                </button>
            </div>
        )
    }
    statusFormat = (cell, row) => {
        let itm = <div className="btn-group btn-group-xs">
            {cell == 1 ? <button className='btn btn-xs btn-success' >POS</button>
            :<button className='btn btn-xs btn-danger' >POS</button>}
            {row.showOnWeb == 1 ? <button className='btn btn-xs btn-success' >WEB</button>
            :<button className='btn btn-xs btn-danger' style={{padding:'0 15px'}} >WEB</button>}
            {row.showOnUssd == 1 ? <button className='btn btn-xs btn-success' >USSD</button>
            :<button className='btn btn-xs btn-danger' >USSD</button>}
            {row.showOnClient == 1 ? <button className='btn btn-xs btn-success' >APP</button>
            :<button className='btn btn-xs btn-danger' >APP</button>}
        </div>
        

        return  itm
    }
    multiplyFormat = (cell, row) => 'x' + cell
    minumFormat = (cell, row) => cell + ' / ' + row.maxNo
    minamountFormat = (cell, row) => cell + ' / ' + row.maxStakeAmount

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12">
                        <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                            <TableHeaderColumn dataField='betname' isKey dataSort>Bet Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='multiplier' dataSort dataFormat={this.multiplyFormat}>Multiplier</TableHeaderColumn>
                            <TableHeaderColumn dataField='minNo' dataSort dataFormat={this.minumFormat}>Min/Max Stake #</TableHeaderColumn>
                            <TableHeaderColumn dataField='minStakeAmount' dataSort  dataFormat={this.minamountFormat}>Min/Max Stake Amt</TableHeaderColumn>
                            <TableHeaderColumn dataField='showOnApp' dataSort dataFormat={this.statusFormat}>Status</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataFormat={this.actionFormat} />
                        </BootstrapTable>
                    </div>
                    <BetSetupForm rowData={this.state.rowData} />
                </div>
            )
        }
    }
}