import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import moment from 'moment'
import ReactDOM from 'react-dom'
import SendNewSMS from './SendNewSMS'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

export default class SMSLists extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            smslogs: [],
            users: []
        }

    }

    componentDidMount(){
        this.getSMSLogs()
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            this.getSMSLogs()
        }
    }

    getSMSLogs = () => {
        this.$http.get('admin/wapp/user')
        .then(res => {
            let users = this.auth.decryptData(res.data)

            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/smslogs')
            .then(res => {
                let smslogs = this.auth.decryptData(res.data)

                this.setState({ users, smslogs })
            })
        })
    }

    newMessage = () => {
        ReactDOM.render(<SendNewSMS />, 
            document.getElementById('smsListCon'))
    }

    dateFormat = (cell, row) => moment(cell).format('DD MMM, YYYY')
    userFormat = (cell, row) => {
        let user = this.state.users.filter(us => us.username == cell)
        let username = (user[0] === undefined) ? user[0].firstname + ' ' + user[0].lastname : cell

        return username
    }
    statusFormat = (cell, row) => (cell == 1) ? 'Success' : 'Failed'
    
    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-default" onClick={this.newMessage}>
                            <i className="glyphicon glyphicon-plus"></i> New Message
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12">
                    <BootstrapTable data={this.state.smslogs} striped hover condensed pagination>
                        <TableHeaderColumn dataField='createdon' dataFormat={this.dateFormat} isKey dataSort>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='sendTo' dataSort>Send To</TableHeaderColumn>
                        <TableHeaderColumn dataField='message' dataSort>Message</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdby' dataFormat={this.userFormat} dataSort>Created By</TableHeaderColumn>
                        <TableHeaderColumn dataField='status' dataFormat={this.statusFormat} dataSort>Status</TableHeaderColumn>
                    </BootstrapTable>
                </article>
            </div>
        )
    }
}