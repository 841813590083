import React, { Component } from "react";
import LiveFeeds from "./LiveFeeds";
import BirdsEye from "./BirdsEye";
import LiveWins from "./LiveWins";
import ActiveGames from "./ActiveGames";
import UnitTransfer from "./UnitTransfer";
import Auth from "../common/Auth";
import Config from "../Config";
import $ from "jquery";

export default class Dashboard extends Component {
  constructor() {
    super()
    
    this.auth = new Auth()
    this.config = new Config()
    this.$http = this.config.DConnect()

    this.state = {
    }
  }

  componentDidMount(){
    
  }

  render() {
    return (
      <section id="widget-grid" className="">
        <div className="row">
          <article className="col-sm-12">
            <div
              className="jarviswidget"
              id="wid-id-0"
              data-widget-togglebutton="false"
              data-widget-editbutton="false"
              data-widget-fullscreenbutton="false"
              data-widget-colorbutton="false"
              data-widget-deletebutton="false"
            >
              <header>
                <span className="widget-icon">
                  {" "}
                  <i className="glyphicon glyphicon-stats txt-color-darken" />{" "}
                </span>
                <h2>Game Statistics </h2>

                <ul className="nav nav-tabs pull-right in" id="myTab">
                  <li className="active">
                    <a data-toggle="tab" href="#s1">
                      <i className="fa fa-clock-o" />{" "}
                      <span className="hidden-mobile hidden-tablet">
                        Live Stakes
                      </span>
                    </a>
                  </li>

                  {/*<li>
                    <a data-toggle="tab" href="#s2">
                      <i className="fa fa-facebook" />{" "}
                      <span className="hidden-mobile hidden-tablet">
                        Unit Transfer
                      </span>
                    </a>
                  </li>

                  <li>
                    <a data-toggle="tab" href="#s3">
                      <i className="fa fa-dollar" />
                      <span className="hidden-mobile hidden-tablet">
                        Live Wins
                      </span>
                    </a>
                  </li>*/}
                </ul>
              </header>

              <div className="no-padding">
                <div className="jarviswidget-editbox">test</div>

                <div className="widget-body">
                  <div id="myTabContent" className="tab-content">
                    <LiveFeeds />
                    {/*<UnitTransfer />
                    <LiveWins />*/}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div className="row">
          <ActiveGames gametype={1}  />

          <ActiveGames gametype={2}  />
          
        </div>
        <div className="row">
          <BirdsEye />
        </div>
      </section>
    );
  }
}
