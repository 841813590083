import React, { Component } from 'react';
import { Link, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import QueryJson from '../common/QueryJson'
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import WinTicketDetails from './WinTicketDetails'
import WinsPayment from './WinsPayment';
import WinsPdf from '../reports/Pdf/WinsPdf';
import _ from 'lodash';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class Winnings extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth();
        this.config = new Config();
        this.$http = this.config.DConnect();

        this.state = {
            operators:[], agents:[], retailers:[], game:[], operator:'',
            formItems: { operator: '', username: '', game: '', startDate:moment().format('YYYY-MM-DD'), 
            curuser: this.auth.getUser(), token: this.auth.getToken(), agent:''},
            winsData: [], ticketSerial: '', loading: false, tbStruct:[],
        }
    }

    componentDidMount(){
        this.setState({ loader:true })
        let systemDefine = (this.auth.getRole() == "Admin" || this.auth.getRole() == "Super") ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
        
        this.getOperators(systemDefine)
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            
        }
    }

    getOperators = (systemDefine) => {
        if (systemDefine != undefined) {
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res   => {
                if(isNaN(res.data)){
                    let operators = this.auth.decryptData(res.data)
                    let operator = (operators[0] != undefined) ? operators[0].username : operators.username
                    let formItems = this.state.formItems
                    formItems['operator'] = operator
                
                    this.setState({ operators, formItems })
                    this.getGame(formItems.startDate)
                    this.getAgents(operator)
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }

            })
        }
    }

    getAgents = (operator) => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/agentdetails/' + operator)
            .then(res => {
                let agents = this.auth.decryptData(res.data)
                // let agents = resdata.filter(rdata => rdata.usertype == 2)
                
                // alert(JSON.stringify(agents))
                this.setState({ agents, loader: false})
                this.getRetailer()
            })
    }

    getRetailer = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/3')
            .then(res => {
                if (isNaN(res.data)) {
                    let retailers = this.auth.decryptData(res.data)
                   
                    this.setState({ retailers })
                } else {
                    this.auth.doAlert(parseInt(res.data))
                }
            })
    }

    getGame = (drawDate) =>{
        this.$http.get('game/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/gamebydrawdate/' + encodeURIComponent(drawDate))
        .then(res => {
            let game = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            formItems['game'] = (game[0] != undefined) ? game[0].id : game.id
            this.setState({ game, loader:false })
        })
    }

    getGamename=(code)=>{
        let game = new QueryJson(this.state.game, 'id=' + code)
        return (game[0]!=undefined)?game[0].gamename:""
    }

    retailerFomat=(cell,row)=>{
        let retailer = new QueryJson(this.state.retailers, 'username=' + cell)
        return (retailer[0]!=undefined)?cell + ' - ' + retailer[0].fullname: cell
    }

    getWinsData = () =>{
        this.setState({loading:true});
        let formItems = {...this.state.formItems};

        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'report/wapp/winnings'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = this.auth.decryptData(res.data),
                    curAgent = this.state.formItems.agent,
                    winsData = (curAgent === '') ? resdata :  resdata.filter(rdata => rdata.agent === curAgent);

                this.getDatatable(winsData);

                this.setState({ winsData, loader:false })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }   
            this.setState({loading:false});         
        }).catch((error)=>{alert(error); this.setState({loading:false})})
    }

    getDatatable = (reportData) => {
        let { rate } = this.state;
        let tbStruct = [], totalStake = _.sumBy(reportData, 'amount');;

        tbStruct.push(<tr><th>Ticket #</th><th>Agent Name</th><th>Retailer</th><th>Total Cost</th><th>Win Amount</th></tr>);
        reportData.map(rdata => {
            tbStruct.push(<tr><td>{rdata.ticketSerial}</td><td>{this.agentFormat(rdata.agent, rdata)}</td><td>{this.retailerFomat(rdata.retailer, rdata)}</td><td>{numformat.format(rdata.lineTotal)}</td><td>{numformat.format(rdata.winAmount)}</td></tr>);
        });
        tbStruct.push(<tr><td>Total</td><td></td><td></td><td>{numformat.format(_.sumBy(reportData, 'lineTotal'))}</td><td>{numformat.format(_.sumBy(reportData, 'winAmount'))}</td></tr>);

        this.setState({ tbStruct })
    }

    curFormat = (cell, row) => numformat.format(cell)
    multiplyFormat = (cell, row) => 'x' + cell
    betFormat = (cell, row) => {
        let bets = this.state.bets.filter(bt => bt.id == cell)
        let betname = (bets[0]!==undefined)?bets[0].betname : cell

        return betname
    }
    agentFormat=(cell, row)=>{
        let agent = new QueryJson(this.state.agents, 'username=' + cell)
        return (agent.length > 0) ? `${cell} - ${agent[0].fullname}` :""
    }
    formatAgent=(cell, row)=>{
        let agent = new QueryJson(this.state.agents, 'username=' + cell)
        return (agent.length > 0) ? `${agent[0].fullname}` :""
    }
    ticketFormat = (cell, row) => {
        return(<BrowserRouter>
            <Link to='#' onClick={() => this.showTicketDetails(cell)}>
                <span className="text-primary" data-toggle="modal" data-target="#winTicketDetails" >{cell}</span>
            </Link>
        </BrowserRouter>)
    }

    showTicketDetails = (ticketSerial) => {
        this.setState({ ticketSerial })
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let fitm = this.state.formItems
        fitm[e.target.name] = ptype
        this.setState({ formItems: fitm })
        if(e.target.name == 'startDate'){
            this.getGame(ptype)
        }
    }

    doPayments = () => {
        let operator = this.state.operators.filter(op => op.username == this.state.formItems.operator);
        let curgame = this.state.game.filter(gm => gm.id == this.state.formItems.game);
        ReactDOM.render(
            <WinsPayment winsData={this.state.winsData} operator={operator[0]} game={curgame[0]}
                agents={this.state.agents} formItems={this.state.formItems} retailers={this.state.retailers} />, 
                document.getElementById('winListCon')
        );
    }

    doPreview=()=>{
        let { tbStruct, formItems } = this.state,
            selAgent = (formItems.agent === '') ? 'All Agents' : this.formatAgent(formItems.agent, formItems);

        ReactDOM.render(
            <WinsPdf rowData={tbStruct} data={{'game':this.getGamename(formItems.game),
                'winsDate': formItems.startDate, 'agent': selAgent}} />,
            document.getElementById('winListCon')
        );
    }

    render(){
        const footerData = [[
            { label: "Total", columnIndex: 0 },
            {label: 'Total ', columnIndex: 3, align: 'right',
            formatter: (tableData) => {
                let label = 0.0
                this.state.winsData.map(tdata => label += tdata.lineTotal)
                return (<span>{numformat.format(label)}</span>)}},
            {label: 'Total ', columnIndex: 4, align: 'right',
            formatter: (tableData) => {
                let label = 0.0
                this.state.winsData.map(tdata => label += tdata.winAmount)
                return (<span>{numformat.format(label)}</span>)}}
        ]];

        if(this.state.loading){
            return <div id='loader'></div>
        }

        return(
            <div className="row">
                <article className="col-xs-12">
                    <div class="text-center">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Operator: </button>
                            <select id="" name="operator" className="btn btn-default" style={{ width: '180px', height:'30px' }}
                            onChange={this.handleChange} value={this.state.formItems.operator} >
                                {this.state.operators.map(agt =>
                                    <option value={agt.username}>{agt.fullname}</option>
                                )}
                            </select>

                            <button className="btn btn-default">Date: </button>
                            <input type="date" class="date" name="startDate" id="" className="btn btn-default" 
                            style={{ width: '150px', height:'30px' }}
                            value={this.state.formItems.startDate}  onChange={this.handleChange}/>

                            <button className="btn btn-default">Game: </button>
                            <select id="" name="game" onChange={this.handleChange} value={this.state.formItems.game} 
                            className="btn btn-default" style={{ width: '150px', height:'30px' }}>
                                {this.state.game.map(gm =>
                                    <option value={gm.id}>{gm.gamename}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div class="text-center" style={{marginTop:'15px'}}>
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Agent: </button>
                            <select id="agent" name="agent" onChange={this.handleChange} value={this.state.formItems.agent} 
                            className="btn btn-default" style={{ width: '200px', height:'30px' }}>
                                <option value={''}>All Agents</option>
                                {this.state.agents.map(ag =>
                                    <option value={ag.username}>{ag.fullname}</option>
                                )}
                            </select>
                            <button onClick={this.getWinsData} className="btn btn-info" style={{marginRight:'15px'}}>Display</button>
                            {this.state.winsData.length > 0 && 
                            <button className="btn btn-success " onClick={this.doPayments}><i className='fa fa-money fa-lg' /> Make Payments</button>}
                            {this.state.winsData.length > 0 && 
                            <button className="btn btn-primary " onClick={this.doPreview}><i className='fa fa-print fa-lg' /> Preview</button>}
                        </div>
                    </div>
                    <hr className="hrsp" />
                </article>
                <article className="col-xs-12">
                    <BootstrapTable data={this.state.winsData} striped hover condensed pagination search footer footerData={footerData}>
                        <TableHeaderColumn dataField='ticketSerial' isKey dataSort width='200px' dataFormat={this.ticketFormat}>Ticket number</TableHeaderColumn>
                        <TableHeaderColumn dataField='agent' dataSort dataFormat={this.agentFormat}>Agent Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='retailer' dataSort dataFormat={this.retailerFomat}>Retailer</TableHeaderColumn>
                        <TableHeaderColumn dataField='lineTotal' dataSort dataAlign="right" dataFormat={this.curFormat} width='120px'>Total Cost</TableHeaderColumn>
                        <TableHeaderColumn dataField='winAmount' dataSort dataAlign="right" dataFormat={this.curFormat} width='120px'>Win Amount</TableHeaderColumn>
                    </BootstrapTable>
                    <WinTicketDetails ticketSerial={this.state.ticketSerial} /> 
                </article>
            </div>
        )
    }
}