import React from 'react'

export default function QueryJson(jsonObject, query){
    let param = query.split('=')
    let result = []

    jsonObject.map(jo => {
        if(jo[param[0]] == param[1]){
            result.push(jo)
        }
    })

    return result
}