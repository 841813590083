import React, { Component } from 'react';
import TerminaList from './TerminaList';

export default class Terminals extends Component{
    constructor(){
        super()

        this.state = {
            status: 0 
        }
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gear"></i> </span>
                            <h2><strong>POS</strong> <i>Terminals</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="terminalCon" className="widget-body">
                                <TerminaList status={this.state.status} />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}