import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import ProfileList from './ProfileList';
import MyProfileDetails from './MyProfileDetails';

export default class ChangePassword extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            formItems: {
                username:'', oldpassword:'', newpassword:'', retypepass:'',
                token:this.auth.getToken(), modifiedby:this.auth.getUser(), 
                curuser:this.auth.getUser(), modifiedon:new Date()
            },
            area: (this.props.area !== undefined) ? this.props.area:'profileCon'
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.rowData !== this.props.rowData){
            //Check area
            let area = (nextProps.area !== undefined) ? nextProps.area:this.state.area

            let rowData = nextProps.rowData
            this.setState({ rowData, area })
        }
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault();

        let { rowData, formItems} = this.state;

        formItems['username'] = rowData.username

        if(formItems.newpassword !== formItems.retypepass){
            alert('Password mismatched...')
            return ''
        }
        
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/changepass'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btncancel').click()
                this.refreshPage()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error)})
        //alert(JSON.stringify( rowData ))
    }

    refreshPage = () =>{
        let pageTo = (this.state.area == 'profileCon') ? <ProfileList /> : <MyProfileDetails />
        ReactDOM.render(pageTo, document.getElementById(this.state.area))
    }

    render(){
        return(
            <div className="modal fade" id="changepassword" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Change Password</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmChangePassword" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">Old Password: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="password" name="oldpassword" placeholder="Old Password" required 
                                                value={this.state.formItems.oldpassword} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">New Password: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="password" name="newpassword" placeholder="New Password" required 
                                                value={this.state.formItems.newpassword} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-5 text-right">Retype Password: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="password" name="retypepass" placeholder="Retype Password" required 
                                                value={this.state.formItems.retypepass} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btncancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmChangePassword" className="btn btn-primary">Change Password</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}