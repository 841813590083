import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import ReactDOM from 'react-dom'

export default class WinTecketDetails extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            imgReceipt:'',
            ticketSerial: this.props.ticketSerial
        }
    }

    componentDidMount(){
        this.getImageTicket(this.props.ticketSerial)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.ticketSerial !== this.props.ticketSerial){
            let ticketSerial = nextProps.ticketSerial

            this.setState({ ticketSerial })

            this.getImageTicket(ticketSerial)
        }
    }

    getImageTicket = (ticketSerial) => {
        if(ticketSerial !== ''){
            let url = 'admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/ticketimage/' + ticketSerial
        
            this.$http.get(url, {responseType: 'arraybuffer'})
            .then((res) => {
                let imgReceipt = new Buffer(res.data, 'binary').toString('base64')
                
                this.setState({ imgReceipt })
            }).catch((error)=>{alert("Image: " + error)})
        }
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="modal fade" id="winTicketDetails" tabindex="-1" role="dialog" aria-hidden="true">  
                    <div className="modal-dialog" style={{width:'350px'}}>  
                        <div className="modal-content"> 
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                                <h5 className="modal-title"><b>Ticket Details</b></h5>
                            </div>
                            <div className="modal-body" >
                                <img id="receiptImageCon" src={`data:image/jpeg;base64,${this.state.imgReceipt}`} style={{width:'300px'}} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}