import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import { BrowserRouter, Link } from 'react-router-dom';
import AgentList from './AgentList';
import ProfileForm from '../administration/ProfileForm';
import UnitForm from './UnitForm';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class RetailersList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            agent: '',
            retailer:'',
            agents: [],
            retailers: [],
            allretailers: [],
            operatorCriteria: this.props.criteria,
            criteria: '',
            filter:'',
            loader: false,
            usertypeItems:{
                username:'', usertype:'3', superior:'', bankname: '', area:'retailer',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        if(this.props.agents !== undefined ){
            let agents = this.props.agents

            this.setState({ agents })
        }

        if(this.props.agent !== undefined && this.props.agent.username !== undefined){
            let criteria = this.props.agent.username
            let agent = this.props.agent

            this.setState({ agent, criteria })
            this.getRetailers(this.props.agent.username)
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let operatorCriteria = nextProps.criteria;
            this.setState({ operatorCriteria, loader:true });

            let agent = nextProps.agent;
            let agents = nextProps.agents;
            let criteria = agent.username;

            this.setState({ agent, agents, criteria });
            this.getRetailers(criteria);
        }
    }

    getRetailers = (agent) =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/retaildetails/' + agent)
        .then(res => {
            let resdata = this.auth.decryptData(res.data)
            let retailers = resdata.map(rdata => {
                rdata['agent'] = agent;
                return rdata;
            })
            
            this.setState({ retailers, allretailers:retailers, filter:'', loader:false })
        }).catch((error)=>{alert(error)})
    }

    handleChange = (e) => {
        let criteria = this.state.criteria
        let filter = this.state.filter
        let allretailers = this.state.allretailers
        let retailers = this.state.retailers
        let itm = e.target.name

        if(itm == 'criteria'){
            criteria = e.target.value
        }
        if(itm == 'filter'){
            filter = e.target.value
            retailers = allretailers.filter(ret => ret.username.startsWith(filter))
        }

        this.setState({ criteria, filter, retailers });
    }

    statusFormat = (cell) => {
        let online = (isNaN(cell)) ? 'bg-color-green' : 'bg-color-red';
        let onlinetxt = (isNaN(cell)) ? 'Online' : 'Offline';

        return (<span className={'badge bounceIn animated ' + online}> {onlinetxt} </span>)
    }

    goBack = () => {
        ReactDOM.render(
            <AgentList role={this.props.role} criteria={this.state.operatorCriteria} />,
            document.getElementById('agent_inner')
        )
    }

    newAgent = () =>{
        let usertypeItems = this.state.usertypeItems
        usertypeItems['superior'] = this.state.criteria

        ReactDOM.render(
            <ProfileForm username={''} uitems={usertypeItems} 
            agent={this.state.agent} agents={this.state.agents} />,
            document.getElementById('agent_inner')
        )
    }

    doDisplay = () => {
        this.getRetailers(this.state.criteria)
    }

    transferUnit = (retailer) =>{
        retailer['usertype'] = 3;
        this.setState({ retailer })
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
          }else{
            return(
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">List of Retailers for: </button>
                            <select name="criteria" className="btn btn-default" style={{width:'200px', height:'30px'}} 
                            value={this.state.criteria} onChange={this.handleChange}>
                                {this.state.agents.map(ag => (
                                    <option value={ag.username}>{ag.fullname}</option>
                                ))} 
                            </select>
                            <button className="btn btn-info btnDisplay" onClick={this.doDisplay}>Display</button>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-warning" onClick={this.goBack}>
                                <i className="fa fa-lg fa-backward"></i> Go Back
                            </button>
                            <button className="btn btn-success" onClick={this.newAgent}>New Retailer</button>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <hr className="hrsp" />
                        <div className="clearfix">
                            <input type="text" placeholder="Filter by Writer Code" className="form-control pull-right" style={{width:'200px', height:'30px'}} name="filter" 
                            value={this.state.filter} onChange={this.handleChange}/>
                        </div>
                        <hr className="hrsp" />
                    </div>
                    {this.state.retailers.map(retail => {
                        let online = (isNaN(retail.curToken)) ? 'bg-color-green' : 'bg-color-red';
                        let onlinetxt = (isNaN(retail.curToken)) ? 'Online' : 'Offline';
                        let status = retail.status > 0 ? 'bg-color-green' : 'bg-color-red';
                        let statustxt = retail.status > 0 ? 'Active' : 'Inactive';
                        
                        return(
                        <div className="col-xs-10 col-sm-4 col-md-3">
                            <div className="lockscreen animated flipInY table-bordered clearfix margin-bottom-10" style={{minHeight:'490px'}}>
                                <div className="text-center padding-5">
                                    <img className="img img-circle" src={this.config.ImagePath('custfiles') + '/' + retail.username + '.' + retail.filex} alt={retail.username} style={{maxHeight:'130px',maxWidth:'120px'}} />
                                    <h3>{retail.fullname}</h3>
                                </div>
                                <div class="profile-usermenu">
                                    <ul class="nav">
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#"><i className="fa fa-key"></i> {retail.username}</Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#" className="text-warning"><i className="fa fa-user"></i> {retail.agent} </Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#" className="text-warning"><i className="fa fa-calculator"></i> {retail.imei}</Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#" ><i className="fa fa-phone"></i> {retail.mobile}</Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#" ><i className="fa fa-map-marker"></i> {retail.location}</Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>
                                            <BrowserRouter>
                                                <Link to="#"><i className="fa fa-money"></i> {numformat.format(retail.cashinhand)}</Link>
                                            </BrowserRouter>
                                        </li>
                                        <li>&nbsp;&nbsp;&nbsp;
                                            {retail.status > 0 && <a href="#" className="text-success" data-toggle="modal" data-target="#unitform" onClick={() => this.transferUnit(retail)}>
                                                <i className="fa fa-credit-card"></i> &nbsp;&nbsp; {numformat.format(retail.unit)} 
                                            </a>}
                                            {retail.status == 0 && <span><i className="fa fa-credit-card"></i> {numformat.format(retail.unit)}</span>}
                                        </li>
                                    </ul>
                                </div>
                                <div className="text-center margin-bottom-10">
                                    <span className={'badge bounceIn animated ' + online}> {onlinetxt} </span>
                                    <span className={'badge bounceIn animated ' + status}> {statustxt} </span>
                                </div>
                            </div>
                        </div>
                    )})}
                    <UnitForm user={this.state.retailer} 
                    agent={this.state.agent} agents={this.state.agents} />
                </div>
            )
        }
    }
}