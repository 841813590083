import React, { Component } from 'react';
import CashierList from './CashierList';

class Cashiers extends Component{
    constructor(){
        super();
    }

    componentDidMount = () => {

    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span className="widget-icon"> <i className="fa fa-gear"></i> </span>
                            <h2><strong>Cashier</strong> <i>Profiles</i></h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="profileCon" className="widget-body">
                                <CashierList />
                            </div>                            
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default Cashiers;