import React, { Component, Fragment } from "react";
import $ from "jquery";
import Auth from "../common/Auth";
import Config from "../Config";

export default class BirdsEye extends Component {
  constructor() {
    super();
    this.auth = new Auth();
    this.config = new Config();
    this.$http = this.config.DConnect();
    this.state = {
      retailerdistribution: [], alphaDist: [], otherDist: [],
    //   gametypename: {1:'Vision', 2:'Other'}
      gametypename: {1:'Alpha', 2:'Other'}
    };
  }
  getretailerdistribution = () => {
    this.$http.get("dashboard/wapp/" + this.auth.getUser() + "/" + this.auth.getToken() + "/retailerdistribution")
    .then(res => {
      if (isNaN(res.data)) {
        let retailerdistribution = this.auth.decryptData(res.data);

        let alphaDist = retailerdistribution.filter(rd => rd.gametype === 1)
        let otherDist = retailerdistribution.filter(rd => rd.gametype === 2)

        //alert(JSON.stringify(retailerdistribution))
        this.setState({ retailerdistribution, alphaDist, otherDist });
      } else {
        this.auth.doAlert(parseInt(res.data));
      }
    })
  }

  componentDidMount() {
    this.getretailerdistribution();
  }
  render() {
    return (
      <Fragment>
      <article className="col-sm-12 col-md-12 col-lg-6">
        <div
          className="jarviswidget"
          id="wid-id-2"
          data-widget-colorbutton="false"
          data-widget-editbutton="false"
        >
          <header>
            <span className="widget-icon">
              {" "}
              <i className="fa fa-map-marker" />{" "}
            </span>
            <h2>{this.state.gametypename['1']} Game - Today Stakes By Location</h2>
          </header>

          <div>
            <div className="jarviswidget-editbox">
              <div>
                <label>Title:</label>
                <input type="text" />
              </div>
            </div>

            <div className="widget-body no-padding">

              <div className="table table-condesed table-striped pagination table-responsive no-margin" style={{maxHeight:'350px'}}>
                <table className="table table-striped table-hover table-condensed">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Stakes</th>
                      <th className="text-align-center">Retailers</th>

                      <th className="text-align-center">Action</th>
                    </tr>
                  </thead>
                    <tbody>
                    {this.state.alphaDist.map(row => (
                      <tr>
                        <td>
                          <a href="javascript:void(0);">{row.location}</a>
                        </td>
                        <td>{row.numberOfStakes}</td>
                        <td className="text-align-center">
                          <div className="sparkline txt-color-blue text-align-center">
                            {row.retailers}
                          </div>
                        </td>

                        <td className="text-align-center">
                          <div className="btn-group display-inline pull-right text-align-left hidden-tablet">
                            <button
                              className="btn btn-xs btn-default dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-cog fa-lg" />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-xs pull-right">
                              <li>
                                <a href="javascript:void(0);">
                                  <i className="fa fa-file fa-lg fa-fw txt-color-greenLight" />{" "}
                                  <u>P</u>DF
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <i className="fa fa-times fa-lg fa-fw txt-color-red" />{" "}
                                  <u>D</u>elete
                                </a>
                              </li>
                              <li className="divider" />
                              <li className="text-align-center">
                                <a href="javascript:void(0);">Cancel</a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>))}
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="col-sm-12 col-md-12 col-lg-6">
      <div
        className="jarviswidget"
        id="wid-id-2"
        data-widget-colorbutton="false"
        data-widget-editbutton="false"
      >
        <header>
          <span className="widget-icon">
            {" "}
            <i className="fa fa-map-marker" />{" "}
          </span>
          <h2>{this.state.gametypename['2']} Games - Today Stakes By Location</h2>
        </header>

        <div>
          <div className="jarviswidget-editbox">
            <div>
              <label>Title:</label>
              <input type="text" />
            </div>
          </div>

          <div className="widget-body no-padding">

            <div className="table table-condesed table-striped pagination table-responsive no-margin" style={{maxHeight:'350px'}}>
              <table className="table table-striped table-hover table-condensed">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Stakes</th>
                    <th className="text-align-center">Retailers</th>

                    <th className="text-align-center">Action</th>
                  </tr>
                </thead>
                  <tbody>
                  {this.state.otherDist.map(row => (
                    <tr>
                      <td>
                        <a href="javascript:void(0);">{row.location}</a>
                      </td>
                      <td>{row.numberOfStakes}</td>
                      <td className="text-align-center">
                        <div className="sparkline txt-color-blue text-align-center">
                          {row.retailers}
                        </div>
                      </td>

                      <td className="text-align-center">
                        <div className="btn-group display-inline pull-right text-align-left hidden-tablet">
                          <button
                            className="btn btn-xs btn-default dropdown-toggle"
                            data-toggle="dropdown"
                          >
                            <i className="fa fa-cog fa-lg" />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-xs pull-right">
                            <li>
                              <a href="javascript:void(0);">
                                <i className="fa fa-file fa-lg fa-fw txt-color-greenLight" />{" "}
                                <u>P</u>DF
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0);">
                                <i className="fa fa-times fa-lg fa-fw txt-color-red" />{" "}
                                <u>D</u>elete
                              </a>
                            </li>
                            <li className="divider" />
                            <li className="text-align-center">
                              <a href="javascript:void(0);">Cancel</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>))}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </article>
      </Fragment>
    );
  }
}
