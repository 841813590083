import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import EmailConfig from './EmailConfig';
import SetupConfig from './SetupConfig';
import Placeholder from './Placeholder'
import Dropdown from './Dropdown';
import APIConfig from './APIConfig';
import SMSConfig from './SMSConfig';

export default class GeneralConfig extends Component{
    constructor(){
        super()
    }

    handleChange=(e)=>{
        let page=''
        if (e == "email") {
            page = <EmailConfig />
        } else if (e == "setup") {
            page = <SetupConfig />
        } else if (e == "api") {
            page = <APIConfig />
        } else if (e == "sms") {
            page = <SMSConfig />
        }else if(e=="placeholder"){
            page=<Placeholder/>
        }else if(e=="dropdowns"){
            page=<Dropdown />
        }

        ReactDOM.render(page, document.getElementById('generalConfigCon'))
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-gears"></i> </span>
                            <h2><strong>General Configuration</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-4 col-md-2 pull-right">
                                        <div className="btn-group btn-group-sm btn-group-vertical">
                                            <button onClick={()=>this.handleChange("setup")}  className="btn btn-default btn-sm">Setup</button>
                                            {/*<button onClick={()=>this.handleChange("email")} className="btn btn-default btn-sm">Email Config</button>*/}
                                            <button onClick={()=>this.handleChange("api")}  className="btn btn-default btn-sm">APIs</button>
                                            <button onClick={()=>this.handleChange("sms")}  className="btn btn-default btn-sm">SMS</button>
                                            <button onClick={()=>this.handleChange("dropdowns")}  className="btn btn-default btn-sm">Dropdowns</button>
                                            <button onClick={()=>this.handleChange("placeholder")}  className="btn btn-default btn-sm">PlaceHolders</button>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-8 col-md-10">
                                        <div id="generalConfigCon" className="table-bordered" style={{minHeight:'200px'}}>
                                            <SetupConfig />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
       )
    }
}