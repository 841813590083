import React, { Component } from 'react';
import FileManagerList from './FileManagerList';

export default class FileManager extends Component{
    constructor(){
        super()
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-building"></i> </span>
                            <h2><strong>File Manager</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="filemanager_inner" className="widget-body">
                                <FileManagerList />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
       )
    }
}