import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'

export default class ApproversForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                approversPK:{username:'', approvalType:this.props.approvalType}, status:1,  createdby:this.auth.getUser(),
                createdon:new Date(), curuser:this.auth.getUser(), token:this.auth.getToken()
            },
            users:[]
        }
    }

    componentDidMount(){
        this.getUsers()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let formItems = this.state.formItems
            formItems['approversPK']['approvalType'] = nextProps.approvalType

            this.setState({ formItems })

            this.getUsers()
        }
    }

    getUsers = () => {
        //alert(this.props.approvalType)
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/approvalusers/' + this.props.approvalType)
        .then(res => {
            let users = this.auth.decryptData(res.data)
            let formItems = this.state.formItems
            if(users[0] !== undefined){
                formItems['approversPK']['username'] = users[0].username
            }

            this.setState({ users, formItems })
        })//.catch((error)=>{alert(error)})
    }

    handleChange = (e) =>{
        let formItems = this.state.formItems
        
        if(e.target.name == 'username'){
            formItems.approversPK['username'] = e.target.value
        }else{
            formItems[e.target.name] = e.target.value
        }

        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'admin/wapp/approver'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnApproverscancel').click()
                $('#btnApproversDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    render(){
        return(
            <div className="modal fade" id="approverform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">New Approver</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmApprovers" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-3 text-right">Approver: </label>
                                        <section className="col col-9">
                                            <label className="select">
                                                <select name="username" value={this.state.formItems.username} onChange={this.handleChange} style={{height:'32px'}}>
                                                    {this.state.users.map(user => (
                                                        <option value={user.username}>{user.firstname} {user.lastname}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnApproverscancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmApprovers" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}