import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery'
import Dashboard from '../dashboard/Dashboard';
import Agents from '../agents/Agents';
import Commission from '../commission/Commission';
import GlTransactions from '../transactions/GlTransactions';
import OperatorTransactions from '../transactions/OperatorTransactions';
import AgentsTransactions from '../transactions/AgentsTransactions';
import RetailersTransactions from '../transactions/RetailersTransactions';
import RetailersStakedTrans from '../transactions/RetailersStakedTrans';
import Emailing from '../tools/Emailing';
import Wins from '../gamedraw/Wins';
import Games from '../games/Games';
import GameSetup from '../games/GameSetup';
import UserProfiles from '../administration/UserProfiles';
import Terminals from '../administration/Terminals';
import DrawData from '../gamedraw/DrawData';
import DrawEntry from '../gamedraw/DrawEntry';
import FileManager from '../tools/FileManager';
import AuditTrail from '../administration/AuditTrail';
import Approvers from '../administration/Approvers';
import GeneralConfig from '../configuration/GeneralConfig';
import MyProfile from '../administration/MyProfile';
import BetSetup from '../games/BetSetup';
import DrawScreening from '../gamedraw/DrawScreening';
import RiskManagement from '../gamedraw/RiskManagement';
import GeneralReport from '../reports/GeneralReport';
import WinList from '../gamedraw/WinList';

import MomoTransactions from '../transactions/MomoTransactions';
import CartTemp from '../transactions/CartTemp';
import SendSMS from '../configuration/SendSMS';
import Portal from '../portal/Portal';
import CashierTransaction from '../transactions/CashierTransaction';
import Cashiers from '../administration/Cashiers';
import UnitRequestForm from './UnitRequestForm';
import UnitRequests from '../transactions/UnitRequests';

const cont = {
    '1': <Dashboard />,
    '2': <Agents />,
    '3': <Games />,
    '41': <GlTransactions />,
    '42': <OperatorTransactions />,
    '43': <AgentsTransactions />,
    '44': <RetailersTransactions />,
    '45': <MomoTransactions />,
    '46': <CashierTransaction />,
    '47': <CartTemp />,
    '48': <RetailersStakedTrans />,
    /*'51': <AgentDetails />,
    '53': <GameSales />,
    '54': <AgentPayouts />,
    '55': <AgentSummary />,
    '56': <RepCommission />,*/
    '57': <GeneralReport />,
    '58': <UnitRequests />,
    '65': <DrawData />,
    '61': <DrawEntry />,
    '62': <DrawScreening />,
    '63': <Wins />,
    '64': <Commission />,
    '66': <RiskManagement />,
    '67': <WinList />,
    '71': <SendSMS />,
    '72': <Emailing />,
    '73': <FileManager />,
    '81': <UserProfiles />,
    '87': <Cashiers />,
    '82': <Terminals />,
    '83': <AuditTrail />,
    '84': <Approvers />,
    '85': <GameSetup />,
    '86': <BetSetup />,
    '91': <GeneralConfig />,
    'A1':<Portal />,
    '0': <MyProfile />
}

export default class Content extends Component{
    constructor(props){
        super(props)

        this.state = {
            pagenum: this.props.pagenum
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.pagenum !== this.props.pagenum){
            let pagenum = nextProps.pagenum
            this.setState({ pagenum })

            let lid = (pagenum>10) ? parseInt(pagenum/10): pagenum
            $('#left-panel nav ul li').removeClass('active')
            $('#left-panel nav ul li#' + lid).addClass('active')

            
        }

        // $('#btnUnitRequest').click();
    }

    render(){
        return(
            <div id="main" role="main">

                {/* <button id="btnUnitRequest" className="btn btn-success" data-toggle="modal" data-target="#unitRequest">
                </button> */}

                <div id="ribbon">
                    <ol class="breadcrumb">
                        <li>&nbsp;</li>
                    </ol>
                    <ol class="breadcrumb pull-right">
                        <li><i class="fa-fw fa fa-calendar"></i> {moment().format('DD MMMM, YYYY')}</li>
                    </ol>
                </div>

                <div id="content">{cont[this.state.pagenum]}</div>
            </div>
        )
    }
}