import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import ReactDOM from 'react-dom'
import moment from 'moment'
import $ from 'jquery'
import BetSetupList from './BetSetupList';

export default class BetSetupForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            loader: false,
            formItems: {
                id:'', betname:'', multiplier:0.0, minNo:0, maxNo:0, showOnApp:0, showOnWeb:0, showOnUssd:0, showOnClient:0,
                createdby:this.auth.getUser(), createdon: moment(), minStakeAmount: 0,
                modifiedby:null, modifiedon:null, curuser:this.auth.getUser(),
                maxStakeAmount: 0, token:this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        let formItems

        if(this.props.rowData.id !== undefined){
            formItems = this.props.rowData
            formItems['modifiedon'] = moment()
            formItems['modifiedby'] = this.auth.getUser()
            formItems['curuser'] = this.auth.getUser()
            formItems['token'] = this.auth.getToken()
        }else{
            formItems = {
                id:'', betname:'', multiplier:0.0, minNo:0, maxNo:0, showOnApp:0,
                createdby:this.auth.getUser(), createdon: moment(), minStakeAmount: 0,
                modifiedby:null, modifiedon:null, curuser:this.auth.getUser(),
                maxStakeAmount: 0, token:this.auth.getToken()
            }
        }
        
        this.setState({ formItems })
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            let formItems

            if(this.props.rowData.id !== undefined){
                formItems = this.props.rowData
                formItems['modifiedon'] = moment()
                formItems['modifiedby'] = this.auth.getUser()
                formItems['curuser'] = this.auth.getUser()
                formItems['token'] = this.auth.getToken()
            }else{
                formItems = {
                    id:'', betname:'', multiplier:0.0, minNo:0, maxNo:0, showOnApp:0,
                    createdby:this.auth.getUser(), createdon: moment(), minStakeAmount: 0,
                    modifiedby:null, modifiedon:null, curuser:this.auth.getUser(),
                    maxStakeAmount: 0, token:this.auth.getToken()
                }
            }
            
            this.setState({ formItems })
        }
    }

    goBack = () => {
        ReactDOM.render(
            <BetSetupList />,
            document.getElementById('betSetupCon')
        )
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        formItems[e.target.name] = e.target.value
        
        this.setState({ formItems })
    }

    formSubmit = (e) => {
        e.preventDefault()

        let formItems = this.state.formItems
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'game/wapp/betting'))
        .then((res) => {
            
            if(isNaN(res.data)){
                let resdata = this.auth.decryptText(res.data)
                alert(resdata)
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            $('#btnBetcancel').click()
            this.goBack()
        }).catch((error)=>{alert(error); $('#btnBetcancel').click();})
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="modal fade" id="betsetupform" tabindex="-1" role="dialog" aria-hidden="true">  
                    <div className="modal-dialog">  
                        <div className="modal-content"> 
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                                <h5 className="modal-title">Edit Bet</h5>
                            </div>
                            <div className="modal-body">
                                <form id="frmBetSetup" className="smart-form" onSubmit={this.formSubmit}>
                                    <div className="row">
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Bet Name: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="text" name="betname" placeholder="Bet Name" required 
                                                    value={this.state.formItems.betname} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Multiplier: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" name="multiplier" min='1' required 
                                                    value={this.state.formItems.multiplier} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12">
                                            <label className="label col col-5 text-right">Min Stake #: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" name="minNo" min='1' required 
                                                    value={this.state.formItems.minNo} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12">
                                            <label className="label col col-5 text-right">Max Stake #: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" name="maxNo" min='1' required 
                                                    value={this.state.formItems.maxNo} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12">
                                            <label className="label col col-5 text-right">Min Stake Amount: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" name="minStakeAmount" min='0' step=".1" required 
                                                    value={this.state.formItems.minStakeAmount} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12">
                                            <label className="label col col-5 text-right">Max Stake Amount: </label>
                                            <section className="col col-7">
                                                <label className="input">
                                                    <input type="number" name="maxStakeAmount" min='0' step=".1" required 
                                                    value={this.state.formItems.maxStakeAmount} onChange={this.handleChange}/>
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Show on POS: </label>
                                            <section className="col col-7">
                                                <label className="select">
                                                    <select style={{minWidth:'100px'}} name="showOnApp" value={this.state.formItems.showOnApp} onChange={this.handleChange}>
                                                       <option value="0">No</option>
                                                       <option value="1">Yes</option>
                                                    </select> <i></i> 
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Show on WEB: </label>
                                            <section className="col col-7">
                                                <label className="select">
                                                    <select style={{minWidth:'100px'}} name="showOnWeb" value={this.state.formItems.showOnWeb} onChange={this.handleChange}>
                                                       <option value="0">No</option>
                                                       <option value="1">Yes</option>
                                                    </select> <i></i> 
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Show on USSD: </label>
                                            <section className="col col-7">
                                                <label className="select">
                                                    <select style={{minWidth:'100px'}} name="showOnUssd" value={this.state.formItems.showOnUssd} onChange={this.handleChange}>
                                                       <option value="0">No</option>
                                                       <option value="1">Yes</option>
                                                    </select> <i></i> 
                                                </label>
                                            </section>
                                        </div>
                                        <div className="col-xs-12 ">
                                            <label className="label col col-5 text-right">Show on Client App: </label>
                                            <section className="col col-7">
                                                <label className="select">
                                                    <select style={{minWidth:'100px'}} name="showOnClient" value={this.state.formItems.showOnClient} onChange={this.handleChange}>
                                                       <option value="0">No</option>
                                                       <option value="1">Yes</option>
                                                    </select> <i></i> 
                                                </label>
                                            </section>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button id="btnBetcancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button type="submit" form="frmBetSetup" className="btn btn-primary">Update Bet</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}