import React, {Component} from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import ReactDOM from 'react-dom'
import axios from 'axios';
import $ from 'jquery'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class PageForm extends Component{
    constructor(props){
        super(props);

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            rowData: this.props.rowData,
            formItems:{},
            docItems: []
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.rowData !== this.state.rowData){
            let rowData = nextProps.rowData;

            //alert(JSON.stringify(rowData));
            this.setState({ rowData });
        }
    }

    saveEditor = (data) => {
        let rowData = this.state.rowData;
        rowData['content'] = data;

        this.setState({ rowData });
    }

    handleChange = (e) => {
        let rowData = this.state.rowData;
        rowData[e.target.name] = e.target.value;

        this.setState({ rowData });
    }

    formSubmit = (e) => {
        e.preventDefault();
        let rowData = this.state.rowData;
        rowData['curuser'] = this.auth.getUser();
        rowData['token'] = this.auth.getToken();

        //alert(JSON.stringify(rowData));

        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'web/wapp/portal'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = this.auth.decryptText(res.data)

                alert(resdata);
                $('#btnPageCancel').click()
                this.props.getReportData(rowData.page);
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        }).catch((error)=>{alert(error); this.setState({ loader: false });})
    }

    imageChange = (e, picon) =>{
        let img = e.target.files[0]
        let ext = img.name.split('.')
        ext = ext[ext.length-1].toLowerCase()

        if(ext === 'jpg' || ext === 'jpeg' || ext === 'png'){
            let docItems = this.state.docItems
            let formItems = this.state.formItems
            docItems[e.target.name] = img
            formItems[e.target.name] = img.name
            
            this.setState({ docItems, formItems })

            let imgurl =  URL.createObjectURL(img)
            ReactDOM.render(
                <img src={imgurl} style={{width:'100px'}} />,
                document.getElementById(picon)
            )
        }else{
            alert('The supported files are: jpg and png...')
        }
    }

    saveImage = (formItems) =>{
        let {docItems} = this.state

        docItems.map(ditm => {
            if(ditm.image !== null){
                axios(this.config.PostMulti(ditm.image, 'admin/wapp/agentfile/' + ditm.name))
                .then((res) => {
                    let multimedia = {'mediaType':1,'filename':ditm.name,'caption':'',owner:'portal','ownerid':formItems.id,'isdefault':0,'createdon':new Date(), curuser:this.auth.getUser(), token:this.auth.getToken()};
                    axios(this.config.PostMulti(multimedia, 'web/wapp/multimedia'))
                    .then((res) => {}).catch((error)=>{alert(error)})
                }).catch((error)=>{alert(error)})
            }
        })
        
    }

    render(){
        let { rowData } = this.state;

        return(
            <div className="modal fade" id="pageForm" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">New Page Item: <b>{rowData.title}</b></h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmPage" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-12 form-group">
                                        <label className="label"><b>Title: </b></label>
                                        <section className="">
                                            <label className="input">
                                                <input type="text" name="title" placeholder="Title"  
                                                value={rowData.title} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-12">
                                        <label className="label"><b>Content: </b></label>
                                        <section className="">
                                            <CKEditor height="150" name="content"
                                                editor={ ClassicEditor }
                                                data={rowData.content}
                                                onInit={ editor => {
                                                    console.log( 'Editor is ready to use!', editor );
                                                } }
                                                onChange={ ( event, editor ) => rowData.content = editor.getData()}
                                            />
                                        </section>
                                    </div>
                                    <div className="col-12">
                                        <label className="label"><b>Attachments: </b></label>
                                        {this.state.formItems!==undefined && <img src={this.config.ImagePath('custfiles') + '/' +  this.state.formItems.poslogo} style={{width:'100'}} alt="POS" />}
                                    </div>
                                    <div className="col-12 btn-group btn-group-sm pull-right">
                                        <button id="btnPageCancel" type="button" className="btn btn-warning" data-dismiss="modal" aria-hidden="true">Cancel</button>
                                        <button type="submit" className="btn btn-success">Save {(rowData.id > 0) && 'Changes'}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}