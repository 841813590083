import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import EmailMessages from './EmailMessages';

export default class EmailLists extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            emaillogs: [],
            users: []
        }
    }

    newMessage = () => {
        alert('Under development...')
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="btn-group btn-group-sm pull-right clearfix">
                        <button type="submit" className="btn btn-default" onClick={this.newMessage}>
                            <i className="glyphicon glyphicon-plus"></i> New Message
                        </button>
                    </div>
                </article>
                <article className="col-xs-12"><hr className="hrsp" /></article>
                <article className="col-xs-12">
                    <BootstrapTable data={this.state.emaillogs} striped hover condensed pagination>
                        <TableHeaderColumn dataField='createdon' dataFormat={this.dateFormat} isKey dataSort>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='sendTo' dataSort>Send To</TableHeaderColumn>
                        <TableHeaderColumn dataField='message' dataSort>Message</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdby' dataFormat={this.userFormat} dataSort>Created By</TableHeaderColumn>
                        <TableHeaderColumn dataField='status' dataFormat={this.statusFormat} dataSort>Status</TableHeaderColumn>
                    </BootstrapTable>
                </article>
            </div>
       )
    }
}