import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import ApproversForm from './ApproversForm';

export default class ApproversList extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData: [],
            rowData: {},
            approvalTypes: [],
            approveStatus: [],
            criteria: '1'
        }
    }

    componentDidMount(){
        this.getDropdowns()
    }

    componentWillReceiveProps(nextProps){
        if(this.props !== nextProps){
            this.getDropdowns()
        }
    }

    getDropdowns = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/approval_type')
        .then(res => {
            let approvalTypes = this.auth.decryptData(res.data)
            
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/dropdown/approve_status')
            .then(res => {
                let approveStatus = this.auth.decryptData(res.data)

                this.setState({ approvalTypes, approveStatus })

                this.getReportData()
            })
        })
    }

    actionFormat = (cell, row) => {
        return(<div className="btn-group btn-group-xs">
            <button className="btn btn-default" title="Delete Approver" onClick={() => this.deleteApprover(row)} >
                <i className="glyphicon glyphicon-trash"></i> Delete
            </button>
        </div>)
    }

    statusFormat = (cell, row) => {
        return (cell == 1) ? 'Active' : 'Inactive'
    }

    deleteApprover = (rowData) => {
        rowData['curuser'] = this.auth.getUser()
        rowData['token'] = this.auth.getToken()
        
        let formData = this.auth.encryptData(JSON.stringify(rowData))
        axios(this.config.PostOptions(formData, 'admin/wapp/deleteapprover'))
        .then((res) => {
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
                $('#btnApproversDisplay').click()
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            
        }).catch((error)=>{alert(error)})
    }

    getReportData = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/approvers/' + this.state.criteria)
        .then(res => {
            let reportData = this.auth.decryptData(res.data)

            this.setState({ reportData })
        })
    }

    setRowData = (rowData) => {
        this.setState({ rowData })
    }

    handleDisplay = () => {
        this.getReportData()
    }

    handleChange = (e) => {
        let criteria = e.target.value
        this.setState({ criteria })
    }

    render(){
        return(
            <div className="row">
                <div className="col-xs-12">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-default">Approve Type: </button>
                        <select name="criteria" className="btn btn-default" style={{width:'150px'}}
                            value={this.state.criteria} onChange={this.handleChange}>
                            {this.state.approvalTypes.map(atype => (
                                <option value={atype.ivalue}>{atype.iname}</option>
                            ))}
                        </select>
                        <button id="btnApproversDisplay" className="btn btn-primary" onClick={this.handleDisplay}>Display</button>
                    </div>     
                    <div className="btn-group btn-group-sm pull-right">
                        <button className="btn btn-info" data-toggle="modal" data-target="#approverform" onClick={() => this.setRowData('{}')}>
                            <i className="glyphicon glyphicon-plus"></i> Add Approver
                        </button>
                    </div>               
                </div>
                <div className="col-xs-12 col-sm-8 col-lg-6">
                    <hr className="hrsp" />
                    <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                        <TableHeaderColumn dataField='user' isKey dataSort >User</TableHeaderColumn>
                        <TableHeaderColumn dataField='status' dataSort dataFormat={this.statusFormat}>Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='status' dataSort dataFormat={this.actionFormat}></TableHeaderColumn>
                    </BootstrapTable>
                </div>
                <ApproversForm approvalType={this.state.criteria} />
            </div>
        )
    }
}