import React, { Component } from "react";
import { Chart } from "react-google-charts";

export default class GameChart extends Component{
    constructor(props){
        super(props)

        this.state = {
            data: this.props.data
        }
    }

    componentDidUpdate(nextProps){
        if(nextProps.data !== this.props.data){
            let data = nextProps.data

            this.setState({ data })
        }
    }

    render(){
        return(
            <Chart
                width={"100%"}
                height={"250px"}
                chartType="LineChart"
                loader={<div>Loading Chart...</div>}
                data={this.props.data}
                options={{
                    legend: { position: 'bottom', alignment: 'start' },
                    vAxis: {title:'Amount'},
                    hAxis: {title:'Time (Hrs)'}
                    //colors: ["#164291", "#DF8502", "#004B1A", "#DD5602", "#FF501A"]
                }} 
            />
        )
    }
}