import React, { useEffect, useState } from "react";
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactDOM from 'react-dom';
import Auth from "../common/Auth";
import Config from "../Config";
import moment from "moment";
import _ from "lodash";
import ReportView from "./ReportView";
import Axios from "axios";
import AgentPerformancePdf from "./Pdf/AgentPerformancePdf";

function AgentPerformanceReport(props) {

    const auth = new Auth();
    const config = new Config();
    const $http = config.DConnect();

    const [filter, setFilter] = useState({
        'operator': '', 'username': '', 'startDate': moment(),
        'curuser': auth.getUser(), 'token':auth.getToken(), 'endDate': moment()
    });
    const [operators, setOperators] = useState([]);
    const [agents, setAgents] = useState([]);
    const [retailers, setRetailers] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [retailerTotal, setRetailTotal] = useState({'total':0, 'active':0, 'inactive':0});
    const [isLoading, setLoading] = useState(false);

    const curformat = new Intl.NumberFormat("en-GB", {
        maximumFractionDigits: 2, minimumFractionDigits: 2
    });
    const numformat = new Intl.NumberFormat("en-GB", {
        maximumFractionDigits: 0, minimumFractionDigits: 0
    });
    let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },
        start = filter.startDate.format('MMM DD, YYYY'),
        end = filter.endDate.format('MMM DD, YYYY'),
        label = start + ' - ' + end;
    if (start === end) { label = start; }

    useEffect(() => {

        if(props.filter !== undefined){
            setFilter(props.filter);
        }

        getInitData();
    }, [props.filter]);

    const getInitData = () => {
        setLoading(true);

        let systemDefine = (auth.getRole() == "Admin" || auth.getRole() == "Super")
            ? '/usertypes/1' : '/usertypesoperator/' + auth.getUser() + '/' + 1;

        if (systemDefine !== undefined) {
            $http.get('admin/wapp/' + auth.getUser() + '/' + auth.getToken() + systemDefine)
                .then(res => {
                    if (isNaN(res.data)) {
                        let operators = auth.decryptData(res.data),
                            operator = (operators[0] !== undefined)
                                ? operators[0].username : operators.username;

                        setOperators(operators);
                        setFilter(prevProps => ({ ...prevProps, 'operator': operator }));

                        getAgents(operator);
                    } else {
                        auth.doAlert(parseInt(res.data))
                    }

                })
        }
    }

    const getAgents = (operator) => {

        $http.get('admin/wapp/' + auth.getUser() + '/' + auth.getToken() + '/agentdetails_rep/' + operator)
            .then(res => {
                let agents = auth.decryptData(res.data),
                    agent = (agents[0] !== undefined) ? agents[0].username : '';

                setFilter(prevProps => ({ ...prevProps, 'username': agent }));
                setAgents(agents);
                getRetailer(agent);
            }).catch(error => console.log(error.message));
    }

    const getRetailer = (agent) => {
        setLoading(true);
        
        $http.get('admin/wapp/' + auth.getUser() + '/' + auth.getToken() + '/retailerdetails/' + agent)
        .then(res => {
            if (isNaN(res.data)) {
                let retailers = auth.decryptData(res.data);
                
                setRetailers(retailers);
            } else {
                auth.doAlert(parseInt(res.data))
            }

            setLoading(false);
        })
    }

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setFilter(prevProps => ({ ...prevProps, [name]: value }));
        setReportData([]);

        if(name === 'username'){
            getRetailer(value);
        }
    }

    const handleEvent = (event, picker) => {
        let newFilter = _.cloneDeep(filter);

        newFilter['startDate'] = picker.startDate;
        newFilter['endDate'] = picker.endDate;

        setFilter(newFilter);
    }

    const getReport = () => {
        setLoading(true);

        let formData = auth.encryptData(JSON.stringify(filter));

        // alert(JSON.stringify(filter))
        Axios(config.PostOptions(formData, 'report/wapp/agentPerformance'))
        .then((res) => {
            if (isNaN(res.data)) {
                let resdata = auth.decryptData(res.data);

                setReportData(resdata.performance);
                setRetailTotal({'total':resdata.totalRetailers, 'active':resdata.activeRetailers, 'inactive':resdata.inactiveRetailers})
            } else {
                auth.doAlert(parseInt(res.data));
            }

            setLoading(false);
        }).catch((error)=>alert(error));
    }

    const curFormat = (cell, row) => curformat.format(cell);

    const numFormat = (cell, row) => numformat.format(cell);

    const formatAgent = (cell, row) => {
        let agentName = agents.filter(agent => agent.username == cell);

        return (agentName.length > 0) ? agentName[0].fullname : cell;
    }

    const formatRetailer = (cell, row) => {
        let retailerName = retailers.filter(retailer => retailer.username == cell);

        return (retailerName.length > 0) ? `(${cell}) ${retailerName[0].fullname}` : `(${cell})`;
    }

    const dateFormat = (cell, row) => moment(cell).format('DD-MM-YYYY HH:mm:ss');

    const printPreview = () => {
        ReactDOM.render(
            <AgentPerformancePdf reportData={reportData} filter={filter} retailerTotal={retailerTotal}
            formatAgent={formatAgent} formatRetailer={formatRetailer} dateFormat={dateFormat} />,
            document.getElementById('generalReport_inners')
        );
    }

    const goBack = () => ReactDOM.render(<ReportView />,
        document.getElementById('generalReport_inners'));

    if (isLoading) {
        return <div id="loader"></div>;
    }

    return (
        <div className="row">
            <article className="col-xs-12">
                <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                    <header>
                        <span class="widget-icon"> <i class="fa fa-users"></i> </span>
                        <h2><strong>Agent Performance</strong> </h2>
                    </header>
                    <div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="btn-group btn-group-sm text-center">
                                    <button className="btn btn-default">Operator: </button>
                                    <select id="" name="operator" onChange={handleChange} value={filter.operator} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                        {operators.map(agt =>
                                            <option value={agt.username}>{agt.fullname}</option>
                                        )}
                                    </select>

                                    <button className="btn btn-default">Agent: </button>
                                    <select id="username" name="username" onChange={handleChange} value={filter.username} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                        {agents.map(agt =>
                                            <option value={agt.username}>{agt.fullname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="btn-group btn-group-sm pull-right">
                                    <button className="btn btn-warning " id="back" onClick={goBack}>Go Back</button>
                                    <button className="btn btn-primary " onClick={printPreview}>Preview</button>
                                </div>
                            </div>
                            <div className="col-xs-12 " style={{ marginTop: '15px' }}>
                                <div className="btn-group btn-group-sm ">

                                    <button className="btn btn-default">Date Range: </button>
                                    <DatetimeRangePicker
                                        startDate={filter.startDate}
                                        endDate={filter.endDate}
                                        ranges={ranges}
                                        onApply={handleEvent}
                                        className="selected-date-range-btn btn btn-default">
                                        <div className="pull-left">
                                            <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                                        </div>
                                        <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                                    </DatetimeRangePicker>

                                    <button onClick={getReport} className="btn btn-info">Display</button>
                                </div>

                            </div>
                            <div className="col-xs-12"><hr className="hrsp" /></div>

                            <div className="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2 bg-primary text-center" style={{ padding: "5px", fontSize: "18px", textTransform: "uppercase" }}>
                                PERFORMANCE FOR {formatAgent(filter.username, filter)}
                            </div>
                            <div className="col-xs-12"><hr className="hrsp" /></div>
                            <div className="col-xs-12 col-sm-10 col-sm-offset-1 ">
                                <table className="table table-condensed table-striped">
                                    <tbody>
                                        <tr>
                                            <td width="150px" className="text-right">&nbsp;</td>
                                            <td width="150px" className="text-right">Total Sales: </td><td width="200px" ><b>{curformat.format(_.sumBy(reportData,'amount'))}</b></td>
                                        </tr>
                                        <tr>
                                            <td width="150px" className="text-right">Total Retailers: </td><td width="200px"><b>{numformat.format(retailerTotal.total)}</b></td>
                                            <td width="150px" className="text-right">Active Retailers: </td><td width="200px"><b>{numformat.format(retailerTotal.active)}</b></td>
                                        </tr>
                                        <tr>
                                            <td width="150px" className="text-right">Period: </td><td width="200px" ><b>{label}</b></td>
                                            <td width="150px" className="text-right">Inactive Retailers: </td><td width="200px" ><b>{numformat.format(retailerTotal.inactive)}</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-xs-12"><hr className="hrsp" /></div>
                            <div className="col-xs-12">
                                <BootstrapTable data={reportData} striped hover condensed pagination >
                                    <TableHeaderColumn dataField='id' isKey dataSort  width="60">#</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='agent' dataSort dataFormat={formatAgent} width="260">AGENT</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='retailer' dataSort dataFormat={formatRetailer} width="280">RETAILER</TableHeaderColumn>
                                    <TableHeaderColumn dataField='lastran' dataFormat={dateFormat} dataSort width="150">LAST TRANSACTION</TableHeaderColumn>
                                    <TableHeaderColumn dataField='terminal' dataSort width="150">TERMINAL ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='bets' dataAlign="right" dataSort width="150">NO. OF BETS</TableHeaderColumn>
                                    <TableHeaderColumn dataField='amount' dataFormat={curFormat} dataAlign="right" dataSort width="120">AMOUNT</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default AgentPerformanceReport;
