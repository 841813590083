import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import Auth from '../common/Auth'
import Config from '../Config'

export default class DrawDataConfirmApprove extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems: this.props.rowData 
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            let formItems = nextProps.rowData

            this.setState({ formItems })
        }
    }

    doApprove = () =>{
        let formItems = this.state.formItems
        formItems['status'] = 4
        let formData = this.auth.encryptData(JSON.stringify(formItems))

        axios(this.config.PostOptions(formData, 'game/wapp/gamedetail'))
        .then((res) => {
            //this.setState({ loader:false })
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data))
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            $('#btnApprovecancel').click()
            $('#btnDrawDataDisplay').click()
        }).catch((error)=>{alert(error);$('#btnApprovecancel').click()})
    }

    render(){
        return(
            <div className="modal fade" id="approveconfirm" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">Confirm Approval</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-xs-12 text-center">
                                    Confirm Draw Approval....
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="btnApprovecancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={this.doApprove}>Approve</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}