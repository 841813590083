import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Config from '../Config'
import Menu from '../common/Menu';
import Auth from '../common/Auth';
import $ from 'jquery'

export default class NavBar extends Component{
    constructor(){
        super()

        this.menu = new Menu()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            menus: this.menu,
            user:{},
            features: {1:['1'], 2:['2'], 3:['3']}
        }
    }

    componentDidMount(){
        this.getProfile()

        let ft = this.auth.getFeatures()
        if(ft !== null && ft !== '' && ft !== 'undefined' && ft !== undefined){
            let features = JSON.parse(ft)
            features['1'] = ['1']
            features['2'] = ['2']
            features['3'] = ['3']
            this.setState({ features })
        }
    }

    getProfile = () => {
        this.$http.get('admin/wapp/user/' + this.auth.getUser())
        .then(res => {
            let user = this.auth.decryptData(res.data)

            this.setState({ user })
        })
    }

    showShortcut = () => {
        $('#shortcut').removeClass('hide')
    }

    doLogout = () => {
        this.auth.logout();
    }

    render(){
        let curuser = this.state.user

        return(
            <aside id="left-panel">
                <div className="login-info">
                    <span> 
                        <div class="btn-group">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={this.config.ImagePath('custfiles') + '/' + this.auth.getUser() + '.' + curuser.filex} alt={this.auth.getUser()} className="online" /> 
                            <span>{this.auth.getUser()}</span> <i className="fa fa-angle-down"></i>
                            </a>
                            <div class="dropdown-menu" style={{padding:'5px 10px',backgroundColor:'navy', boxShadow: '2px 2px 5px grey'}}>
                                <a class="dropdown-item" href="#" onClick={()=>this.props.navClickHandler(0)}>My Profile</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="" onClick={this.doLogout} data-action="userLogout" data-logout-msg="Are you sure, you want to logout?">Logout</a>
                            </div>
                        </div>
                    </span>
                </div>

                <nav>
                    <ul>
                    {this.state.menus.map(mn => ( 
                        this.state.features[mn.code] !== undefined && 
                        <li id={mn.code} className={mn.code == 1 && 'active'}>
                            <Link to='#' onClick={mn.submenu===undefined?()=>this.props.navClickHandler(mn.code):''}>
                                <i className={'fa fa-lg fa-fw fa-' + mn.icon}></i> 
                                <span className="menu-item-parent">{mn.name}</span>
                            </Link>
                            {mn.submenu!==undefined &&
                            <ul>
                                {mn.submenu.map(smenu => (
                                    (this.state.features[mn.code].includes(smenu.code)) &&
                                    <li>
                                        <Link to='#' onClick={()=>this.props.navClickHandler(smenu.code)}>
                                            {smenu.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>}
                        </li>
                    ))}
                        <li id="0" className="myprofile hide" onClick={()=>this.props.navClickHandler(0)}>My Profile</li>
                        <li className="text-center" style={{paddingTop:'20px'}}><a href="https://system.alphalottogh.com/assets/Apps/Alpha_POS.apk" target="_blank"  className="btn btn-sm btn-success"><i className="fa fa-lg fa-download"></i> POS App</a></li>
                        {/* <li className="text-center" style={{paddingTop:'20px'}}><a href="https://vision.vision2000lotto.com/assets/Apps/Vision_POS.apk" target="_blank"  className="btn btn-sm btn-success"><i className="fa fa-lg fa-download"></i> POS App</a></li> */}
                    </ul>
                </nav>
                <span className="minifyme" data-action="minifyMenu"> <i className="fa fa-arrow-circle-left hit"></i> </span>
            </aside>
        )
    }
}