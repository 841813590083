
import React, { Component } from 'react';
import AgentTransList from './AgentTransList';


export default class AgentsTransactions extends Component {
    constructor() {
        super()
      
    }
   
    render() {
        return (
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-calculator"></i> </span>
                            <h2><strong>Agent Transactions</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div id="agentrans_inner" className="widget-body"><AgentTransList /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}