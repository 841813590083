import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import GamesList from './GamesList'
import Config from '../Config'
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

export default class GamesForm extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            formItems:{
                id:0, drawno:'', gamecode:0, gameDay:new Date(), drawDate:new Date(), 
                drawNumbers:'', machineNumbers:'', status:0, createdby: this.auth.getUser(),
                createdon: new Date(), modifiedby:null, modifiedon:null,
                curuser:this.auth.getUser(), token: this.auth.getToken()
            },
            drawDate:new Date(), 
            drawTime:new Date().getTime(),
            minDate:'',
            games:this.props.games,
            activegames: this.props.activegames,
            gamedetail: this.props.gamedetail,
            game: {},
            agents:[],
            loader:false
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.activegames !== this.props.activegames){
            let allgames = nextProps.games
            let games = allgames
            let rowData = nextProps.rowData
            let formItems = this.state.formItems
            let drawDate = this.state.drawDate
            let drawTime = this.state.drawTime

            if(rowData.id !== undefined){
                formItems = rowData
                formItems['curuser'] = this.auth.getUser()
                formItems['token'] = this.auth.getToken()
                drawTime = moment(formItems.drawDate).format('HH:mm:ss')
                drawDate = moment(formItems.drawDate).format('YYYY-MM-DD')

                this.setState({ games, formItems, drawDate, drawTime})
            }else{
                let activegames = nextProps.activegames
                let agames = activegames.map(ag => ag.gamecode)
                games = allgames.filter(ag => !agames.includes(ag.id));
                //alert(JSON.stringify(games));
                formItems ={
                    id:0, drawno:'', gamecode:0, gameDay:new Date(), drawDate:new Date(), 
                    drawNumbers:'', machineNumbers:'', status:0, createdby: this.auth.getUser(),
                    createdon: new Date(), modifiedby:null, modifiedon:null,
                    curuser:this.auth.getUser(), token: this.auth.getToken()
                }

                this.setState({ formItems, games})
                this.calcNextDrawDate(games[0])
            }
            
            
        }
    }

    calcNextDrawDate = (game) => {
        let formItems = this.state.formItems
        let drawDate = this.state.drawDate
        let drawTime = this.state.drawTime

        const drawday = game.drawday
        let today = moment().isoWeekday()
        let nextDrawday = drawday;

        if(game.drawday === 0){
            nextDrawday = moment();
        }else{
            if(today <= drawday){
                nextDrawday = moment().isoWeekday(drawday)
            }else{
                nextDrawday = moment().add(1, 'weeks').isoWeekday(drawday)
            }
        }

        drawDate = moment(nextDrawday).format('YYYY-MM-DD')
        let minDate = drawDate
        drawTime = moment(game.endtime).format('HH:mm:ss')
        formItems['drawDate'] = drawDate + 'T' + drawTime + '.000Z'
        formItems['gameDay'] = drawDate

        this.setState({ formItems, drawDate, drawTime, minDate })
    }

    handleChange = (e) => {
        let formItems = this.state.formItems
        let drawDate = this.state.drawDate
        let drawTime = this.state.drawTime

        if(e.target.name === 'gamecode'){
            formItems[e.target.name] = e.target.value

            let games = this.state.games
            let game = games.filter(gm => gm.id == e.target.value)
            this.calcNextDrawDate(game[0])

            this.setState({ formItems })
        }else{
            if(e.target.name === 'drawDate'){
                drawDate = e.target.value
            }else{
                drawTime = e.target.value
            }

            formItems['drawDate'] = drawDate + 'T' + drawTime + '.000Z'

            this.setState({ formItems, drawDate, drawTime })
        }
        
        
    }

    formSubmit = (e) => {
        e.preventDefault()

        
        let formItems = this.state.formItems
        //alert(JSON.stringify(formItems))
        if(formItems.gamecode == 0){
            alert("Please select a game");
            return;
            //formItems['gamecode'] = this.state.games[0].id
        }
        let formData = this.auth.encryptData(JSON.stringify(formItems))

        axios(this.config.PostOptions(formData, 'game/wapp/gamedetail'))
        .then((res) => {
            //this.setState({ loader:false })
            if(isNaN(res.data)){
                alert(this.auth.decryptText(res.data));
                formItems ={
                    id:0, drawno:'', gamecode:0, gameDay:new Date(), drawDate:new Date(), 
                    drawNumbers:'', machineNumbers:'', status:0, createdby: this.auth.getUser(),
                    createdon: new Date(), modifiedby:null, modifiedon:null,
                    curuser:this.auth.getUser(), token: this.auth.getToken()
                };
                this.setState({ formItems });
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
            $('#btnGamecancel').click()
            this.props.getGames();
        }).catch((error)=>{alert(error);$('#btnGamecancel').click()})
    }

    goBack = () =>{
        return(
            ReactDOM.render(
                <GamesList />,
                document.getElementById('gameCon')
            )
        )
    }

    render(){
        return(
            <div className="modal fade" id="gameform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">New Game</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmGame" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <label className="label col col-5 text-right">Game Name: {this.state.formItems.id}</label>
                                        <section className="col col-7">
                                            <label className="select">
                                                <select name="gamecode" value={this.state.formItems.gamecode} 
                                                onChange={this.handleChange} disabled={this.state.formItems.id >0}>
                                                    <option value="0">-- Select Game --</option>
                                                    {this.state.games.map(game => (
                                                        <option value={game.id}>{game.gamename}</option>
                                                    ))}
                                                </select> <i></i> 
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12">
                                        <label className="label col col-5 text-right">Draw Day: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="date" name="drawDate" min={this.state.minDate} required 
                                                value={this.state.drawDate} onChange={this.handleChange}/>
                                                <small className="text-primary">{moment(this.state.drawDate).format('dddd')}</small>
                                            </label>
                                        </section>
                                    </div>
                                    <div className="col-xs-12">
                                        <label className="label col col-5 text-right">End Time: </label>
                                        <section className="col col-7">
                                            <label className="input">
                                                <input type="time" name="drawTime" required 
                                                value={this.state.drawTime} onChange={this.handleChange}/>
                                            </label>
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnGamecancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmGame" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}