import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'

export default class FileManagerFolder extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            isEdit: false
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){

        }
    }

    formSubmit = (e) => {
        e.preventDefault()
    }

    render(){
        return(
            <div className="modal fade" id="filefolderform" tabindex="-1" role="dialog" aria-hidden="true">  
                <div className="modal-dialog">  
                    <div className="modal-content"> 
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true"> × </button>
                            <h5 className="modal-title">{(this.state.isEdit) ? 'Edit' : 'New'} File Archive</h5>
                        </div>
                        <div className="modal-body">
                            <form id="frmFileFolder" className="smart-form" onSubmit={this.formSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-8">
                                        <label className="label col col-4 text-right">Folder Name: </label>
                                        <section className="col col-8">
                                            
                                        </section>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button id="btnFileFoldercancel" type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" form="frmFileFolder" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}