import React, { Component } from 'react'
import Auth from '../common/Auth'
import Config from '../Config'
import axios from 'axios'
import moment from 'moment'
import ReactDOM from 'react-dom'
import SendNewSMS from './SendNewSMS'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import SMSLists from './SMSLists'

export default class SendSMS extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            smslogs: [],
            users: []
        }

    }

    componentDidMount(){
        
    }

    componentDidUpdate(nextProps){
        if(nextProps !== this.props){
            
        }
    }
    
    render(){
        return(
            <div className="row">
                <article className="col-xs-12">
                    <div className="jarviswidget" id="wid-id-0" data-widget-editbutton="false">
                        <header>
                            <span class="widget-icon"> <i class="fa fa-inbox"></i> </span>
                            <h2><strong>SMS Messages</strong> </h2>
                        </header>
                        <div>
                            <div className="jarviswidget-editbox"></div>
                            <div className="widget-body" id="smsListCon"><SMSLists /></div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}