import React, { Component } from 'react';
import Auth from '../common/Auth'
import Config from '../Config'
import $ from 'jquery'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import EmailCompose from './EmailCompose'
import EmailBroadcast from './EmailBroadcast'
import EmailContent from './EmailContent'

export default class EmailMessages extends Component{
    constructor(props){
        super(props)

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            reportData:[]
        }
    }

    componentDidMount(){
        this.getInbox()
    }

    getInbox = () => {
        this.makeActive(1)
        let reportData = []
        ReactDOM.render(this.messageList(reportData, 'Inbox'), 
            document.getElementById('emailCon'))
    }

    getSent = () => {
        this.makeActive(3)
        let reportData = []
        ReactDOM.render(this.messageList(reportData, 'Sent'), 
            document.getElementById('emailCon'))
    }

    getDraft = () => {
        this.makeActive(2)
        let reportData = []
        ReactDOM.render(this.messageList(reportData, 'Draft'), 
            document.getElementById('emailCon'))
    }

    composeMessage = () => {
        this.makeActive(0)
        ReactDOM.render(<EmailCompose />, 
            document.getElementById('emailCon'))
    }

    broadcastMessage = () => {
        this.makeActive(0)
        ReactDOM.render(<EmailBroadcast />, 
            document.getElementById('emailCon'))
    }

    readMessage = () => {
        ReactDOM.render(<EmailContent />, 
            document.getElementById('emailCon'))
    }

    makeActive = (itm) => {
        $('[id^="btnEmail_"]').removeClass('active')
        $('#btnEmail_' + itm).addClass('active')
    }

    actionFormat = (cell, row) => {
        return(
            <div className>
                <i className="fa fa-square"></i> &nbsp; 
                <i className="fa fa-star"></i> &nbsp; 
                <i className="fa fa-trash-o"></i>
            </div>
        )
    }

    messageList = (reportData, title) => {
        return(
            <div>
                <h3>{title}</h3>
                <BootstrapTable data={this.state.reportData} striped hover condensed search pagination>
                    <TableHeaderColumn dataField='id' isKey dataSort dataFormat={this.actionFormat}></TableHeaderColumn>
                    <TableHeaderColumn dataField='sender' dataSort></TableHeaderColumn>
                    <TableHeaderColumn dataField='title'></TableHeaderColumn>
                    <TableHeaderColumn dataField='createdon'></TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }

    render(){
        return(
            <div className="row">
                <article className="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                    <div className="btn-group btn-group-vertical btn-group-sm" style={{width:'100%'}}>
                        <button className="btn btn-success btn-block" onClick={this.composeMessage}>Compose</button>
                        <button className="btn btn-primary btn-block" onClick={this.broadcastMessage}>Broadcast</button>
                        <hr class="separator hrsp"></hr>
                        <button id="btnEmail_1" className="btn btn-default btn-block active" onClick={this.getInbox}>Inbox</button>
                        <button id="btnEmail_2" className="btn btn-default btn-block" onClick={this.getDraft}>Draft</button>
                        <button id="btnEmail_3" className="btn btn-default btn-block" onClick={this.getSent}>Sent</button>
                    </div>
                </article>
                <article id="emailCon" className="col-xs-12 col-sm-8 col-md-9 col-lg-10"></article>
            </div>
        )
    }
}