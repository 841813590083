import React from "react";

function CommissionDetails(props){

    return(
        <div className="row">
            <div className="col-xs-12 clearfix">
                <div class="col-xs-12 col-sm-7">

                </div>
                <div class="col-xs-12 col-sm-5 pull-right text-right">
                    {/* <div className="btn-group btn-group-md" >
                        <button className="btn btn-success" > <i class="fa fa-plus"></i> New Sales Bonus</button>
                        <button className="btn btn-primary" > <i class="fa fa-print"></i> Print Report</button>
                    </div> */}
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 ">
                <hr className="hrsp" />
            </div>
        </div>
    );
}

export default CommissionDetails;